export default [
  {
    uid: '1',
    un: '数と式・集合と論理',
    fid: '1a2bc',
    did: '1',
    hn: 'I A Ⅱ B C / 準備系',
    prints: [
      {
        pid: '1',
        pn: '論理',
        hn: 'I A Ⅱ B C / 準備系 / 数と式・集合と論理',
        questions: [
          {
            uid: '1',
            fid: '1a2bc',
            qid: '1',
            qn: '数と式はいぷりプレビュー1番',
            quid: '01_01_01',
            hn: 'I A Ⅱ B C / 準備系 / 数と式・集合と論理 / 論理',
            vid: 'LEUDN6QKzKrt',
            hid: 'q73tPgM4pZ5V',
            kuids: [ '01_06_04' ],
            euids: [ '01_09_29_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '1',
            fid: '1a2bc',
            qid: '2',
            qn: '数と式はいぷりプレビュー2番',
            quid: '01_01_02',
            hn: 'I A Ⅱ B C / 準備系 / 数と式・集合と論理 / 論理',
            vid: 'pNpaAwvu_xUd',
            hid: '-WjzYQtoidgr',
            kuids: [ '01_06_05' ],
            euids: [ '01_09_30_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '89',
    un: 'いろいろな式',
    fid: '1a2bc',
    did: '1',
    hn: 'I A Ⅱ B C / 準備系',
    prints: [
      {
        pid: '1',
        pn: '二項定理・恒等式',
        hn: 'I A Ⅱ B C / 準備系 / いろいろな式',
        questions: [
          {
            uid: '89',
            fid: '1a2bc',
            qid: '1',
            qn: 'いろいろな式はいぷりプレビュー1番',
            quid: '89_01_01',
            hn: 'I A Ⅱ B C / 準備系 / いろいろな式 / 二項定理・恒等式',
            vid: 'ElP9HmMoTcn2',
            hid: 'y_1v-JkBgsKm',
            kuids: [ '08_01_01' ],
            euids: [ '08_02_05_01', '08_02_05_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '89',
            fid: '1a2bc',
            qid: '2',
            qn: 'いろいろな式はいぷりプレビュー2番',
            quid: '89_01_02',
            hn: 'I A Ⅱ B C / 準備系 / いろいろな式 / 二項定理・恒等式',
            vid: 'r-eVnD9mvDXI',
            hid: '3L7BXXxnIZ8L',
            kuids: [ '08_02_01' ],
            euids: [ '08_03_08_01', '08_03_08_02' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '等式の証明・不等式の証明',
        hn: 'I A Ⅱ B C / 準備系 / いろいろな式',
        questions: [
          {
            uid: '89',
            fid: '1a2bc',
            qid: '3',
            qn: 'いろいろな式はいぷりプレビュー3番',
            quid: '89_02_03',
            hn: 'I A Ⅱ B C / 準備系 / いろいろな式 / 等式の証明・不等式の証明',
            vid: '9YJb6U9YeZrV',
            hid: 'eRes1o1XeNsz',
            kuids: [ '08_03_01', '01_01_02' ],
            euids: [ '08_04_11_02', '01_01_02_09' ],
            huids: [],
            record: {}
          },
          {
            uid: '89',
            fid: '1a2bc',
            qid: '4',
            qn: 'いろいろな式はいぷりプレビュー4番',
            quid: '89_02_04',
            hn: 'I A Ⅱ B C / 準備系 / いろいろな式 / 等式の証明・不等式の証明',
            vid: 'KRtSAiMbiLm4',
            hid: 'J8rWdMA07iZG',
            kuids: [ '08_04_01' ],
            euids: [ '08_05_12_01' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '有名不等式の利用',
        hn: 'I A Ⅱ B C / 準備系 / いろいろな式',
        questions: [
          {
            uid: '89',
            fid: '1a2bc',
            qid: '5',
            qn: 'いろいろな式はいぷりプレビュー5番',
            quid: '89_03_05',
            hn: 'I A Ⅱ B C / 準備系 / いろいろな式 / 有名不等式の利用',
            vid: '4-yUJQGSDecL',
            hid: 'UWsgOeutOuQH',
            kuids: [ '08_05_01' ],
            euids: [
              '08_06_14_01',
              '08_06_14_02',
              '08_06_14_03',
              '08_06_14_04'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '89',
            fid: '1a2bc',
            qid: '6',
            qn: 'いろいろな式はいぷりプレビュー6番',
            quid: '89_03_06',
            hn: 'I A Ⅱ B C / 準備系 / いろいろな式 / 有名不等式の利用',
            vid: '4sU3KbljOl6Q',
            hid: 'MrJCIN5UbDpa',
            kuids: [ '08_05_02' ],
            euids: [ '08_06_15_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '２次方程式（数学Ⅱ）',
        hn: 'I A Ⅱ B C / 準備系 / いろいろな式',
        questions: [
          {
            uid: '89',
            fid: '1a2bc',
            qid: '7',
            qn: 'いろいろな式はいぷりプレビュー7番',
            quid: '89_04_07',
            hn: 'I A Ⅱ B C / 準備系 / いろいろな式 / ２次方程式（数学Ⅱ）',
            vid: 'SQ853rYtZLhC',
            hid: 'vWH_pvsvuPYw',
            kuids: [ '09_02_02', '09_02_01', '01_02_05' ],
            euids: [ '09_02_06_00', '01_03_07_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '89',
            fid: '1a2bc',
            qid: '8',
            qn: 'いろいろな式はいぷりプレビュー8番',
            quid: '89_04_08',
            hn: 'I A Ⅱ B C / 準備系 / いろいろな式 / ２次方程式（数学Ⅱ）',
            vid: 'cKFP9zqYM-1T',
            hid: 'HG5j2mX0sQvf',
            kuids: [ '09_02_03', '09_02_04' ],
            euids: [ '09_03_08_00', '09_03_10_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '高次方程式',
        hn: 'I A Ⅱ B C / 準備系 / いろいろな式',
        questions: [
          {
            uid: '89',
            fid: '1a2bc',
            qid: '9',
            qn: 'いろいろな式はいぷりプレビュー9番',
            quid: '89_05_09',
            hn: 'I A Ⅱ B C / 準備系 / いろいろな式 / 高次方程式',
            vid: 'iW6rrdhRPWNX',
            hid: 'AXzTgg3LdtEE',
            kuids: [ '09_03_01', '09_03_04' ],
            euids: [ '09_04_15_00', '09_04_16_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '89',
            fid: '1a2bc',
            qid: '10',
            qn: 'いろいろな式はいぷりプレビュー10番',
            quid: '89_05_10',
            hn: 'I A Ⅱ B C / 準備系 / いろいろな式 / 高次方程式',
            vid: '7rqDSepjeCiv',
            hid: 'V0vru_IUFEVs',
            kuids: [ '09_03_03', '09_03_05' ],
            euids: [ '09_05_18_00', '09_06_20_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '高次方程式の応用題',
        hn: 'I A Ⅱ B C / 準備系 / いろいろな式',
        questions: [
          {
            uid: '89',
            fid: '1a2bc',
            qid: '11',
            qn: 'いろいろな式はいぷりプレビュー11番',
            quid: '89_06_11',
            hn: 'I A Ⅱ B C / 準備系 / いろいろな式 / 高次方程式の応用題',
            vid: 'xZv6sGwr5w3A',
            hid: 'bqnh1wqytl2c',
            kuids: [ '09_03_02', '02_04_03' ],
            euids: [ '09_05_17_05', '02_09_27_00', '02_09_30_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '89',
            fid: '1a2bc',
            qid: '12',
            qn: 'いろいろな式はいぷりプレビュー12番',
            quid: '89_06_12',
            hn: 'I A Ⅱ B C / 準備系 / いろいろな式 / 高次方程式の応用題',
            vid: 'wPrTe-fteZWX',
            hid: 'GmACWQLefKwN',
            kuids: [ '09_03_03', '08_03_01', '01_01_02' ],
            euids: [ '09_05_18_00', '08_04_11_01', '01_01_02_10' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '14',
    un: '数列',
    fid: '1a2bc',
    did: '1',
    hn: 'I A Ⅱ B C / 準備系',
    prints: [
      {
        pid: '1',
        pn: '等差数列・等比数列',
        hn: 'I A Ⅱ B C / 準備系 / 数列',
        questions: [
          {
            uid: '14',
            fid: '1a2bc',
            qid: '1',
            qn: '数列はいぷりプレビュー1番',
            quid: '14_01_01',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 等差数列・等比数列',
            vid: '4QVctXUgqRuO',
            hid: 'gqnYo3YkJC_q',
            kuids: [ '14_01_01', '01_06_05' ],
            euids: [ '14_01_01_00', '01_09_30_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '14',
            fid: '1a2bc',
            qid: '2',
            qn: '数列はいぷりプレビュー2番',
            quid: '14_01_02',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 等差数列・等比数列',
            vid: 'kSQ4z0LsOalA',
            hid: 'ASx6h-UyCJtD',
            kuids: [ '14_02_01' ],
            euids: [ '14_02_05_00', '14_02_07_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: 'Σ　その１',
        hn: 'I A Ⅱ B C / 準備系 / 数列',
        questions: [
          {
            uid: '14',
            fid: '1a2bc',
            qid: '3',
            qn: '数列はいぷりプレビュー3番',
            quid: '14_02_03',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / Σ　その１',
            vid: 'ZSDKoPSyuIFW',
            hid: 'BhPk8lKKmwxC',
            kuids: [ '14_03_02' ],
            euids: [ '14_03_10_02', '14_03_10_03' ],
            huids: [],
            record: {}
          },
          {
            uid: '14',
            fid: '1a2bc',
            qid: '4',
            qn: '数列はいぷりプレビュー4番',
            quid: '14_02_04',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / Σ　その１',
            vid: 'vSQHZfG7dYRr',
            hid: 'Z_MnpIQ_I2Wn',
            kuids: [ '14_03_03' ],
            euids: [ '14_03_10_04', '14_03_10_05' ],
            huids: [],
            record: {}
          },
          {
            uid: '14',
            fid: '1a2bc',
            qid: '5',
            qn: '数列はいぷりプレビュー5番',
            quid: '14_02_05',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / Σ　その１',
            vid: 'JDss49tCq4kh',
            hid: 'liHALeWR4VlK',
            kuids: [ '14_03_04' ],
            euids: [ '14_03_10_12' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: 'Σ　その２',
        hn: 'I A Ⅱ B C / 準備系 / 数列',
        questions: [
          {
            uid: '14',
            fid: '1a2bc',
            qid: '6',
            qn: '数列はいぷりプレビュー6番',
            quid: '14_03_06',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / Σ　その２',
            vid: 't3ic99uDw2Qe',
            hid: 'fKjxKMtH7zO1',
            kuids: [ '14_03_06' ],
            euids: [ '14_03_10_14' ],
            huids: [],
            record: {}
          },
          {
            uid: '14',
            fid: '1a2bc',
            qid: '7',
            qn: '数列はいぷりプレビュー7番',
            quid: '14_03_07',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / Σ　その２',
            vid: 'A5s4yCMQXUjL',
            hid: 'i-AC1blLgVLt',
            kuids: [ '14_03_07' ],
            euids: [],
            huids: [],
            record: {}
          },
          {
            uid: '14',
            fid: '1a2bc',
            qid: '8',
            qn: '数列はいぷりプレビュー8番',
            quid: '14_03_08',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / Σ　その２',
            vid: 'jHzwHl0Ntyi7',
            hid: 'UUFTHCbUvN0h',
            kuids: [ '14_02_01' ],
            euids: [ '14_04_12_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '群数列・和と一般項の関係',
        hn: 'I A Ⅱ B C / 準備系 / 数列',
        questions: [
          {
            uid: '14',
            fid: '1a2bc',
            qid: '9',
            qn: '数列はいぷりプレビュー9番',
            quid: '14_04_09',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 群数列・和と一般項の関係',
            vid: 'X4IU0oNyQWgI',
            hid: 'eYU2TwlaWGOY',
            kuids: [ '14_04_01' ],
            euids: [ '14_04_14_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '14',
            fid: '1a2bc',
            qid: '10',
            qn: '数列はいぷりプレビュー10番',
            quid: '14_04_10',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 群数列・和と一般項の関係',
            vid: 'In7DFiDdYSxk',
            hid: 'InOz--nZmQ93',
            kuids: [ '14_06_01', '14_06_02' ],
            euids: [ '14_05_18_01', '14_05_18_02', '14_05_19_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '漸化式　その１',
        hn: 'I A Ⅱ B C / 準備系 / 数列',
        questions: [
          {
            uid: '14',
            fid: '1a2bc',
            qid: '11',
            qn: '数列はいぷりプレビュー11番',
            quid: '14_05_11',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 漸化式　その１',
            vid: 'ZL8n_pZ-zAi9',
            hid: '3XfJr9YhqzFD',
            kuids: [ '14_07_02' ],
            euids: [ '14_06_20_03', '14_06_20_04', '14_06_20_05' ],
            huids: [],
            record: {}
          },
          {
            uid: '14',
            fid: '1a2bc',
            qid: '12',
            qn: '数列はいぷりプレビュー12番',
            quid: '14_05_12',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 漸化式　その１',
            vid: '0tw7P_zhfzZ6',
            hid: 'Fe1eJkV7wqLN',
            kuids: [ '14_07_04' ],
            euids: [ '14_06_20_08' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '漸化式　その２',
        hn: 'I A Ⅱ B C / 準備系 / 数列',
        questions: [
          {
            uid: '14',
            fid: '1a2bc',
            qid: '13',
            qn: '数列はいぷりプレビュー13番',
            quid: '14_06_13',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 漸化式　その２',
            vid: '9XJMeTmFQPI3',
            hid: 'LJgmBTz3KdOu',
            kuids: [ '14_07_06', '18_01_07' ],
            euids: [ '14_06_20_11', '14_06_20_12', '18_03_04_03' ],
            huids: [],
            record: {}
          },
          {
            uid: '14',
            fid: '1a2bc',
            qid: '14',
            qn: '数列はいぷりプレビュー14番',
            quid: '14_06_14',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 漸化式　その２',
            vid: 'B0zU5dxAA7bj',
            hid: '7jHdmFfhqmst',
            kuids: [ '14_07_07', '14_07_08' ],
            euids: [ '14_06_20_13', '14_06_20_15' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '漸化式　その３',
        hn: 'I A Ⅱ B C / 準備系 / 数列',
        questions: [
          {
            uid: '14',
            fid: '1a2bc',
            qid: '15',
            qn: '数列はいぷりプレビュー15番',
            quid: '14_07_15',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 漸化式　その３',
            vid: 'UXtr4RDLWdPU',
            hid: 'w4PbGS1n_mlA',
            kuids: [ '14_07_03', '14_06_01' ],
            euids: [
              '14_06_20_06',
              '14_06_20_06',
              '14_05_18_01',
              '14_05_18_02'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '14',
            fid: '1a2bc',
            qid: '16',
            qn: '数列はいぷりプレビュー16番',
            quid: '14_07_16',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 漸化式　その３',
            vid: 'NynYIViF7JVD',
            hid: 'b7R9y7Ors9w8',
            kuids: [ '14_07_01' ],
            euids: [ '14_06_20_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '14',
            fid: '1a2bc',
            qid: '17',
            qn: '数列はいぷりプレビュー17番',
            quid: '14_07_17',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 漸化式　その３',
            vid: 'aiIFUBh2ecaN',
            hid: 'ySPJlM6eb8Ll',
            kuids: [ '14_07_07' ],
            euids: [ '14_06_20_13' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '数学的帰納法　その１',
        hn: 'I A Ⅱ B C / 準備系 / 数列',
        questions: [
          {
            uid: '14',
            fid: '1a2bc',
            qid: '18',
            qn: '数列はいぷりプレビュー18番',
            quid: '14_08_18',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 数学的帰納法　その１',
            vid: 'ow_KHjtUV6_X',
            hid: 'Ha-ccRIQJEdD',
            kuids: [ '14_08_01', '14_08_04' ],
            euids: [ '14_07_21_00', '14_08_24_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '14',
            fid: '1a2bc',
            qid: '19',
            qn: '数列はいぷりプレビュー19番',
            quid: '14_08_19',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 数学的帰納法　その１',
            vid: 'xLacRlZquRRo',
            hid: '5c093nDpFIgk',
            kuids: [ '14_08_01' ],
            euids: [ '14_07_21_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '数学的帰納法　その２',
        hn: 'I A Ⅱ B C / 準備系 / 数列',
        questions: [
          {
            uid: '14',
            fid: '1a2bc',
            qid: '20',
            qn: '数列はいぷりプレビュー20番',
            quid: '14_09_20',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 数学的帰納法　その２',
            vid: 'A5TpKVLe-3Na',
            hid: 'CzZqwImz_6HL',
            kuids: [ '14_08_05', '14_08_01' ],
            euids: [ '14_07_21_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '14',
            fid: '1a2bc',
            qid: '21',
            qn: '数列はいぷりプレビュー21番',
            quid: '14_09_21',
            hn: 'I A Ⅱ B C / 準備系 / 数列 / 数学的帰納法　その２',
            vid: 'UlQZqjYDMBdd',
            hid: 'Voq6ba7Ub7-7',
            kuids: [ '14_08_06', '14_08_01', '14_08_04' ],
            euids: [ '14_07_21_00', '14_08_24_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '36',
    un: 'Ⅰ・Ａ図形総合',
    fid: '1a2bc',
    did: '2',
    hn: 'I A Ⅱ B C / 図形系',
    prints: [
      {
        pid: '1',
        pn: '図形の性質　その１（数学Ａ）',
        hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合',
        questions: [
          {
            uid: '36',
            fid: '1a2bc',
            qid: '1',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー1番',
            quid: '36_01_01',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形の性質　その１（数学Ａ）',
            vid: 'ID5i4JWaWVXE',
            hid: 'PV-fY6PG5wGX',
            kuids: [ '06_01_03', '06_01_06', '06_01_07' ],
            euids: [ '06_01_04_00', '06_02_06_00', '06_02_07_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '36',
            fid: '1a2bc',
            qid: '2',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー2番',
            quid: '36_01_02',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形の性質　その１（数学Ａ）',
            vid: '2bjT9REmSpDJ',
            hid: '0IXWRRYuqigD',
            kuids: [ '06_01_06', '06_01_04' ],
            euids: [ '06_02_06_00', '06_02_08_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '36',
            fid: '1a2bc',
            qid: '3',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー3番',
            quid: '36_01_03',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形の性質　その１（数学Ａ）',
            vid: 'hgDqVZ0VexMw',
            hid: 'VJyS8gTLZXeG',
            kuids: [
              '06_01_10',
              '06_01_05',
              '06_01_11',
              '06_01_13',
              '06_01_12'
            ],
            euids: [ '06_03_09_00', '06_03_11_00', '06_04_13_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '図形の性質　その２（数学Ａ）',
        hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合',
        questions: [
          {
            uid: '36',
            fid: '1a2bc',
            qid: '4',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー4番',
            quid: '36_02_04',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形の性質　その２（数学Ａ）',
            vid: 'bGwecNN7TupY',
            hid: 'gqwuKJGwM2fb',
            kuids: [ '06_01_06', '06_02_04' ],
            euids: [ '06_02_06_00', '06_06_18_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '36',
            fid: '1a2bc',
            qid: '5',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー5番',
            quid: '36_02_05',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形の性質　その２（数学Ａ）',
            vid: 'ycijVn0DcpV6',
            hid: 'd5qiqgKsGGRE',
            kuids: [ '06_02_05' ],
            euids: [ '06_08_21_00', '06_08_22_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '図形と計量　その１（数学Ⅰ）',
        hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合',
        questions: [
          {
            uid: '36',
            fid: '1a2bc',
            qid: '6',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー6番',
            quid: '36_03_06',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形と計量　その１（数学Ⅰ）',
            vid: 'EWdSxFQhglg_',
            hid: '79ncRK9svf29',
            kuids: [ '03_10_01' ],
            euids: [ '03_06_17_01', '03_06_17_02', '03_06_17_03' ],
            huids: [],
            record: {}
          },
          {
            uid: '36',
            fid: '1a2bc',
            qid: '7',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー7番',
            quid: '36_03_07',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形と計量　その１（数学Ⅰ）',
            vid: 'eZGerPgezIv2',
            hid: 'gq3_I0HWwpbx',
            kuids: [ '03_09_01', '03_11_01' ],
            euids: [
              '03_06_15_00',
              '03_06_16_00',
              '03_07_19_01',
              '03_07_19_02'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '36',
            fid: '1a2bc',
            qid: '8',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー8番',
            quid: '36_03_08',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形と計量　その１（数学Ⅰ）',
            vid: 'ZV-jS7IHutui',
            hid: '6E5JGQ8Qr7rO',
            kuids: [ '03_07_01', '03_08_01', '03_11_01', '08_05_01' ],
            euids: [
              '03_05_12_03',
              '03_05_14_01',
              '03_07_19_01',
              '08_06_14_01'
            ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '図形と計量　その２（数学Ⅰ）',
        hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合',
        questions: [
          {
            uid: '36',
            fid: '1a2bc',
            qid: '9',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー9番',
            quid: '36_04_09',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形と計量　その２（数学Ⅰ）',
            vid: '0-7Muejanmi_',
            hid: '9wet_c8CnPcz',
            kuids: [ '03_12_01', '09_02_04' ],
            euids: [ '03_07_20_00', '03_07_21_00', '09_03_10_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '36',
            fid: '1a2bc',
            qid: '10',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー10番',
            quid: '36_04_10',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形と計量　その２（数学Ⅰ）',
            vid: 'BkivDvO2JXco',
            hid: 'Clfj1QCoi7Pv',
            kuids: [ '03_12_01' ],
            euids: [ '03_07_20_00', '03_07_21_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '36',
            fid: '1a2bc',
            qid: '11',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー11番',
            quid: '36_04_11',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形と計量　その２（数学Ⅰ）',
            vid: 'RmU40DG5kPp5',
            hid: '0AFY8hHhA18M',
            kuids: [ '03_08_01', '03_13_01', '02_02_01' ],
            euids: [
              '03_05_14_02',
              '03_08_22_00',
              '03_08_23_00',
              '02_02_04_00'
            ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '図形総合（数学ⅠＡ）',
        hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合',
        questions: [
          {
            uid: '36',
            fid: '1a2bc',
            qid: '12',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー12番',
            quid: '36_05_12',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形総合（数学ⅠＡ）',
            vid: '_A9fYFVhwaoe',
            hid: 'aO3BR-ouGVz4',
            kuids: [ '03_09_01', '06_01_01' ],
            euids: [ '03_06_15_00', '03_06_16_00', '06_01_02_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '36',
            fid: '1a2bc',
            qid: '13',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー13番',
            quid: '36_05_13',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形総合（数学ⅠＡ）',
            vid: 'AnhTI6lPEbp8',
            hid: '3MTVwp2X5Lke',
            kuids: [ '03_12_01', '03_10_01' ],
            euids: [ '03_07_20_00', '03_07_21_00', '03_06_17_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '36',
            fid: '1a2bc',
            qid: '14',
            qn: 'Ⅰ・Ａ図形総合はいぷりプレビュー14番',
            quid: '36_05_14',
            hn: 'I A Ⅱ B C / 図形系 / Ⅰ・Ａ図形総合 / 図形総合（数学ⅠＡ）',
            vid: 'IhP_mXm_KRlA',
            hid: 'DIQbtEegPycg',
            kuids: [
              '03_13_01',
              '06_01_10',
              '06_01_05',
              '06_01_11',
              '06_01_13'
            ],
            euids: [ '03_08_22_00', '06_03_09_00', '06_03_11_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '10',
    un: '図形と方程式',
    fid: '1a2bc',
    did: '2',
    hn: 'I A Ⅱ B C / 図形系',
    prints: [
      {
        pid: '1',
        pn: '点と直線',
        hn: 'I A Ⅱ B C / 図形系 / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '1a2bc',
            qid: '1',
            qn: '図形と方程式はいぷりプレビュー1番',
            quid: '10_01_01',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 点と直線',
            vid: '9mYt_VqWflKD',
            hid: 'KEC6nw16LPDl',
            kuids: [ '10_01_08' ],
            euids: [ '10_03_11_00', '10_03_12_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '10',
            fid: '1a2bc',
            qid: '2',
            qn: '図形と方程式はいぷりプレビュー2番',
            quid: '10_01_02',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 点と直線',
            vid: 'j58yUOmNgHph',
            hid: 'WVNOBqe_s2TX',
            kuids: [ '10_01_10', '10_01_04' ],
            euids: [ '10_03_14_00', '10_02_05_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '円',
        hn: 'I A Ⅱ B C / 図形系 / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '1a2bc',
            qid: '3',
            qn: '図形と方程式はいぷりプレビュー3番',
            quid: '10_02_03',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 円',
            vid: 'iH0ngo13DheW',
            hid: 'TXByqJWHELRE',
            kuids: [ '10_01_06', '10_02_02', '10_02_03' ],
            euids: [
              '10_02_09_00',
              '10_04_17_00',
              '10_04_18_00',
              '10_05_20_00'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '10',
            fid: '1a2bc',
            qid: '4',
            qn: '図形と方程式はいぷりプレビュー4番',
            quid: '10_02_04',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 円',
            vid: '3VGjACSzUf7v',
            hid: 'htMFK2l3e0sX',
            kuids: [ '10_01_06', '10_02_06' ],
            euids: [ '10_02_09_00', '10_06_24_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '10',
            fid: '1a2bc',
            qid: '5',
            qn: '図形と方程式はいぷりプレビュー5番',
            quid: '10_02_05',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 円',
            vid: 'pgPOOxCPiwzp',
            hid: 'ZccxJmRR-Kdo',
            kuids: [ '10_02_07', '10_02_05' ],
            euids: [ '10_05_22_00', '10_05_23_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '軌跡　その１',
        hn: 'I A Ⅱ B C / 図形系 / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '1a2bc',
            qid: '6',
            qn: '図形と方程式はいぷりプレビュー6番',
            quid: '10_03_06',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 軌跡　その１',
            vid: '2Udq9AbA3EtW',
            hid: 'Z8kGKfJKVr0p',
            kuids: [ '10_03_02' ],
            euids: [ '10_07_27_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '10',
            fid: '1a2bc',
            qid: '7',
            qn: '図形と方程式はいぷりプレビュー7番',
            quid: '10_03_07',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 軌跡　その１',
            vid: 'wH_VEwtf1dzn',
            hid: 'zNGtxI3Jgg2M',
            kuids: [ '10_02_03', '10_03_05', '10_03_06', '09_02_03' ],
            euids: [
              '10_04_18_00',
              '10_07_29_00',
              '10_07_32_00',
              '09_03_08_00'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '10',
            fid: '1a2bc',
            qid: '8',
            qn: '図形と方程式はいぷりプレビュー8番',
            quid: '10_03_08',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 軌跡　その１',
            vid: 'KnwMOMjWz9vf',
            hid: 'aWROlvxTSgso',
            kuids: [ '10_03_04' ],
            euids: [ '10_07_30_00', '10_07_31_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '軌跡　その２',
        hn: 'I A Ⅱ B C / 図形系 / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '1a2bc',
            qid: '9',
            qn: '図形と方程式はいぷりプレビュー9番',
            quid: '10_04_09',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 軌跡　その２',
            vid: 'PB27cNbWRlRQ',
            hid: 'dbE2pw5FtMXF',
            kuids: [ '10_03_03', '10_03_06' ],
            euids: [ '10_07_28_00', '10_07_32_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '10',
            fid: '1a2bc',
            qid: '10',
            qn: '図形と方程式はいぷりプレビュー10番',
            quid: '10_04_10',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 軌跡　その２',
            vid: 'eaXTdywUyk1_',
            hid: 'IRxaTf1vdDBr',
            kuids: [ '10_03_04' ],
            euids: [ '10_07_30_00', '10_07_31_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '領域と最大最小',
        hn: 'I A Ⅱ B C / 図形系 / 図形と方程式',
        questions: [
          {
            uid: '10',
            fid: '1a2bc',
            qid: '11',
            qn: '図形と方程式はいぷりプレビュー11番',
            quid: '10_05_11',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 領域と最大最小',
            vid: 'igInHlFHIUU5',
            hid: '0yZOcVUDkout',
            kuids: [ '10_04_01', '02_04_03' ],
            euids: [ '10_08_33_00', '02_09_29_00', '02_09_30_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '10',
            fid: '1a2bc',
            qid: '12',
            qn: '図形と方程式はいぷりプレビュー12番',
            quid: '10_05_12',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 領域と最大最小',
            vid: 'lwKTMUl_iUdx',
            hid: 'FoPazytCrshV',
            kuids: [ '10_04_02' ],
            euids: [ '10_08_34_00', '10_09_38_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '10',
            fid: '1a2bc',
            qid: '13',
            qn: '図形と方程式はいぷりプレビュー13番',
            quid: '10_05_13',
            hn: 'I A Ⅱ B C / 図形系 / 図形と方程式 / 領域と最大最小',
            vid: 'c0wedI5SR0RW',
            hid: 'iPyx3D_nbusz',
            kuids: [ '10_04_03', '02_04_03' ],
            euids: [
              '10_10_39_00',
              '10_10_40_00',
              '02_09_29_00',
              '02_09_30_00'
            ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '16',
    un: 'ベクトル',
    fid: '1a2bc',
    did: '2',
    hn: 'I A Ⅱ B C / 図形系',
    prints: [
      {
        pid: '1',
        pn: '平面ベクトル　内積系',
        hn: 'I A Ⅱ B C / 図形系 / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '1a2bc',
            qid: '1',
            qn: 'ベクトルはいぷりプレビュー1番',
            quid: '16_01_01',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 平面ベクトル　内積系',
            vid: 'NWJG9FxPZoRU',
            hid: 'N-AuhB0wwZaJ',
            kuids: [ '16_01_03' ],
            euids: [ '16_02_05_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '16',
            fid: '1a2bc',
            qid: '2',
            qn: 'ベクトルはいぷりプレビュー2番',
            quid: '16_01_02',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 平面ベクトル　内積系',
            vid: 'xCxmaxXIRJs2',
            hid: 'lheKEV2wJVM0',
            kuids: [ '16_01_03' ],
            euids: [ '16_02_05_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '点の位置・面積比',
        hn: 'I A Ⅱ B C / 図形系 / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '1a2bc',
            qid: '3',
            qn: 'ベクトルはいぷりプレビュー3番',
            quid: '16_02_03',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 点の位置・面積比',
            vid: 'TlqKq_IzT6DO',
            hid: '9hlv1Cy73ROG',
            kuids: [ '16_02_02', '16_02_01', '16_00_01' ],
            euids: [ '16_04_12_00', '16_08_24_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '16',
            fid: '1a2bc',
            qid: '4',
            qn: 'ベクトルはいぷりプレビュー4番',
            quid: '16_02_04',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 点の位置・面積比',
            vid: 'p52D7FTMN8Ts',
            hid: 'zg-2efM6aVkb',
            kuids: [ '16_02_03' ],
            euids: [ '16_04_14_00', '16_04_15_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '領域・内積と図形',
        hn: 'I A Ⅱ B C / 図形系 / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '1a2bc',
            qid: '5',
            qn: 'ベクトルはいぷりプレビュー5番',
            quid: '16_03_05',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 領域・内積と図形',
            vid: 'dgq79nHOwPpN',
            hid: '3p4XmcVUwkK5',
            kuids: [ '16_02_04', '03_08_01' ],
            euids: [ '16_05_16_04', '16_05_17_00', '03_05_14_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '16',
            fid: '1a2bc',
            qid: '6',
            qn: 'ベクトルはいぷりプレビュー6番',
            quid: '16_03_06',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 領域・内積と図形',
            vid: 'A82TWYVqpcrV',
            hid: 'KUMQA5eoGbN7',
            kuids: [ '16_01_05', '16_03_01' ],
            euids: [ '16_03_09_00', '16_07_22_00', '16_07_23_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '外心',
        hn: 'I A Ⅱ B C / 図形系 / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '1a2bc',
            qid: '7',
            qn: 'ベクトルはいぷりプレビュー7番',
            quid: '16_04_07',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 外心',
            vid: 'UXPqJZRowrxN',
            hid: 'iId7hDmk1MVh',
            kuids: [ '16_01_04', '03_09_01' ],
            euids: [ '16_03_08_00', '03_06_15_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '16',
            fid: '1a2bc',
            qid: '8',
            qn: 'ベクトルはいぷりプレビュー8番',
            quid: '16_04_08',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 外心',
            vid: 'sZUIFDmzGRPw',
            hid: 'Z7SkBQwgxzw1',
            kuids: [ '16_03_01', '16_01_05' ],
            euids: [ '16_08_26_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '平面ベクトルの応用',
        hn: 'I A Ⅱ B C / 図形系 / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '1a2bc',
            qid: '9',
            qn: 'ベクトルはいぷりプレビュー9番',
            quid: '16_05_09',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 平面ベクトルの応用',
            vid: 'n0dhhrG4xAxh',
            hid: 'x7go2mxwEzeP',
            kuids: [ '16_01_05', '16_01_04', '16_04_03', '16_04_04' ],
            euids: [ '16_03_08_00', '16_10_34_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '16',
            fid: '1a2bc',
            qid: '10',
            qn: 'ベクトルはいぷりプレビュー10番',
            quid: '16_05_10',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 平面ベクトルの応用',
            vid: 'K8FEVACwuNoE',
            hid: 'zy-6_YVe-3K_',
            kuids: [ '16_02_06' ],
            euids: [ '16_06_19_00', '16_06_20_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '空間ベクトルの基本',
        hn: 'I A Ⅱ B C / 図形系 / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '1a2bc',
            qid: '11',
            qn: 'ベクトルはいぷりプレビュー11番',
            quid: '16_06_11',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 空間ベクトルの基本',
            vid: 'yK3Q6nCV0gu5',
            hid: 'bcIZ2RSHXuG4',
            kuids: [ '16_04_03', '16_04_04', '16_05_09', '16_05_06' ],
            euids: [ '16_10_34_00', '16_13_45_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '16',
            fid: '1a2bc',
            qid: '12',
            qn: 'ベクトルはいぷりプレビュー12番',
            quid: '16_06_12',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 空間ベクトルの基本',
            vid: 'OV7E8X3U9AzV',
            hid: 'JH79nQ6fGQE9',
            kuids: [ '16_05_03', '16_05_02' ],
            euids: [ '16_12_42_00', '16_12_43_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '内積と空間図形',
        hn: 'I A Ⅱ B C / 図形系 / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '1a2bc',
            qid: '13',
            qn: 'ベクトルはいぷりプレビュー13番',
            quid: '16_07_13',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 内積と空間図形',
            vid: 'pU4Pg7_B54ct',
            hid: 'g84xO0mE0Kzg',
            kuids: [ '16_05_01' ],
            euids: [ '16_12_40_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '16',
            fid: '1a2bc',
            qid: '14',
            qn: 'ベクトルはいぷりプレビュー14番',
            quid: '16_07_14',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / 内積と空間図形',
            vid: '66eRrjCGqEFm',
            hid: 'aLwej9CmPlYN',
            kuids: [ '16_06_01' ],
            euids: [ '16_13_44_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: 'ベクトル方程式（空間）',
        hn: 'I A Ⅱ B C / 図形系 / ベクトル',
        questions: [
          {
            uid: '16',
            fid: '1a2bc',
            qid: '15',
            qn: 'ベクトルはいぷりプレビュー15番',
            quid: '16_08_15',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / ベクトル方程式（空間）',
            vid: 'roGDtKseK_jx',
            hid: '_GsSa-CtOO4o',
            kuids: [ '16_05_04', '16_05_07' ],
            euids: [ '16_14_48_00', '16_14_47_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '16',
            fid: '1a2bc',
            qid: '16',
            qn: 'ベクトルはいぷりプレビュー16番',
            quid: '16_08_16',
            hn: 'I A Ⅱ B C / 図形系 / ベクトル / ベクトル方程式（空間）',
            vid: 'FZ9n5q5bBDvM',
            hid: 'nuKU66IEXV3U',
            kuids: [ '16_05_08' ],
            euids: [ '16_14_50_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '17',
    un: '平面上の曲線と複素数平面',
    fid: '1a2bc',
    did: '2',
    hn: 'I A Ⅱ B C / 図形系',
    prints: [
      {
        pid: '1',
        pn: '＜平面上の曲線＞　放物線',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '1',
            qn: '平面上の曲線はいぷりプレビュー1番',
            quid: '17_01_01',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　放物線',
            vid: '7Hgb3Cw8sFuq',
            hid: 'JjUcTn4mVtbI',
            kuids: [ '17_01_01', '17_01_04' ],
            euids: [ '17_01_01_01', '17_05_12_01' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '＜平面上の曲線＞　楕円　その１',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '2',
            qn: '平面上の曲線はいぷりプレビュー2番',
            quid: '17_02_02',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　楕円　その１',
            vid: 'XJ09qtdIJszI',
            hid: 'ni2Rcq40QBMJ',
            kuids: [ '17_01_02', '17_01_04' ],
            euids: [ '17_02_04_02', '17_05_12_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '3',
            qn: '平面上の曲線はいぷりプレビュー3番',
            quid: '17_02_03',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　楕円　その１',
            vid: 'BaeDrQ3FDIk0',
            hid: '8hcM41dk6dg7',
            kuids: [ '17_02_01' ],
            euids: [ '17_03_08_00', '17_03_09_00', '17_07_17_02' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '＜平面上の曲線＞　楕円　その２',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '4',
            qn: '平面上の曲線はいぷりプレビュー4番',
            quid: '17_03_04',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　楕円　その２',
            vid: 'Soz2x7YY71Fi',
            hid: 'lSahsYWI-13i',
            kuids: [ '17_01_04', '10_01_06', '09_02_03' ],
            euids: [
              '17_05_12_02',
              '17_05_13_02',
              '10_02_08_00',
              '09_03_08_00'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '5',
            qn: '平面上の曲線はいぷりプレビュー5番',
            quid: '17_03_05',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　楕円　その２',
            vid: 'TCB4vhZ3foBf',
            hid: 'wTae74CfDsyv',
            kuids: [ '17_01_02', '17_02_01' ],
            euids: [ '17_02_06_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '＜平面上の曲線＞　双曲線　その１',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '6',
            qn: '平面上の曲線はいぷりプレビュー6番',
            quid: '17_04_06',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　双曲線　その１',
            vid: 'zpOKs1Om5Z12',
            hid: '16NVmi_50jHJ',
            kuids: [ '17_01_03' ],
            euids: [ '17_04_10_01', '17_04_11_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '7',
            qn: '平面上の曲線はいぷりプレビュー7番',
            quid: '17_04_07',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　双曲線　その１',
            vid: 'VKUM4sf9Sf8O',
            hid: 'nJIfSBJ17wna',
            kuids: [ '17_01_03', '17_01_04', '16_01_05' ],
            euids: [ '17_04_10_03', '17_05_12_03', '16_03_10_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '＜平面上の曲線＞　双曲線　その２',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '8',
            qn: '平面上の曲線はいぷりプレビュー8番',
            quid: '17_05_08',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　双曲線　その２',
            vid: 'aLsWRx10DFg7',
            hid: 'tl2w6A0R8X3K',
            kuids: [ '17_01_04', '10_02_06' ],
            euids: [ '17_05_12_03', '10_06_24_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '9',
            qn: '平面上の曲線はいぷりプレビュー9番',
            quid: '17_05_09',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　双曲線　その２',
            vid: 'PIrbWmGd_zbE',
            hid: 'LyfeMiDMylxv',
            kuids: [ '17_01_04', '17_01_05' ],
            euids: [ '17_05_12_02', '17_05_12_03', '17_06_15_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '＜平面上の曲線＞　離心率・極座標',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '10',
            qn: '平面上の曲線はいぷりプレビュー10番',
            quid: '17_06_10',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　離心率・極座標',
            vid: 'SFU3ocQsS1bs',
            hid: 'HtkSUzH9VI0F',
            kuids: [ '17_01_05' ],
            euids: [ '17_06_15_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '11',
            qn: '平面上の曲線はいぷりプレビュー11番',
            quid: '17_06_11',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　離心率・極座標',
            vid: 'LdGa9esaTZ3f',
            hid: 'SZzr-yfqQy1P',
            kuids: [ '17_02_05', '17_02_04', '17_01_05' ],
            euids: [ '17_08_20_01', '17_08_20_02', '17_06_15_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '＜平面上の曲線＞　極座標と極方程式',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '12',
            qn: '平面上の曲線はいぷりプレビュー12番',
            quid: '17_07_12',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　極座標と極方程式',
            vid: 'j2h7rbVRI64J',
            hid: 'eGs_LkiCz0Kf',
            kuids: [ '17_02_05', '17_02_04' ],
            euids: [ '17_08_20_01', '17_08_20_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '13',
            qn: '平面上の曲線はいぷりプレビュー13番',
            quid: '17_07_13',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　極座標と極方程式',
            vid: 'pVePUIoc1Qo_',
            hid: 'iDcboJRdMPGW',
            kuids: [ '17_02_07', '17_02_05', '17_02_04' ],
            euids: [ '17_08_20_01', '17_08_20_02' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '＜平面上の曲線＞　媒介変数表示　その１',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '14',
            qn: '平面上の曲線はいぷりプレビュー14番',
            quid: '17_08_14',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示　その１',
            vid: 'Yd_5LuUP5pJL',
            hid: '5V9IY2dlo_v1',
            kuids: [ '19_03_06' ],
            euids: [ '19_06_09_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '15',
            qn: '平面上の曲線はいぷりプレビュー15番',
            quid: '17_08_15',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示　その１',
            vid: 'SlBV4a4P3cjY',
            hid: 'MdrbMf4eBts4',
            kuids: [ '19_03_06' ],
            euids: [ '19_06_09_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '＜平面上の曲線＞　媒介変数表示　その２',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '16',
            qn: '平面上の曲線はいぷりプレビュー16番',
            quid: '17_09_16',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示　その２',
            vid: 'dK52U606Y8pr',
            hid: 'VjD9G07NUAQr',
            kuids: [ '20_03_01' ],
            euids: [ '20_05_13_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '17',
            qn: '平面上の曲線はいぷりプレビュー17番',
            quid: '17_09_17',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜平面上の曲線＞　媒介変数表示　その２',
            vid: '_f_ndD8t_Gz4',
            hid: 'RpAsyv7Dgq49',
            kuids: [ '20_03_01' ],
            euids: [ '20_05_13_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '11',
        pn: '＜複素数平面＞　複素数平面と極形式',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '1',
            qn: '複素数平面はいぷりプレビュー1番',
            quid: '17_11_01',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜複素数平面＞　複素数平面と極形式',
            vid: 'mAysvv7xU5C6',
            hid: 'z3YxR5LiGHub',
            kuids: [ '17_04_02', '09_03_03' ],
            euids: [ '17_11_02_00', '09_05_18_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '2',
            qn: '複素数平面はいぷりプレビュー2番',
            quid: '17_11_02',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜複素数平面＞　複素数平面と極形式',
            vid: '3WWELTZvNZ9A',
            hid: 'HvKx6S-QeTnf',
            kuids: [ '17_04_03' ],
            euids: [ '17_14_12_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '12',
        pn: '＜複素数平面＞　７次からｎ次へ',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '3',
            qn: '複素数平面はいぷりプレビュー3番',
            quid: '17_12_03',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜複素数平面＞　７次からｎ次へ',
            vid: 'AMiStKluDsEC',
            hid: 'WH-azW1HxjBB',
            kuids: [ '17_04_04' ],
            euids: [ '17_14_13_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '4',
            qn: '複素数平面はいぷりプレビュー4番',
            quid: '17_12_04',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜複素数平面＞　７次からｎ次へ',
            vid: 'fIsx6uJ8fxRx',
            hid: 'Z-wmH3NddVjW',
            kuids: [ '17_04_02' ],
            euids: [ '17_11_05_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '13',
        pn: '＜複素数平面＞　三角形',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '5',
            qn: '複素数平面はいぷりプレビュー5番',
            quid: '17_13_05',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜複素数平面＞　三角形',
            vid: '-DBFjr0ulLuQ',
            hid: 'fEgUYylx1oug',
            kuids: [ '17_05_01' ],
            euids: [ '17_15_14_01', '17_15_14_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '6',
            qn: '複素数平面はいぷりプレビュー6番',
            quid: '17_13_06',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜複素数平面＞　三角形',
            vid: 'GLyVT8ReFZKf',
            hid: 'HHSrb5O9ikJ1',
            kuids: [ '17_05_01' ],
            euids: [ '17_15_15_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '7',
            qn: '複素数平面はいぷりプレビュー7番',
            quid: '17_13_07',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜複素数平面＞　三角形',
            vid: 'wVldmjdzZrQx',
            hid: 'jbQnvoL0ZuXj',
            kuids: [ '17_05_01' ],
            euids: [ '17_15_15_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '14',
        pn: '＜複素数平面＞　軌跡（基本）',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '8',
            qn: '複素数平面はいぷりプレビュー8番',
            quid: '17_14_08',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜複素数平面＞　軌跡（基本）',
            vid: 'ax7LY8R8Hach',
            hid: 'JouOzdKta1i1',
            kuids: [ '17_06_02' ],
            euids: [ '17_18_23_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '9',
            qn: '複素数平面はいぷりプレビュー9番',
            quid: '17_14_09',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜複素数平面＞　軌跡（基本）',
            vid: 'HmBTZSzXsHfp',
            hid: 'DgdVFzeceTzt',
            kuids: [ '17_06_02' ],
            euids: [ '17_17_22_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '10',
            qn: '複素数平面はいぷりプレビュー10番',
            quid: '17_14_10',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜複素数平面＞　軌跡（基本）',
            vid: 'ihhpp0_g6mda',
            hid: '_Cq3j6u4G2-4',
            kuids: [ '17_06_02' ],
            euids: [ '17_17_22_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '15',
        pn: '＜複素数平面＞　軌跡（応用）',
        hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面',
        questions: [
          {
            uid: '17',
            fid: '1a2bc',
            qid: '11',
            qn: '複素数平面はいぷりプレビュー11番',
            quid: '17_15_11',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜複素数平面＞　軌跡（応用）',
            vid: 'EaQtZKff3Rqr',
            hid: 'uN2ilm4ITPBt',
            kuids: [ '17_07_01' ],
            euids: [],
            huids: [],
            record: {}
          },
          {
            uid: '17',
            fid: '1a2bc',
            qid: '12',
            qn: '複素数平面はいぷりプレビュー12番',
            quid: '17_15_12',
            hn: 'I A Ⅱ B C / 図形系 / 平面上の曲線と複素数平面 / ＜複素数平面＞　軌跡（応用）',
            vid: 'ujfnvSSLqxFQ',
            hid: '5dki-NMO6dx8',
            kuids: [ '17_07_01', '17_07_02' ],
            euids: [],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '2',
    un: '2次関数',
    fid: '1a2bc',
    did: '3',
    hn: 'I A Ⅱ B C / 関数系',
    prints: [
      {
        pid: '1',
        pn: '２次関数の最大最小',
        hn: 'I A Ⅱ B C / 関数系 / 2次関数',
        questions: [
          {
            uid: '2',
            fid: '1a2bc',
            qid: '1',
            qn: '2次関数はいぷりプレビュー1番',
            quid: '02_01_01',
            hn: 'I A Ⅱ B C / 関数系 / 2次関数 / ２次関数の最大最小',
            vid: 'oOH6hMsXl-SA',
            hid: 'HV0qngFnG-mi',
            kuids: [ '02_02_04' ],
            euids: [ '02_04_11_00', '02_04_12_00', '02_04_13_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '2',
            fid: '1a2bc',
            qid: '2',
            qn: '2次関数はいぷりプレビュー2番',
            quid: '02_01_02',
            hn: 'I A Ⅱ B C / 関数系 / 2次関数 / ２次関数の最大最小',
            vid: 'jQHNBru_pLl_',
            hid: 'IaZRvORBc_N4',
            kuids: [ '02_02_05' ],
            euids: [ '02_04_14_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '２次方程式と２次不等式',
        hn: 'I A Ⅱ B C / 関数系 / 2次関数',
        questions: [
          {
            uid: '2',
            fid: '1a2bc',
            qid: '3',
            qn: '2次関数はいぷりプレビュー3番',
            quid: '02_02_03',
            hn: 'I A Ⅱ B C / 関数系 / 2次関数 / ２次方程式と２次不等式',
            vid: 'wp8LS3oXBthf',
            hid: 'MMJqqeqeok7l',
            kuids: [ '02_04_03' ],
            euids: [ '02_09_29_00', '02_09_30_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '2',
            fid: '1a2bc',
            qid: '4',
            qn: '2次関数はいぷりプレビュー4番',
            quid: '02_02_04',
            hn: 'I A Ⅱ B C / 関数系 / 2次関数 / ２次方程式と２次不等式',
            vid: 'JK3OXOSWAqWA',
            hid: 'asoGbOLjKorw',
            kuids: [ '02_02_01', '02_05_04' ],
            euids: [ '02_02_04_00', '02_10_33_00', '02_10_34_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '２次不等式の応用',
        hn: 'I A Ⅱ B C / 関数系 / 2次関数',
        questions: [
          {
            uid: '2',
            fid: '1a2bc',
            qid: '5',
            qn: '2次関数はいぷりプレビュー5番',
            quid: '02_03_05',
            hn: 'I A Ⅱ B C / 関数系 / 2次関数 / ２次不等式の応用',
            vid: 'UCqiUutvnJ7l',
            hid: 'rO58et-Hlz0k',
            kuids: [ '02_05_04' ],
            euids: [ '02_10_33_00', '02_10_35_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '2',
            fid: '1a2bc',
            qid: '6',
            qn: '2次関数はいぷりプレビュー6番',
            quid: '02_03_06',
            hn: 'I A Ⅱ B C / 関数系 / 2次関数 / ２次不等式の応用',
            vid: 'rrbk-btaFkrG',
            hid: 'qAH0HGUUykLT',
            kuids: [ '02_05_02', '01_06_04' ],
            euids: [ '02_10_31_00', '01_09_29_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '11',
    un: '三角関数',
    fid: '1a2bc',
    did: '3',
    hn: 'I A Ⅱ B C / 関数系',
    prints: [
      {
        pid: '1',
        pn: '基本編　その１',
        hn: 'I A Ⅱ B C / 関数系 / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '1a2bc',
            qid: '1',
            qn: '三角関数はいぷりプレビュー1番',
            quid: '11_01_01',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 基本編　その１',
            vid: 'Q2bp-Re6xYys',
            hid: '3hhKh5VHJ2gJ',
            kuids: [ '11_02_01' ],
            euids: [ '11_02_06_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '11',
            fid: '1a2bc',
            qid: '2',
            qn: '三角関数はいぷりプレビュー2番',
            quid: '11_01_02',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 基本編　その１',
            vid: 'MabjXsobm6xP',
            hid: 'lJMUXW9UPzSL',
            kuids: [ '11_01_01' ],
            euids: [ '11_01_04_03' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '基本編　その２',
        hn: 'I A Ⅱ B C / 関数系 / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '1a2bc',
            qid: '3',
            qn: '三角関数はいぷりプレビュー3番',
            quid: '11_02_03',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 基本編　その２',
            vid: 'fqhrRwaGoZh3',
            hid: 'RgZi5xWsT8E5',
            kuids: [ '11_03_01', '11_03_02' ],
            euids: [ '11_02_07_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '11',
            fid: '1a2bc',
            qid: '4',
            qn: '三角関数はいぷりプレビュー4番',
            quid: '11_02_04',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 基本編　その２',
            vid: 'nVpU2iWkBhHy',
            hid: 'd9f1najR7HsE',
            kuids: [ '11_04_01', '08_05_01' ],
            euids: [ '11_03_10_00', '08_06_14_03' ],
            huids: [],
            record: {}
          },
          {
            uid: '11',
            fid: '1a2bc',
            qid: '5',
            qn: '三角関数はいぷりプレビュー5番',
            quid: '11_02_05',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 基本編　その２',
            vid: 'FgPVR7Ac08ti',
            hid: 'NKMtWgmPw7Kb',
            kuids: [ '11_04_02', '03_05_01' ],
            euids: [ '11_03_11_01', '11_03_11_03', '11_01_03_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '応用編　その１',
        hn: 'I A Ⅱ B C / 関数系 / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '1a2bc',
            qid: '6',
            qn: '三角関数はいぷりプレビュー6番',
            quid: '11_03_06',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 応用編　その１',
            vid: 'zASXcfswWNfn',
            hid: 'LwKbyySUwbhO',
            kuids: [ '11_11_01' ],
            euids: [ '11_05_18_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '11',
            fid: '1a2bc',
            qid: '7',
            qn: '三角関数はいぷりプレビュー7番',
            quid: '11_03_07',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 応用編　その１',
            vid: 'YjhWco-2zFxq',
            hid: 'MyfpcF2DogHi',
            kuids: [ '11_12_02', '02_02_04' ],
            euids: [ '11_05_20_00', '02_04_12_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '11',
            fid: '1a2bc',
            qid: '8',
            qn: '三角関数はいぷりプレビュー8番',
            quid: '11_03_08',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 応用編　その１',
            vid: 'XV14LkyzkUi0',
            hid: 'wls30LPJ_jJy',
            kuids: [ '11_12_03' ],
            euids: [ '11_06_21_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '応用編　その２',
        hn: 'I A Ⅱ B C / 関数系 / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '1a2bc',
            qid: '9',
            qn: '三角関数はいぷりプレビュー9番',
            quid: '11_04_09',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 応用編　その２',
            vid: 'lEOrw4PQ6d-E',
            hid: 'ac071aRu_ewI',
            kuids: [ '11_13_02' ],
            euids: [ '11_06_23_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '11',
            fid: '1a2bc',
            qid: '10',
            qn: '三角関数はいぷりプレビュー10番',
            quid: '11_04_10',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 応用編　その２',
            vid: '1w45mXzjlU1B',
            hid: 'BdBPquZxlW6Q',
            kuids: [ '11_14_01' ],
            euids: [ '11_06_24_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '11',
            fid: '1a2bc',
            qid: '11',
            qn: '三角関数はいぷりプレビュー11番',
            quid: '11_04_11',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 応用編　その２',
            vid: '1OKsh95_BRb7',
            hid: 'V3lgQWv-fbA7',
            kuids: [ '11_15_01' ],
            euids: [ '11_07_25_00', '11_07_26_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '応用編　その３',
        hn: 'I A Ⅱ B C / 関数系 / 三角関数',
        questions: [
          {
            uid: '11',
            fid: '1a2bc',
            qid: '12',
            qn: '三角関数はいぷりプレビュー12番',
            quid: '11_05_12',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 応用編　その３',
            vid: 'b1jumeGb3vjz',
            hid: 'h12GsABbpQT9',
            kuids: [ '11_16_01' ],
            euids: [ '11_07_27_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '11',
            fid: '1a2bc',
            qid: '13',
            qn: '三角関数はいぷりプレビュー13番',
            quid: '11_05_13',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 応用編　その３',
            vid: 'UdhDzYMYRZPa',
            hid: '3lArnh_H-KMr',
            kuids: [ '11_17_01' ],
            euids: [ '11_07_28_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '11',
            fid: '1a2bc',
            qid: '15',
            qn: '三角関数はいぷりプレビュー14番',
            quid: '11_05_15',
            hn: 'I A Ⅱ B C / 関数系 / 三角関数 / 応用編　その３',
            vid: 'PlYTnh5SMpyH',
            hid: 'INBTZNsY0GyH',
            kuids: [ '11_13_02', '11_04_02', '13_01_07' ],
            euids: [ '11_06_23_00', '11_03_11_03', '13_03_09_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '12',
    un: '指数対数関数',
    fid: '1a2bc',
    did: '3',
    hn: 'I A Ⅱ B C / 関数系',
    prints: [
      {
        pid: '1',
        pn: '法則と性質・方程式の基本',
        hn: 'I A Ⅱ B C / 関数系 / 指数対数関数',
        questions: [
          {
            uid: '12',
            fid: '1a2bc',
            qid: '1',
            qn: '指数対数関数はいぷりプレビュー1番',
            quid: '12_01_01',
            hn: 'I A Ⅱ B C / 関数系 / 指数対数関数 / 法則と性質・方程式の基本',
            vid: 'v6ovyVPzzvHj',
            hid: 'm95doPkoFPIq',
            kuids: [ '12_01_04', '12_01_05', '12_01_06' ],
            euids: [ '12_02_04_00', '12_02_05_00', '12_02_06_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '12',
            fid: '1a2bc',
            qid: '2',
            qn: '指数対数関数はいぷりプレビュー2番',
            quid: '12_01_02',
            hn: 'I A Ⅱ B C / 関数系 / 指数対数関数 / 法則と性質・方程式の基本',
            vid: 'gf8ijgDwcibM',
            hid: '1OpKHM5-fNV9',
            kuids: [ '12_02_02', '12_02_04' ],
            euids: [
              '12_03_08_01',
              '12_03_08_01',
              '12_04_11_01',
              '12_04_11_02'
            ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '不等式・方程式の応用',
        hn: 'I A Ⅱ B C / 関数系 / 指数対数関数',
        questions: [
          {
            uid: '12',
            fid: '1a2bc',
            qid: '3',
            qn: '指数対数関数はいぷりプレビュー3番',
            quid: '12_02_03',
            hn: 'I A Ⅱ B C / 関数系 / 指数対数関数 / 不等式・方程式の応用',
            vid: 'd4tgh1g-TNkr',
            hid: 'hzp75Ctx5akM',
            kuids: [ '12_02_03', '12_02_05' ],
            euids: [ '12_03_09_03', '12_04_12_04', '12_04_12_03' ],
            huids: [],
            record: {}
          },
          {
            uid: '12',
            fid: '1a2bc',
            qid: '4',
            qn: '指数対数関数はいぷりプレビュー4番',
            quid: '12_02_04',
            hn: 'I A Ⅱ B C / 関数系 / 指数対数関数 / 不等式・方程式の応用',
            vid: 'NqCkFFwswkdv',
            hid: '-BzPckfWJOdM',
            kuids: [ '12_02_02', '12_02_04', '02_04_03' ],
            euids: [ '12_03_10_00', '12_04_11_03', '02_09_27_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '最大最小（基本的なもの）',
        hn: 'I A Ⅱ B C / 関数系 / 指数対数関数',
        questions: [
          {
            uid: '12',
            fid: '1a2bc',
            qid: '5',
            qn: '指数対数関数はいぷりプレビュー5番',
            quid: '12_03_05',
            hn: 'I A Ⅱ B C / 関数系 / 指数対数関数 / 最大最小（基本的なもの）',
            vid: 'SdFHNECtVdRc',
            hid: '5VFwP7g_06WK',
            kuids: [ '12_03_01', '12_03_02', '02_02_04' ],
            euids: [ '12_05_13_01', '12_05_13_03', '02_04_11_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '12',
            fid: '1a2bc',
            qid: '6',
            qn: '指数対数関数はいぷりプレビュー6番',
            quid: '12_03_06',
            hn: 'I A Ⅱ B C / 関数系 / 指数対数関数 / 最大最小（基本的なもの）',
            vid: 'G9qxc9ools22',
            hid: 'v2L2TstaQF8a',
            kuids: [ '12_03_03', '12_03_04', '12_03_05' ],
            euids: [
              '12_05_14_01',
              '12_05_14_02',
              '12_05_14_03',
              '12_05_14_04'
            ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '種々の応用題（対称式＆領域）',
        hn: 'I A Ⅱ B C / 関数系 / 指数対数関数',
        questions: [
          {
            uid: '12',
            fid: '1a2bc',
            qid: '7',
            qn: '指数対数関数はいぷりプレビュー7番',
            quid: '12_04_07',
            hn: 'I A Ⅱ B C / 関数系 / 指数対数関数 / 種々の応用題（対称式＆領域）',
            vid: 'ta-i4mbBMEYF',
            hid: 'hHaDgMp7c3jr',
            kuids: [ '12_04_01', '12_01_03', '13_01_07' ],
            euids: [
              '12_06_15_00',
              '12_06_16_00',
              '12_01_02_01',
              '13_03_09_00'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '12',
            fid: '1a2bc',
            qid: '8',
            qn: '指数対数関数はいぷりプレビュー8番',
            quid: '12_04_08',
            hn: 'I A Ⅱ B C / 関数系 / 指数対数関数 / 種々の応用題（対称式＆領域）',
            vid: 'P9Uaq_CXE7zY',
            hid: 'PoaTKUhI8g5b',
            kuids: [ '12_04_02', '10_04_02' ],
            euids: [
              '12_07_18_01',
              '12_07_18_02',
              '12_07_18_03',
              '10_09_37_00'
            ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '大小比較・桁数問題',
        hn: 'I A Ⅱ B C / 関数系 / 指数対数関数',
        questions: [
          {
            uid: '12',
            fid: '1a2bc',
            qid: '9',
            qn: '指数対数関数はいぷりプレビュー9番',
            quid: '12_05_09',
            hn: 'I A Ⅱ B C / 関数系 / 指数対数関数 / 大小比較・桁数問題',
            vid: '6LxtVfetWeJc',
            hid: 'RPp61VB-7yuk',
            kuids: [ '12_04_03' ],
            euids: [ '12_06_17_01', '12_06_17_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '12',
            fid: '1a2bc',
            qid: '10',
            qn: '指数対数関数はいぷりプレビュー10番',
            quid: '12_05_10',
            hn: 'I A Ⅱ B C / 関数系 / 指数対数関数 / 大小比較・桁数問題',
            vid: '2U988bnp9-JN',
            hid: 'vRkON3LRH8B0',
            kuids: [ '12_05_02', '12_05_03' ],
            euids: [
              '12_09_22_98',
              '12_09_23_98',
              '12_09_24_98',
              '12_09_25_98'
            ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '13',
    un: '数Ⅱ微積',
    fid: '1a2bc',
    did: '3',
    hn: 'I A Ⅱ B C / 関数系',
    prints: [
      {
        pid: '1',
        pn: '極限',
        hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積',
        questions: [
          {
            uid: '13',
            fid: '1a2bc',
            qid: '1',
            qn: '数・微積はいぷりプレビュー1番',
            quid: '13_01_01',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 極限',
            vid: 'S2uydKx4Z-Qj',
            hid: 'mRo0MXpLOBwo',
            kuids: [ '13_01_01' ],
            euids: [ '13_01_02_01', '13_01_02_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '13',
            fid: '1a2bc',
            qid: '2',
            qn: '数・微積はいぷりプレビュー2番',
            quid: '13_01_02',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 極限',
            vid: 'ly5ptjwFL8k3',
            hid: 'U4CJzoTFiL7h',
            kuids: [ '13_01_02' ],
            euids: [ '13_01_04_02' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '極大・極小',
        hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積',
        questions: [
          {
            uid: '13',
            fid: '1a2bc',
            qid: '3',
            qn: '数・微積はいぷりプレビュー3番',
            quid: '13_02_03',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 極大・極小',
            vid: 'E6LgpPaY7b4N',
            hid: 'ehYZGraODJ5q',
            kuids: [ '13_01_05', '13_01_06', '13_02_06' ],
            euids: [ '13_02_08_02', '13_02_08_03', '13_07_28_04' ],
            huids: [],
            record: {}
          },
          {
            uid: '13',
            fid: '1a2bc',
            qid: '4',
            qn: '数・微積はいぷりプレビュー4番',
            quid: '13_02_04',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 極大・極小',
            vid: '9XAb_KMlzGZA',
            hid: 'uykwULT_Bo4d',
            kuids: [ '13_01_08', '09_02_03' ],
            euids: [ '13_05_18_00', '09_03_08_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '13',
            fid: '1a2bc',
            qid: '5',
            qn: '数・微積はいぷりプレビュー5番',
            quid: '13_02_05',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 極大・極小',
            vid: 'rsjtjOmTbm6e',
            hid: 'hQZblvpbnf8t',
            kuids: [ '13_01_05', '13_01_06', '02_04_03' ],
            euids: [ '13_02_08_01', '02_09_28_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '最大最小',
        hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積',
        questions: [
          {
            uid: '13',
            fid: '1a2bc',
            qid: '6',
            qn: '数・微積はいぷりプレビュー6番',
            quid: '13_03_06',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 最大最小',
            vid: 'jMEU6sJqtdBx',
            hid: 'XSveObVVM1GW',
            kuids: [ '13_01_01', '13_01_07' ],
            euids: [
              '13_01_02_01',
              '13_03_11_00',
              '13_03_12_00',
              '13_03_13_00'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '13',
            fid: '1a2bc',
            qid: '7',
            qn: '数・微積はいぷりプレビュー7番',
            quid: '13_03_07',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 最大最小',
            vid: 'Y2-q_bGeHoRA',
            hid: 'AHnzV9Bmuiup',
            kuids: [ '09_02_02', '13_01_07', '09_02_01' ],
            euids: [ '13_03_09_00', '09_02_06_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '方程式＆不等式への応用',
        hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積',
        questions: [
          {
            uid: '13',
            fid: '1a2bc',
            qid: '8',
            qn: '数・微積はいぷりプレビュー8番',
            quid: '13_04_08',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 方程式＆不等式への応用',
            vid: 'f5mUoVz8h4n3',
            hid: 'q3ROvN6wN8Hc',
            kuids: [ '13_01_08', '09_03_03' ],
            euids: [ '13_05_18_00', '13_05_19_00', '09_05_18_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '13',
            fid: '1a2bc',
            qid: '9',
            qn: '数・微積はいぷりプレビュー9番',
            quid: '13_04_09',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 方程式＆不等式への応用',
            vid: 'OPJvCPgbY_Im',
            hid: 'ETjLnKuWL4ss',
            kuids: [ '13_01_09' ],
            euids: [ '13_05_21_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '接線',
        hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積',
        questions: [
          {
            uid: '13',
            fid: '1a2bc',
            qid: '10',
            qn: '数・微積はいぷりプレビュー10番',
            quid: '13_05_10',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 接線',
            vid: 'gskVLOrL1GFE',
            hid: 'ewmdVDCU1L8R',
            kuids: [ '13_01_10', '13_01_11' ],
            euids: [ '13_06_22_03', '13_06_25_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '13',
            fid: '1a2bc',
            qid: '11',
            qn: '数・微積はいぷりプレビュー11番',
            quid: '13_05_11',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 接線',
            vid: 'CEZm5aG97pAS',
            hid: '5K9ew13DZgbd',
            kuids: [ '13_01_10', '11_04_01', '08_05_01' ],
            euids: [ '13_06_22_01', '11_03_10_00', '08_06_14_03' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '定積分で表された関数',
        hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積',
        questions: [
          {
            uid: '13',
            fid: '1a2bc',
            qid: '12',
            qn: '数・微積はいぷりプレビュー12番',
            quid: '13_06_12',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 定積分で表された関数',
            vid: '8cnr7L72WTeB',
            hid: 'IsTN0yIOzZwD',
            kuids: [ '13_02_04' ],
            euids: [ '13_07_28_02', '13_08_32_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '13',
            fid: '1a2bc',
            qid: '13',
            qn: '数・微積はいぷりプレビュー13番',
            quid: '13_06_13',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 定積分で表された関数',
            vid: 'oFed_2DiLhC2',
            hid: 'FxvpxuZW2j40',
            kuids: [ '13_02_07' ],
            euids: [ '13_09_33_01', '13_09_33_02', '13_09_34_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '面積　その１',
        hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積',
        questions: [
          {
            uid: '13',
            fid: '1a2bc',
            qid: '14',
            qn: '数・微積はいぷりプレビュー14番',
            quid: '13_07_14',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 面積　その１',
            vid: 'OaoVEW-7tQLG',
            hid: 'qH9YG9m1f0OA',
            kuids: [ '13_02_08', '13_01_10' ],
            euids: [ '13_09_35_03', '13_06_22_01' ],
            huids: [],
            record: {}
          },
          {
            uid: '13',
            fid: '1a2bc',
            qid: '15',
            qn: '数・微積はいぷりプレビュー15番',
            quid: '13_07_15',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 面積　その１',
            vid: 'UMM929c43uqS',
            hid: 'EwQ7k5jMQRPL',
            kuids: [ '13_02_09', '13_02_08' ],
            euids: [ '13_10_37_00', '13_09_35_02', '13_09_36_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '13',
            fid: '1a2bc',
            qid: '16',
            qn: '数・微積はいぷりプレビュー16番',
            quid: '13_07_16',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 面積　その１',
            vid: 'YR8hj7gHcPrV',
            hid: 'OKD8yWDvG6Ln',
            kuids: [ '13_02_10' ],
            euids: [ '13_10_38_00', '13_10_39_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '面積　その２',
        hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積',
        questions: [
          {
            uid: '13',
            fid: '1a2bc',
            qid: '17',
            qn: '数・微積はいぷりプレビュー17番',
            quid: '13_08_17',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 面積　その２',
            vid: 'T0jqmgL_hfoq',
            hid: 'QM9IzQGP-mVA',
            kuids: [ '13_02_11' ],
            euids: [ '13_11_40_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '13',
            fid: '1a2bc',
            qid: '18',
            qn: '数・微積はいぷりプレビュー18番',
            quid: '13_08_18',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 面積　その２',
            vid: 'dwPWcZ-4QZ7L',
            hid: 'I6__qWYJgv97',
            kuids: [ '13_02_12', '13_02_06' ],
            euids: [ '13_11_41_00', '13_07_28_05' ],
            huids: [],
            record: {}
          },
          {
            uid: '13',
            fid: '1a2bc',
            qid: '19',
            qn: '数・微積はいぷりプレビュー19番',
            quid: '13_08_19',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 面積　その２',
            vid: 'D6dPKy1-YeBf',
            hid: 'TowOEua3W434',
            kuids: [ '13_02_12', '13_02_06', '13_01_11' ],
            euids: [ '13_11_41_00', '13_07_28_05', '13_06_23_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '面積　その３',
        hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積',
        questions: [
          {
            uid: '13',
            fid: '1a2bc',
            qid: '20',
            qn: '数・微積はいぷりプレビュー20番',
            quid: '13_09_20',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 面積　その３',
            vid: 'SNeB16Kp0Hzd',
            hid: 'NRUgw5dpdfTk',
            kuids: [ '13_02_13' ],
            euids: [ '13_11_42_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '13',
            fid: '1a2bc',
            qid: '21',
            qn: '数・微積はいぷりプレビュー21番',
            quid: '13_09_21',
            hn: 'I A Ⅱ B C / 関数系 / 数Ⅱ微積 / 面積　その３',
            vid: 'DCcOPd9cZFKL',
            hid: '_NSpurBxRNO9',
            kuids: [ '13_02_06' ],
            euids: [ '13_07_28_04' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '5',
    un: '場合の数と確率',
    fid: '1a2bc',
    did: '4',
    hn: 'I A Ⅱ B C / 確率統計系',
    prints: [
      {
        pid: '1',
        pn: '順列・組み合わせ',
        hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a2bc',
            qid: '1',
            qn: '場合の数と確率はいぷりプレビュー1番',
            quid: '05_01_01',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 順列・組み合わせ',
            vid: 'kgudtfDeMbRN',
            hid: 'KdYmb6YUJm94',
            kuids: [ '05_01_01', '05_01_02', '05_01_03' ],
            euids: [ '05_01_03_00', '05_02_05_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '5',
            fid: '1a2bc',
            qid: '2',
            qn: '場合の数と確率はいぷりプレビュー2番',
            quid: '05_01_02',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 順列・組み合わせ',
            vid: 'n4yjhLNn48EE',
            hid: '3bCxvDQhcqZD',
            kuids: [ '05_01_03', '05_01_04' ],
            euids: [ '05_02_05_00', '05_02_07_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '5',
            fid: '1a2bc',
            qid: '3',
            qn: '場合の数と確率はいぷりプレビュー3番',
            quid: '05_01_03',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 順列・組み合わせ',
            vid: 'DrNrjVlUS3z1',
            hid: 'Oy7AlzQAkhqm',
            kuids: [ '05_02_02' ],
            euids: [ '05_05_13_00', '05_05_14_01', '05_05_14_02' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '組み合わせ３頻出',
        hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a2bc',
            qid: '4',
            qn: '場合の数と確率はいぷりプレビュー4番',
            quid: '05_02_04',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 組み合わせ３頻出',
            vid: 'NjZAu3BrkbwZ',
            hid: '5ImyfvnuxuD2',
            kuids: [ '05_02_03' ],
            euids: [ '05_06_15_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '5',
            fid: '1a2bc',
            qid: '5',
            qn: '場合の数と確率はいぷりプレビュー5番',
            quid: '05_02_05',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 組み合わせ３頻出',
            vid: 'TetZ8rf5EQ_w',
            hid: 'xM6Nym3yWKox',
            kuids: [ '05_02_04' ],
            euids: [ '05_06_16_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '5',
            fid: '1a2bc',
            qid: '6',
            qn: '場合の数と確率はいぷりプレビュー6番',
            quid: '05_02_06',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 組み合わせ３頻出',
            vid: 'xOSxK43oRPdH',
            hid: 'drIrY99sn7fv',
            kuids: [ '05_02_05' ],
            euids: [ '05_06_17_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '確率の基本',
        hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a2bc',
            qid: '7',
            qn: '場合の数と確率はいぷりプレビュー7番',
            quid: '05_03_07',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 確率の基本',
            vid: 'BaLvb0QCWIaG',
            hid: 't1qAKTUaIpx2',
            kuids: [ '05_03_01' ],
            euids: [ '05_08_23_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '5',
            fid: '1a2bc',
            qid: '8',
            qn: '場合の数と確率はいぷりプレビュー8番',
            quid: '05_03_08',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 確率の基本',
            vid: 'bpXK7Onp8Bfh',
            hid: 'vLs4PXvjeG2V',
            kuids: [ '05_03_02' ],
            euids: [ '05_09_24_00', '05_09_25_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '独立試行・反復試行',
        hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a2bc',
            qid: '9',
            qn: '場合の数と確率はいぷりプレビュー9番',
            quid: '05_04_09',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 独立試行・反復試行',
            vid: 'TjiGJX7TdSVo',
            hid: 'nIUzWJHD-Roh',
            kuids: [ '05_03_02', '05_03_11' ],
            euids: [
              '05_09_24_00',
              '05_09_25_00',
              '05_13_36_00',
              '05_13_37_00'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '5',
            fid: '1a2bc',
            qid: '10',
            qn: '場合の数と確率はいぷりプレビュー10番',
            quid: '05_04_10',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 独立試行・反復試行',
            vid: '8y5yzhRRNIAu',
            hid: 'FxX6zoC0hR_x',
            kuids: [ '05_03_04' ],
            euids: [ '05_10_27_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '条件つき確率',
        hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a2bc',
            qid: '11',
            qn: '場合の数と確率はいぷりプレビュー11番',
            quid: '05_05_11',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 条件つき確率',
            vid: '4XuJ8Qz0vhmu',
            hid: '8LdI8Qt3kRvt',
            kuids: [ '05_03_05' ],
            euids: [ '05_11_29_00', '05_11_30_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '5',
            fid: '1a2bc',
            qid: '12',
            qn: '場合の数と確率はいぷりプレビュー12番',
            quid: '05_05_12',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 条件つき確率',
            vid: 'C1HRIKzfzEpo',
            hid: 'FO45xWlrq2Zx',
            kuids: [ '05_03_05', '05_03_13' ],
            euids: [ '05_11_31_00', '05_14_40_00', '05_14_41_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: 'じゃんけん・点の移動',
        hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a2bc',
            qid: '13',
            qn: '場合の数と確率はいぷりプレビュー13番',
            quid: '05_06_13',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / じゃんけん・点の移動',
            vid: 'XusWPvIs1Soh',
            hid: '_vm0bFSa0P2t',
            kuids: [ '05_03_12' ],
            euids: [ '05_13_38_00', '05_13_39_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '5',
            fid: '1a2bc',
            qid: '14',
            qn: '場合の数と確率はいぷりプレビュー14番',
            quid: '05_06_14',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / じゃんけん・点の移動',
            vid: 'Nl751CPqqsM6',
            hid: '7uZu8tpPQxRS',
            kuids: [ '05_03_14' ],
            euids: [ '05_14_42_00', '05_14_43_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '最大確率・Σ',
        hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a2bc',
            qid: '15',
            qn: '場合の数と確率はいぷりプレビュー15番',
            quid: '05_07_15',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 最大確率・Σ',
            vid: 'tNNcOVJwWHNn',
            hid: 'qOi5-_XeFEB0',
            kuids: [ '05_03_21', '05_03_13' ],
            euids: [ '05_12_33_00', '05_14_41_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '5',
            fid: '1a2bc',
            qid: '16',
            qn: '場合の数と確率はいぷりプレビュー16番',
            quid: '05_07_16',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 最大確率・Σ',
            vid: 'o9gAAAzBw9cR',
            hid: 'Fsznors502V6',
            kuids: [ '05_03_01', '14_03_02' ],
            euids: [
              '05_08_21_00',
              '05_08_22_00',
              '14_03_10_01',
              '14_03_10_07'
            ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '確率と漸化式',
        hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率',
        questions: [
          {
            uid: '5',
            fid: '1a2bc',
            qid: '17',
            qn: '場合の数と確率はいぷりプレビュー17番',
            quid: '05_08_17',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 確率と漸化式',
            vid: 'TNN0LngoU3V6',
            hid: 'G7eR3zTgROYX',
            kuids: [ '05_03_22', '14_07_03' ],
            euids: [ '05_12_34_00', '14_06_20_06' ],
            huids: [],
            record: {}
          },
          {
            uid: '5',
            fid: '1a2bc',
            qid: '18',
            qn: '場合の数と確率はいぷりプレビュー18番',
            quid: '05_08_18',
            hn: 'I A Ⅱ B C / 確率統計系 / 場合の数と確率 / 確率と漸化式',
            vid: '4EuwboU3iP6a',
            hid: 'J6WZJc28wKOq',
            kuids: [ '05_03_22', '14_07_03' ],
            euids: [ '05_12_34_00', '14_06_20_06' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '4',
    un: 'データの分析',
    fid: '1a2bc',
    did: '4',
    hn: 'I A Ⅱ B C / 確率統計系',
    prints: [
      {
        pid: '1',
        pn: '代表値・分散と標準偏差',
        hn: 'I A Ⅱ B C / 確率統計系 / データの分析',
        questions: [
          {
            uid: '4',
            fid: '1a2bc',
            qid: '1',
            qn: 'データの分析はいぷりプレビュー1番',
            quid: '04_01_01',
            hn: 'I A Ⅱ B C / 確率統計系 / データの分析 / 代表値・分散と標準偏差',
            vid: 'tPOsKSgAC0yL',
            hid: 'muBUHm-p5N-N',
            kuids: [ '04_01_02', '04_01_03' ],
            euids: [ '04_01_02_00', '04_02_05_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '4',
            fid: '1a2bc',
            qid: '2',
            qn: 'データの分析はいぷりプレビュー2番',
            quid: '04_01_02',
            hn: 'I A Ⅱ B C / 確率統計系 / データの分析 / 代表値・分散と標準偏差',
            vid: 'a93-SzWJNxJb',
            hid: 'xVf5ZQ52T0sZ',
            kuids: [ '04_03_01' ],
            euids: [ '04_05_11_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '4',
            fid: '1a2bc',
            qid: '3',
            qn: 'データの分析はいぷりプレビュー3番',
            quid: '04_01_03',
            hn: 'I A Ⅱ B C / 確率統計系 / データの分析 / 代表値・分散と標準偏差',
            vid: '14wv6hulZe0k',
            hid: 'w6AaFhZdRZlU',
            kuids: [ '04_03_01', '04_03_02', '04_04_02' ],
            euids: [ '04_05_11_00', '04_05_12_00', '04_08_18_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '変量変換',
        hn: 'I A Ⅱ B C / 確率統計系 / データの分析',
        questions: [
          {
            uid: '4',
            fid: '1a2bc',
            qid: '4',
            qn: 'データの分析はいぷりプレビュー4番',
            quid: '04_02_04',
            hn: 'I A Ⅱ B C / 確率統計系 / データの分析 / 変量変換',
            vid: '4sLuMfaH_F11',
            hid: 'vRHshVkOPEna',
            kuids: [ '04_03_03', '04_03_04', '04_04_02' ],
            euids: [ '04_06_15_00', '04_08_18_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '偏差値・共分散と平均値',
        hn: 'I A Ⅱ B C / 確率統計系 / データの分析',
        questions: [
          {
            uid: '4',
            fid: '1a2bc',
            qid: '5',
            qn: 'データの分析はいぷりプレビュー5番',
            quid: '04_03_05',
            hn: 'I A Ⅱ B C / 確率統計系 / データの分析 / 偏差値・共分散と平均値',
            vid: 'cxK8Gx02A9Tb',
            hid: '9iyjSEitLgLJ',
            kuids: [ '04_03_01' ],
            euids: [ '04_05_11_00', '04_06_14_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '4',
            fid: '1a2bc',
            qid: '6',
            qn: 'データの分析はいぷりプレビュー6番',
            quid: '04_03_06',
            hn: 'I A Ⅱ B C / 確率統計系 / データの分析 / 偏差値・共分散と平均値',
            vid: '4Utl5gJ3M7RQ',
            hid: '7ZqUV5nYmDCm',
            kuids: [ '04_04_01', '04_04_02' ],
            euids: [ '04_07_16_00', '04_07_17_00', '04_08_18_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '相関係数',
        hn: 'I A Ⅱ B C / 確率統計系 / データの分析',
        questions: [
          {
            uid: '4',
            fid: '1a2bc',
            qid: '7',
            qn: 'データの分析はいぷりプレビュー7番',
            quid: '04_04_07',
            hn: 'I A Ⅱ B C / 確率統計系 / データの分析 / 相関係数',
            vid: 'e9Ojk7LrvVaL',
            hid: 'CdbKd_fq1T2H',
            kuids: [ '04_04_02' ],
            euids: [ '04_08_18_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '4',
            fid: '1a2bc',
            qid: '8',
            qn: 'データの分析はいぷりプレビュー8番',
            quid: '04_04_08',
            hn: 'I A Ⅱ B C / 確率統計系 / データの分析 / 相関係数',
            vid: 'BoMKo_LML9WZ',
            hid: 'kAHpZinHp6By',
            kuids: [ '04_04_02', '08_04_01', '02_05_04' ],
            euids: [ '04_08_18_00', '08_05_12_01', '02_10_33_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '15',
    un: '確率分布と統計的な推測',
    fid: '1a2bc',
    did: '4',
    hn: 'I A Ⅱ B C / 確率統計系',
    prints: [
      {
        pid: '1',
        pn: '確率変数',
        hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '1a2bc',
            qid: '1',
            qn: '確率分布と統計的な推測はいぷりプレビュー1番',
            quid: '15_01_01',
            hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測 / 確率変数',
            vid: 'xiTaaDCQwFcA',
            hid: '4yOV89ngnHvl',
            kuids: [ '15_01_01', '15_01_02', '05_03_06' ],
            euids: [ '15_01_01_00', '15_01_02_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '15',
            fid: '1a2bc',
            qid: '2',
            qn: '確率分布と統計的な推測はいぷりプレビュー2番',
            quid: '15_01_02',
            hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測 / 確率変数',
            vid: 'eMZkQnY9Eskc',
            hid: 'zLsvqiFsErmc',
            kuids: [ '15_01_02', '04_03_01', '15_01_03' ],
            euids: [ '15_01_03_00', '15_02_04_00', '15_02_05_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '15',
            fid: '1a2bc',
            qid: '3',
            qn: '確率分布と統計的な推測はいぷりプレビュー3番',
            quid: '15_01_03',
            hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測 / 確率変数',
            vid: 'DFSUkCtgEsMU',
            hid: '4ADFnqyFPUuu',
            kuids: [ '15_01_04', '15_01_05', '15_01_06' ],
            euids: [ '15_02_06_00', '15_03_07_00', '15_03_08_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '二項分布と正規分布',
        hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '1a2bc',
            qid: '4',
            qn: '確率分布と統計的な推測はいぷりプレビュー4番',
            quid: '15_02_04',
            hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測 / 二項分布と正規分布',
            vid: 'WCKlUQsO2HI_',
            hid: '1BQKTsUGpY6l',
            kuids: [ '15_01_08' ],
            euids: [ '15_05_12_00', '15_05_13_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '15',
            fid: '1a2bc',
            qid: '5',
            qn: '確率分布と統計的な推測はいぷりプレビュー5番',
            quid: '15_02_05',
            hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測 / 二項分布と正規分布',
            vid: 'bX4BYvtS4jEd',
            hid: 'lVFNyuvA9-pf',
            kuids: [ '15_01_07', '15_01_09_F', '15_01_09_S', '15_01_10' ],
            euids: [
              '15_04_09_00',
              '15_04_10_00',
              '15_04_11_00',
              '15_06_14_99',
              '15_06_15_99',
              '15_06_17_99'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '15',
            fid: '1a2bc',
            qid: '6',
            qn: '確率分布と統計的な推測はいぷりプレビュー6番',
            quid: '15_02_06',
            hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測 / 二項分布と正規分布',
            vid: 'dXnYL6cgYldF',
            hid: 'oFX7gAHdAo3p',
            kuids: [ '15_01_09_F', '15_01_09_S' ],
            euids: [ '15_06_16_99' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '標本平均とその分布',
        hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '1a2bc',
            qid: '7',
            qn: '確率分布と統計的な推測はいぷりプレビュー7番',
            quid: '15_03_07',
            hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測 / 標本平均とその分布',
            vid: '8L9boIJ8Q_ow',
            hid: 'dKOdfKN5NKQ0',
            kuids: [ '15_02_01', '15_02_02_F', '15_02_02_S', '15_02_03' ],
            euids: [
              '15_07_18_00',
              '15_07_19_00',
              '15_07_20_99',
              '15_08_21_99',
              '15_08_22_99'
            ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '推定',
        hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '1a2bc',
            qid: '8',
            qn: '確率分布と統計的な推測はいぷりプレビュー8番',
            quid: '15_04_08',
            hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測 / 推定',
            vid: 'Yq_pBV0z1Rzq',
            hid: 'hH-3fAdvyq9A',
            kuids: [ '15_02_04' ],
            euids: [ '15_09_23_00', '15_09_24_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '15',
            fid: '1a2bc',
            qid: '9',
            qn: '確率分布と統計的な推測はいぷりプレビュー9番',
            quid: '15_04_09',
            hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測 / 推定',
            vid: 'CkBlZZ11hK3T',
            hid: 'VjNwn3LTRvBA',
            kuids: [ '15_02_04' ],
            euids: [ '15_09_25_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '仮説検定',
        hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測',
        questions: [
          {
            uid: '15',
            fid: '1a2bc',
            qid: '10',
            qn: '確率分布と統計的な推測はいぷりプレビュー10番',
            quid: '15_05_10',
            hn: 'I A Ⅱ B C / 確率統計系 / 確率分布と統計的な推測 / 仮説検定',
            vid: 'WyTLhTMnJd75',
            hid: 'WyTLhTMnJd75',
            kuids: [ '15_02_05' ],
            euids: [ '15_10_26_00', '15_10_27_00', '15_10_28_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '7',
    un: '整数問題',
    fid: '1a2bc',
    did: '5',
    hn: 'I A Ⅱ B C / 整数問題',
    prints: [
      {
        pid: '1',
        pn: '整数の基本事項',
        hn: 'I A Ⅱ B C / 整数問題 / 整数問題',
        questions: [
          {
            uid: '7',
            fid: '1a2bc',
            qid: '1',
            qn: '整数の性質はいぷりプレビュー1番',
            quid: '07_01_01',
            hn: 'I A Ⅱ B C / 整数問題 / 整数問題 / 整数の基本事項',
            vid: 'ICE2EkRhm4ac',
            hid: 'LfmYnpUGFSjl',
            kuids: [ '07_01_01' ],
            euids: [ '07_01_02_01', '07_01_02_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '7',
            fid: '1a2bc',
            qid: '2',
            qn: '整数の性質はいぷりプレビュー2番',
            quid: '07_01_02',
            hn: 'I A Ⅱ B C / 整数問題 / 整数問題 / 整数の基本事項',
            vid: '4n0yTS1vaysT',
            hid: 'k9uqswqUfK20',
            kuids: [ '07_01_01', '07_01_02' ],
            euids: [ '07_01_01_01', '07_01_01_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '7',
            fid: '1a2bc',
            qid: '3',
            qn: '整数の性質はいぷりプレビュー3番',
            quid: '07_01_03',
            hn: 'I A Ⅱ B C / 整数問題 / 整数問題 / 整数の基本事項',
            vid: 'JuavWXRshmvc',
            hid: 'rcDaU_TAYzoo',
            kuids: [ '07_01_05' ],
            euids: [ '07_02_04_00', '07_02_05_00', '07_02_06_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '整数解方程式　その１',
        hn: 'I A Ⅱ B C / 整数問題 / 整数問題',
        questions: [
          {
            uid: '7',
            fid: '1a2bc',
            qid: '4',
            qn: '整数の性質はいぷりプレビュー4番',
            quid: '07_02_04',
            hn: 'I A Ⅱ B C / 整数問題 / 整数問題 / 整数解方程式　その１',
            vid: 'AuvNOPVnFuXI',
            hid: 'P1fY41379X1i',
            kuids: [ '07_02_02' ],
            euids: [
              '07_03_10_01',
              '07_03_10_02',
              '07_03_10_03',
              '07_03_11_00'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '7',
            fid: '1a2bc',
            qid: '5',
            qn: '整数の性質はいぷりプレビュー5番',
            quid: '07_02_05',
            hn: 'I A Ⅱ B C / 整数問題 / 整数問題 / 整数解方程式　その１',
            vid: '1wu1pUBLRI_U',
            hid: '6LjHm6TIOoms',
            kuids: [ '07_02_03' ],
            euids: [ '07_04_12_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '整数解方程式　その２',
        hn: 'I A Ⅱ B C / 整数問題 / 整数問題',
        questions: [
          {
            uid: '7',
            fid: '1a2bc',
            qid: '6',
            qn: '整数の性質はいぷりプレビュー6番',
            quid: '07_03_06',
            hn: 'I A Ⅱ B C / 整数問題 / 整数問題 / 整数解方程式　その２',
            vid: 'Luc3NY4is4V0',
            hid: 'DuoWXkw5MKjG',
            kuids: [ '07_02_01', '07_02_04' ],
            euids: [ '07_03_08_00', '07_03_09_00', '07_04_13_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '7',
            fid: '1a2bc',
            qid: '7',
            qn: '整数の性質はいぷりプレビュー7番',
            quid: '07_03_07',
            hn: 'I A Ⅱ B C / 整数問題 / 整数問題 / 整数解方程式　その２',
            vid: '6DAfhFjtA2jh',
            hid: '82z2U-Cfb_f3',
            kuids: [ '07_02_05' ],
            euids: [ '07_04_14_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '倍数証明',
        hn: 'I A Ⅱ B C / 整数問題 / 整数問題',
        questions: [
          {
            uid: '7',
            fid: '1a2bc',
            qid: '8',
            qn: '整数の性質はいぷりプレビュー8番',
            quid: '07_04_08',
            hn: 'I A Ⅱ B C / 整数問題 / 整数問題 / 倍数証明',
            vid: 'zmQ3XfObiGUu',
            hid: 'TXKyo5B9VCTS',
            kuids: [ '07_03_01', '07_01_06' ],
            euids: [ '07_05_16_00', '07_06_17_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '7',
            fid: '1a2bc',
            qid: '9',
            qn: '整数の性質はいぷりプレビュー9番',
            quid: '07_04_09',
            hn: 'I A Ⅱ B C / 整数問題 / 整数問題 / 倍数証明',
            vid: 'X6w6CnRT7k7O',
            hid: 'U8AA9HuAiMw9',
            kuids: [ '07_03_01', '07_01_06', '14_08_03' ],
            euids: [ '07_05_16_00', '07_06_17_00', '14_08_23_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '整数の応用題',
        hn: 'I A Ⅱ B C / 整数問題 / 整数問題',
        questions: [
          {
            uid: '7',
            fid: '1a2bc',
            qid: '10',
            qn: '整数の性質はいぷりプレビュー10番',
            quid: '07_05_10',
            hn: 'I A Ⅱ B C / 整数問題 / 整数問題 / 整数の応用題',
            vid: 'TYP7bkUSRqEq',
            hid: 'D8Dpi9qeYXVJ',
            kuids: [ '07_01_01', '01_06_05' ],
            euids: [ '07_01_02_01', '07_01_02_02', '01_09_30_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '7',
            fid: '1a2bc',
            qid: '11',
            qn: '整数の性質はいぷりプレビュー11番',
            quid: '07_05_11',
            hn: 'I A Ⅱ B C / 整数問題 / 整数問題 / 整数の応用題',
            vid: 'Jl4YyB4ne0tp',
            hid: 'OcfJiTN66lCW',
            kuids: [ '07_03_01', '08_01_01' ],
            euids: [ '07_05_16_00', '08_02_05_01', '08_02_05_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '7',
            fid: '1a2bc',
            qid: '12',
            qn: '整数の性質はいぷりプレビュー12番',
            quid: '07_05_12',
            hn: 'I A Ⅱ B C / 整数問題 / 整数問題 / 整数の応用題',
            vid: 'lbctxJdTEpNA',
            hid: 'A9b1RYyI78iX',
            kuids: [ '07_01_06' ],
            euids: [ '07_02_07_01', '07_02_07_02' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '18',
    un: '極限',
    fid: '3',
    did: '6',
    hn: 'Ⅲ / 数学Ⅲ',
    prints: [
      {
        pid: '1',
        pn: '一般項の極限　その１',
        hn: 'Ⅲ / 数学Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '1',
            qn: '極限はいぷりプレビュー1番',
            quid: '18_01_01',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 一般項の極限　その１',
            vid: 'QIxQMbTxGIvy',
            hid: 'lOIOrnaU6FW4',
            kuids: [ '18_01_02', '18_01_03' ],
            euids: [ '18_01_01_04', '18_01_01_05' ],
            huids: [],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '2',
            qn: '極限はいぷりプレビュー2番',
            quid: '18_01_02',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 一般項の極限　その１',
            vid: 'ahGKgO5tz386',
            hid: 'Lt295Hbj6dQ3',
            kuids: [ '18_01_05', '18_02_01' ],
            euids: [ '18_02_02_03', '18_02_02_04', '18_05_10_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '一般項の極限　その２',
        hn: 'Ⅲ / 数学Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '3',
            qn: '極限はいぷりプレビュー3番',
            quid: '18_02_03',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 一般項の極限　その２',
            vid: 'PUgAfuCd_iyF',
            hid: 'GSulyY6rya4u',
            kuids: [ '18_01_08' ],
            euids: [ '18_03_05_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '4',
            qn: '極限はいぷりプレビュー4番',
            quid: '18_02_04',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 一般項の極限　その２',
            vid: 'D8HIlhwQh5pz',
            hid: 'ydVOtn__MdN8',
            kuids: [ '18_01_07' ],
            euids: [ '18_03_04_01', '18_03_04_02', '18_03_04_03' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '無限級数　その１',
        hn: 'Ⅲ / 数学Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '5',
            qn: '極限はいぷりプレビュー5番',
            quid: '18_03_05',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 無限級数　その１',
            vid: 'UecxW-0dL3vB',
            hid: 'g37BKOyQpEvZ',
            kuids: [ '18_01_09', '18_01_13', '18_01_10' ],
            euids: [
              '18_04_06_01',
              '18_04_06_03',
              '18_04_07_02',
              '18_04_07_03'
            ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '無限級数　その２',
        hn: 'Ⅲ / 数学Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '6',
            qn: '極限はいぷりプレビュー6番',
            quid: '18_04_06',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 無限級数　その２',
            vid: 'WOoUHko4ZOch',
            hid: 'hj0RShwsN_VF',
            kuids: [ '18_01_10' ],
            euids: [ '18_04_07_02', '18_04_09_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '7',
            qn: '極限はいぷりプレビュー7番',
            quid: '18_04_07',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 無限級数　その２',
            vid: '9-XeSo07eM7i',
            hid: 'Q5qwHiDZYzyM',
            kuids: [ '18_01_11', '18_01_12' ],
            euids: [],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '分数＆無理関数の極限',
        hn: 'Ⅲ / 数学Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '8',
            qn: '極限はいぷりプレビュー8番',
            quid: '18_05_08',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 分数＆無理関数の極限',
            vid: 'WaMLram2N1WP',
            hid: 'GKuGXvbyc8D9',
            kuids: [ '18_02_05', '18_02_06' ],
            euids: [ '18_07_19_02', '18_07_19_04' ],
            huids: [],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '9',
            qn: '極限はいぷりプレビュー9番',
            quid: '18_05_09',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 分数＆無理関数の極限',
            vid: '14u3bS6f-IIs',
            hid: 'lQe-R3qb2or5',
            kuids: [ '18_02_05', '18_02_06' ],
            euids: [ '18_07_20_02', '18_07_20_03' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '三角関数の極限',
        hn: 'Ⅲ / 数学Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '10',
            qn: '極限はいぷりプレビュー10番',
            quid: '18_06_10',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 三角関数の極限',
            vid: 'LeoR7wvkwAZb',
            hid: 'Rb75Q3rtKzrx',
            kuids: [ '18_02_07', '18_02_08', '18_02_09' ],
            euids: [
              '18_08_21_02',
              '18_08_21_03',
              '18_08_21_04',
              '18_08_21_05'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '11',
            qn: '極限はいぷりプレビュー11番',
            quid: '18_06_11',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 三角関数の極限',
            vid: 'ptte_NiZHSsZ',
            hid: '93MqqRVY0EoS',
            kuids: [ '18_02_07', '18_02_08', '18_02_09' ],
            euids: [ '18_08_22_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '三角＆指数対数関数の極限',
        hn: 'Ⅲ / 数学Ⅲ / 極限',
        questions: [
          {
            uid: '18',
            fid: '3',
            qid: '12',
            qn: '極限はいぷりプレビュー12番',
            quid: '18_07_12',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 三角＆指数対数関数の極限',
            vid: 'TOMp6DOtOtSL',
            hid: '3df-Y-KDYWGq',
            kuids: [ '18_02_07', '18_02_08', '18_02_09', '18_01_10' ],
            euids: [ '18_08_23_00', '18_04_09_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            qid: '13',
            qn: '極限はいぷりプレビュー13番',
            quid: '18_07_13',
            hn: 'Ⅲ / 数学Ⅲ / 極限 / 三角＆指数対数関数の極限',
            vid: 'EnftGCczT97V',
            hid: '4BxmlTHJ76pQ',
            kuids: [ '18_02_07', '18_02_10' ],
            euids: [ '18_08_24_03', '18_08_24_04', '18_08_24_05' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '19',
    un: '微分',
    fid: '3',
    did: '6',
    hn: 'Ⅲ / 数学Ⅲ',
    prints: [
      {
        pid: '1',
        pn: '導関数',
        hn: 'Ⅲ / 数学Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '1',
            qn: '数・微分はいぷりプレビュー1番',
            quid: '19_01_01',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 導関数',
            vid: 'sJggnUrfMETd',
            hid: 'qjwI0F2sPX34',
            kuids: [ '19_01_02', '19_01_03' ],
            euids: [ '19_01_01_15', '19_02_02_02', '19_02_02_04' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '2',
            qn: '数・微分はいぷりプレビュー2番',
            quid: '19_01_02',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 導関数',
            vid: 'WMjcKjxo7ono',
            hid: 'nnaxGh2eMHon',
            kuids: [ '19_01_02', '19_01_03' ],
            euids: [ '19_02_02_03' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '3',
            qn: '数・微分はいぷりプレビュー3番',
            quid: '19_01_03',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 導関数',
            vid: 'kK3uSRJeFWXn',
            hid: 'CvueYtnkxAne',
            kuids: [ '19_01_04' ],
            euids: [ '19_02_03_03' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '接線　その１',
        hn: 'Ⅲ / 数学Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '4',
            qn: '数・微分はいぷりプレビュー4番',
            quid: '19_02_04',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 接線　その１',
            vid: '5MXUEsRkGrt5',
            hid: 'Em9OAJ9umI-o',
            kuids: [ '19_02_02' ],
            euids: [ '19_03_05_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '5',
            qn: '数・微分はいぷりプレビュー5番',
            quid: '19_02_05',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 接線　その１',
            vid: 'pzLCD0n_GuUx',
            hid: 'TRntKTbl1VQW',
            kuids: [ '19_02_01' ],
            euids: [ '19_03_04_03' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '6',
            qn: '数・微分はいぷりプレビュー6番',
            quid: '19_02_06',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 接線　その１',
            vid: '1OpSWqBKwhhv',
            hid: 'TTxronj85djw',
            kuids: [ '19_02_01' ],
            euids: [ '19_03_04_04' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '接線　その２',
        hn: 'Ⅲ / 数学Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '7',
            qn: '数・微分はいぷりプレビュー7番',
            quid: '19_03_07',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 接線　その２',
            vid: 'xPwA4GHpXkW7',
            hid: 'xcQ_EI1rIrYc',
            kuids: [ '19_02_02' ],
            euids: [ '19_03_06_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '8',
            qn: '数・微分はいぷりプレビュー8番',
            quid: '19_03_08',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 接線　その２',
            vid: 'F1ivV_iMQKR2',
            hid: 'OAEagTFNO9ap',
            kuids: [ '19_02_01', '18_01_10' ],
            euids: [ '19_03_04_01', '19_03_04_02', '18_04_09_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: 'グラフ　その１',
        hn: 'Ⅲ / 数学Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '9',
            qn: '数・微分はいぷりプレビュー9番',
            quid: '19_04_09',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / グラフ　その１',
            vid: 'b6_PMZueWuoL',
            hid: 'p7O5Am9Hxf4v',
            kuids: [
              '19_03_01',
              '19_03_02',
              '19_03_05',
              '19_03_03',
              '19_03_04'
            ],
            euids: [
              '19_04_07_03',
              '19_04_07_04',
              '19_05_08_01',
              '19_05_08_02'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '10',
            qn: '数・微分はいぷりプレビュー10番',
            quid: '19_04_10',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / グラフ　その１',
            vid: 'i5uBx3xxENt4',
            hid: 'IveTvIO7PsA9',
            kuids: [ '19_03_05', '19_03_03', '19_03_04' ],
            euids: [ '19_05_08_01', '19_05_08_02' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: 'グラフ　その２',
        hn: 'Ⅲ / 数学Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '11',
            qn: '数・微分はいぷりプレビュー11番',
            quid: '19_05_11',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / グラフ　その２',
            vid: 'PH-TG242SAA6',
            hid: 'WFitKzArIonZ',
            kuids: [ '19_03_01' ],
            euids: [ '19_06_10_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '12',
            qn: '数・微分はいぷりプレビュー12番',
            quid: '19_05_12',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / グラフ　その２',
            vid: 'G_aGdjzAmhNh',
            hid: 'X88VeOU7MAET',
            kuids: [ '19_03_01' ],
            euids: [ '19_06_10_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '13',
            qn: '数・微分はいぷりプレビュー13番',
            quid: '19_05_13',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / グラフ　その２',
            vid: 'r-om1CCU4Wa8',
            hid: 'PM1LHjUvGH37',
            kuids: [ '19_03_05', '19_03_03', '19_03_04' ],
            euids: [ '19_05_08_01', '19_05_08_02' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '最大・最小',
        hn: 'Ⅲ / 数学Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '14',
            qn: '数・微分はいぷりプレビュー14番',
            quid: '19_06_14',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 最大・最小',
            vid: 'ypl8_b0fiKH2',
            hid: 'j4w1NXZe3p1f',
            kuids: [ '19_04_01' ],
            euids: [ '19_07_12_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '15',
            qn: '数・微分はいぷりプレビュー15番',
            quid: '19_06_15',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 最大・最小',
            vid: '6P3ChSOLO_Dd',
            hid: 'rcUCvqaYYAt_',
            kuids: [ '19_04_01' ],
            euids: [ '19_07_11_01', '19_07_11_02', '19_07_11_03' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '方程式への応用',
        hn: 'Ⅲ / 数学Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '16',
            qn: '数・微分はいぷりプレビュー16番',
            quid: '19_07_16',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 方程式への応用',
            vid: 'OmRPcWGmkP3o',
            hid: 'GB1EH5tiUNFJ',
            kuids: [ '19_05_01' ],
            euids: [ '19_08_14_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '17',
            qn: '数・微分はいぷりプレビュー17番',
            quid: '19_07_17',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 方程式への応用',
            vid: 'vKjAgCOtnGsy',
            hid: 'AzT9bhVsYTEN',
            kuids: [ '19_05_01' ],
            euids: [ '19_08_15_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '18',
            qn: '数・微分はいぷりプレビュー18番',
            quid: '19_07_18',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 方程式への応用',
            vid: 'Gno8Fc8nc4FG',
            hid: 'B_DvQqs1bzeu',
            kuids: [ '19_04_01', '19_05_01', '19_05_02' ],
            euids: [ '19_07_13_00', '19_08_14_00', '19_08_16_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '不等式への応用',
        hn: 'Ⅲ / 数学Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '19',
            qn: '数・微分はいぷりプレビュー19番',
            quid: '19_08_19',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 不等式への応用',
            vid: '5kb49KjUcT-v',
            hid: 'qzMOxD7XdIrq',
            kuids: [ '19_05_02' ],
            euids: [ '19_08_16_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '20',
            qn: '数・微分はいぷりプレビュー20番',
            quid: '19_08_20',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 不等式への応用',
            vid: 'gP-oLOLW1Y7T',
            hid: 'vUSVvRgF8LZC',
            kuids: [ '19_05_02' ],
            euids: [ '19_08_16_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '微分可能と連続',
        hn: 'Ⅲ / 数学Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '21',
            qn: '数・微分はいぷりプレビュー21番',
            quid: '19_09_21',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 微分可能と連続',
            vid: 'rumV8zitdzLv',
            hid: 'Q-I8BbLb8CMy',
            kuids: [ '19_06_01' ],
            euids: [ '19_09_17_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '22',
            qn: '数・微分はいぷりプレビュー22番',
            quid: '19_09_22',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 微分可能と連続',
            vid: 'OFpvcIyKrnBe',
            hid: 'yg3I1qE291ov',
            kuids: [ '19_06_01' ],
            euids: [ '19_09_17_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '23',
            qn: '数・微分はいぷりプレビュー23番',
            quid: '19_09_23',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 微分可能と連続',
            vid: 'sVECcczaXdtO',
            hid: 'Uu8iT29Xv__R',
            kuids: [ '19_06_01' ],
            euids: [ '19_09_17_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '10',
        pn: '平均値の定理',
        hn: 'Ⅲ / 数学Ⅲ / 微分',
        questions: [
          {
            uid: '19',
            fid: '3',
            qid: '24',
            qn: '数・微分はいぷりプレビュー24番',
            quid: '19_10_24',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 平均値の定理',
            vid: '7fWG4fiz1S3Z',
            hid: 'Csorp7-spkms',
            kuids: [ '19_06_02' ],
            euids: [ '19_09_18_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            qid: '25',
            qn: '数・微分はいぷりプレビュー25番',
            quid: '19_10_25',
            hn: 'Ⅲ / 数学Ⅲ / 微分 / 平均値の定理',
            vid: 'EUiPLnVfqpLe',
            hid: 'tSXLLuS6xt5K',
            kuids: [ '19_06_02' ],
            euids: [ '19_09_18_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '20',
    un: '積分',
    fid: '3',
    did: '6',
    hn: 'Ⅲ / 数学Ⅲ',
    prints: [
      {
        pid: '1',
        pn: '積分計算',
        hn: 'Ⅲ / 数学Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '1',
            qn: '数・積分はいぷりプレビュー1番',
            quid: '20_01_01',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 積分計算',
            vid: 'khLRdWoyxk6j',
            hid: '1aPMbCL3TsdP',
            kuids: [ '20_01_02', '20_01_03', '20_01_06', '20_01_10' ],
            euids: [
              '20_01_01_01',
              '20_01_01_01',
              '20_03_02_02',
              '20_03_02_10'
            ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '2',
            qn: '数・積分はいぷりプレビュー2番',
            quid: '20_01_02',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 積分計算',
            vid: 'qOJsy8Qwad5U',
            hid: 'arwpr7_EOLYZ',
            kuids: [ '20_01_08', '20_01_10' ],
            euids: [ '20_03_02_08', '20_03_02_09', '20_03_02_10' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '3',
            qn: '数・積分はいぷりプレビュー3番',
            quid: '20_01_03',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 積分計算',
            vid: 'vFTqBPBaamdw',
            hid: 'WglgGQA-UjFX',
            kuids: [ '20_01_02', '20_01_03', '20_01_05', '20_01_08' ],
            euids: [ '20_01_01_06', '20_02_01_20', '20_03_02_08' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '定積分の応用　その１',
        hn: 'Ⅲ / 数学Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '4',
            qn: '数・積分はいぷりプレビュー4番',
            quid: '20_02_04',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 定積分の応用　その１',
            vid: '7XiCGq37RUfW',
            hid: '6Drqv1AIQzVD',
            kuids: [ '20_01_14' ],
            euids: [],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '5',
            qn: '数・積分はいぷりプレビュー5番',
            quid: '20_02_05',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 定積分の応用　その１',
            vid: 'ASvtqvJ88QE0',
            hid: 'p5nmKnQ7Zmfz',
            kuids: [ '20_01_13' ],
            euids: [],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '6',
            qn: '数・積分はいぷりプレビュー6番',
            quid: '20_02_06',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 定積分の応用　その１',
            vid: 'Wh9x-wuWUkZs',
            hid: 'HGViky97Br5u',
            kuids: [ '20_01_15' ],
            euids: [ '20_04_04_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '定積分の応用　その２',
        hn: 'Ⅲ / 数学Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '7',
            qn: '数・積分はいぷりプレビュー7番',
            quid: '20_03_07',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 定積分の応用　その２',
            vid: 'BHkieM_oidlJ',
            hid: '_MbQN8uBMK4C',
            kuids: [ '20_01_06', '20_01_12' ],
            euids: [ '20_03_02_04' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '8',
            qn: '数・積分はいぷりプレビュー8番',
            quid: '20_03_08',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 定積分の応用　その２',
            vid: 'sZqpWJIUnUyT',
            hid: 'LM6DAyMh3JQ0',
            kuids: [ '20_01_07' ],
            euids: [ '20_03_02_06' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '9',
            qn: '数・積分はいぷりプレビュー9番',
            quid: '20_03_09',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 定積分の応用　その２',
            vid: '4NXzkw32FJGM',
            hid: '4W0GUhBM24Gp',
            kuids: [ '20_01_13', '18_01_10' ],
            euids: [ '20_04_03_00', '18_04_09_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '定積分で表された関数',
        hn: 'Ⅲ / 数学Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '10',
            qn: '数・積分はいぷりプレビュー10番',
            quid: '20_04_10',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 定積分で表された関数',
            vid: '3Xw5KmUberpX',
            hid: 'w9GrhO5OlOnk',
            kuids: [ '20_02_01', '20_02_02', '20_02_03' ],
            euids: [ '20_04_05_01', '20_04_05_02', '20_04_07_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '11',
            qn: '数・積分はいぷりプレビュー11番',
            quid: '20_04_11',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 定積分で表された関数',
            vid: 'fLI4SxBK6fJt',
            hid: '52PHxv9PRHrA',
            kuids: [ '20_02_01', '20_02_03' ],
            euids: [ '20_04_07_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '12',
            qn: '数・積分はいぷりプレビュー12番',
            quid: '20_04_12',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 定積分で表された関数',
            vid: 'LO-YVrrs6bGk',
            hid: 'CtY4pf-EyVBx',
            kuids: [ '20_02_01', '20_02_02' ],
            euids: [ '20_04_05_01', '20_04_05_02' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '面積・区分求積法',
        hn: 'Ⅲ / 数学Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '13',
            qn: '数・積分はいぷりプレビュー13番',
            quid: '20_05_13',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 面積・区分求積法',
            vid: 'sfd1b33IgS3F',
            hid: 'T1mh8AlYrcDq',
            kuids: [ '20_03_01' ],
            euids: [ '20_05_09_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '14',
            qn: '数・積分はいぷりプレビュー14番',
            quid: '20_05_14',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 面積・区分求積法',
            vid: 'dCWGnkIweNAt',
            hid: 'kItEBekdjVbb',
            kuids: [ '20_03_02' ],
            euids: [ '20_06_14_01', '20_06_14_02' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '15',
            qn: '数・積分はいぷりプレビュー15番',
            quid: '20_05_15',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 面積・区分求積法',
            vid: 'GZUMYzkXjO9C',
            hid: 'CS0wTPuHxHVF',
            kuids: [ '20_03_02' ],
            euids: [ '20_06_14_01', '20_06_14_02' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '定積分と不等式',
        hn: 'Ⅲ / 数学Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '16',
            qn: '数・積分はいぷりプレビュー16番',
            quid: '20_06_16',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 定積分と不等式',
            vid: 'PhqEykj0xNwH',
            hid: 'XWCIhNboqhSe',
            kuids: [ '20_03_03' ],
            euids: [ '20_06_15_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '17',
            qn: '数・積分はいぷりプレビュー17番',
            quid: '20_06_17',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 定積分と不等式',
            vid: 'LHg_Gcz9SI6x',
            hid: 'K59mubSgPyKh',
            kuids: [ '20_03_03' ],
            euids: [ '20_06_16_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '体積（非回転体）',
        hn: 'Ⅲ / 数学Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '18',
            qn: '数・積分はいぷりプレビュー18番',
            quid: '20_07_18',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 体積（非回転体）',
            vid: 'V34cqizkWZtq',
            hid: 'KgkS0l_D8g4O',
            kuids: [ '20_04_01' ],
            euids: [ '20_07_17_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '19',
            qn: '数・積分はいぷりプレビュー19番',
            quid: '20_07_19',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 体積（非回転体）',
            vid: 'X19-p581kak6',
            hid: '5EKXfCIlrI_p',
            kuids: [ '20_04_01' ],
            euids: [ '20_07_17_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '20',
            qn: '数・積分はいぷりプレビュー20番',
            quid: '20_07_20',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 体積（非回転体）',
            vid: 'J4pV23gOsynZ',
            hid: 'oKspcmz7a5uX',
            kuids: [ '20_04_01' ],
            euids: [ '20_07_17_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '体積（回転体　基本）',
        hn: 'Ⅲ / 数学Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '21',
            qn: '数・積分はいぷりプレビュー21番',
            quid: '20_08_21',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 体積（回転体　基本）',
            vid: 'GX9G55qQS04m',
            hid: '6m0nCubuSuw0',
            kuids: [ '20_04_02' ],
            euids: [ '20_07_19_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '22',
            qn: '数・積分はいぷりプレビュー22番',
            quid: '20_08_22',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 体積（回転体　基本）',
            vid: '9m1OzFKs6QuN',
            hid: 'otYeZyykVC-Q',
            kuids: [ '20_04_02' ],
            euids: [ '20_07_19_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '体積（回転体　応用）',
        hn: 'Ⅲ / 数学Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '23',
            qn: '数・積分はいぷりプレビュー23番',
            quid: '20_09_23',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 体積（回転体　応用）',
            vid: 'azov6bgOsDJA',
            hid: 'WtsE8q_L4CNo',
            kuids: [ '20_04_02' ],
            euids: [ '20_07_18_00', '20_07_19_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '24',
            qn: '数・積分はいぷりプレビュー24番',
            quid: '20_09_24',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 体積（回転体　応用）',
            vid: 'Yy8j3jsCMv4E',
            hid: 'BXUyuHtp1_C5',
            kuids: [ '20_04_02' ],
            euids: [ '20_07_18_00', '20_07_19_00' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '25',
            qn: '数・積分はいぷりプレビュー25番',
            quid: '20_09_25',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 体積（回転体　応用）',
            vid: 'PkOjg1oW4K3G',
            hid: 'sWvTaiQZvUv9',
            kuids: [ '20_04_02' ],
            euids: [ '20_07_18_00', '20_07_19_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '10',
        pn: '曲線の長さ・物理量',
        hn: 'Ⅲ / 数学Ⅲ / 積分',
        questions: [
          {
            uid: '20',
            fid: '3',
            qid: '26',
            qn: '数・積分はいぷりプレビュー26番',
            quid: '20_10_26',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 曲線の長さ・物理量',
            vid: 'V3RIR6clUzaf',
            hid: 'lqi1sjjsFuUY',
            kuids: [ '20_05_01' ],
            euids: [ '20_08_21_01' ],
            huids: [],
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            qid: '27',
            qn: '数・積分はいぷりプレビュー27番',
            quid: '20_10_27',
            hn: 'Ⅲ / 数学Ⅲ / 積分 / 曲線の長さ・物理量',
            vid: 'Is0GvJqtxiy7',
            hid: 'IIU0oeNMMB2l',
            kuids: [ '20_05_01' ],
            euids: [ '20_08_21_02', '20_08_22_00' ],
            huids: [],
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  }
];