export default [
  {
    uid: '1',
    un: '数と式・集合と論理',
    fid: '1a',
    hn: 'I',
    prints: [
      {
        pid: '1',
        pn: '展開と因数分解',
        hn: 'I / 数と式・集合と論理',
        kougis: [
          {
            uid: '1',
            fid: '1',
            kid: '1',
            kn: '展開',
            kuid: '01_01_01',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'sFzqQHRgbVQG',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '2',
            kn: '因数分解',
            kuid: '01_01_02',
            hn: 'I / 数と式・集合と論理 / 展開と因数分解',
            vid: 'gV4htum4hB3g',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '実数',
        hn: 'I / 数と式・集合と論理',
        kougis: [
          {
            uid: '1',
            fid: '1',
            kid: '1',
            kn: '有理数・無理数',
            kuid: '01_02_01',
            hn: 'I / 数と式・集合と論理 / 実数',
            vid: '46NVXJkccw5E',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '2',
            kn: '√の計算',
            kuid: '01_02_02',
            hn: 'I / 数と式・集合と論理 / 実数',
            vid: 'rGjPnAljrG_w',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '3',
            kn: '２重根号をはずす',
            kuid: '01_02_03',
            hn: 'I / 数と式・集合と論理 / 実数',
            vid: 'qbYlXAqsL5Zz',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '4',
            kn: '整数部分・小数部分',
            kuid: '01_02_04',
            hn: 'I / 数と式・集合と論理 / 実数',
            vid: 'kTrGSbh4eHm7',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '5',
            kn: '対称式＜基本編＞',
            kuid: '01_02_05',
            hn: 'I / 数と式・集合と論理 / 実数',
            vid: '0rvB98vK33tr',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '6',
            kn: '対称式＜応用編＞',
            kuid: '01_02_06',
            hn: 'I / 数と式・集合と論理 / 実数',
            vid: 'IYVcTQTKRWDE',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '絶対値',
        hn: 'I / 数と式・集合と論理',
        kougis: [
          {
            uid: '1',
            fid: '1',
            kid: '1',
            kn: '基本',
            kuid: '01_03_01',
            hn: 'I / 数と式・集合と論理 / 絶対値',
            vid: '_PybL2hSDyRt',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '2',
            kn: '応用',
            kuid: '01_03_02',
            hn: 'I / 数と式・集合と論理 / 絶対値',
            vid: 'cr87AQMqlr6K',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '１次不等式',
        hn: 'I / 数と式・集合と論理',
        kougis: [
          {
            uid: '1',
            fid: '1',
            kid: '1',
            kn: '基本',
            kuid: '01_04_01',
            hn: 'I / 数と式・集合と論理 / １次不等式',
            vid: '-9VNXzkr-ntX',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '2',
            kn: '応用',
            kuid: '01_04_02',
            hn: 'I / 数と式・集合と論理 / １次不等式',
            vid: 'Q_INOZMI6kVF',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '3',
            kn: '絶対値つき',
            kuid: '01_04_03',
            hn: 'I / 数と式・集合と論理 / １次不等式',
            vid: 'K22Xb_l-B7kU',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '集合',
        hn: 'I / 数と式・集合と論理',
        kougis: [
          {
            uid: '1',
            fid: '1',
            kid: '1',
            kn: '集合',
            kuid: '01_05_01',
            hn: 'I / 数と式・集合と論理 / 集合',
            vid: 'z4UpehLgo3XL',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '論理',
        hn: 'I / 数と式・集合と論理',
        kougis: [
          {
            uid: '1',
            fid: '1',
            kid: '1',
            kn: '命題と真偽',
            kuid: '01_06_01',
            hn: 'I / 数と式・集合と論理 / 論理',
            vid: 'vZJg1CQo_cmp',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '2',
            kn: '否定',
            kuid: '01_06_02',
            hn: 'I / 数と式・集合と論理 / 論理',
            vid: 'oNfSyDS42iSF',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '3',
            kn: '逆・裏・対偶',
            kuid: '01_06_03',
            hn: 'I / 数と式・集合と論理 / 論理',
            vid: '4FsChNkLCwbn',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '4',
            kn: '必要条件・十分条件',
            kuid: '01_06_04',
            hn: 'I / 数と式・集合と論理 / 論理',
            vid: 'tazSk3OR1N-s',
            record: {}
          },
          {
            uid: '1',
            fid: '1',
            kid: '5',
            kn: '背理法',
            kuid: '01_06_05',
            hn: 'I / 数と式・集合と論理 / 論理',
            vid: 'yIgzPbpiKs0r',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '2',
    un: '２次関数',
    fid: '1a',
    hn: 'I',
    prints: [
      {
        pid: '1',
        pn: '２次関数のグラフ',
        hn: 'I / ２次関数',
        kougis: [
          {
            uid: '2',
            fid: '1',
            kid: '1',
            kn: '平行移動',
            kuid: '02_01_01',
            hn: 'I / ２次関数 / ２次関数のグラフ',
            vid: 'giCMlRgjxBxQ',
            record: {}
          },
          {
            uid: '2',
            fid: '1',
            kid: '2',
            kn: '対称移動',
            kuid: '02_01_02',
            hn: 'I / ２次関数 / ２次関数のグラフ',
            vid: 'n67TkniupkIN',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '２次関数の最大最小',
        hn: 'I / ２次関数',
        kougis: [
          {
            uid: '2',
            fid: '1',
            kid: '1',
            kn: '基本',
            kuid: '02_02_01',
            hn: 'I / ２次関数 / ２次関数の最大最小',
            vid: 'wUBcF5R7v_lV',
            record: {}
          },
          {
            uid: '2',
            fid: '1',
            kid: '2',
            kn: '条件つき（文字消去）',
            kuid: '02_02_02',
            hn: 'I / ２次関数 / ２次関数の最大最小',
            vid: 'Xwo6VUP9HMGM',
            record: {}
          },
          {
            uid: '2',
            fid: '1',
            kid: '3',
            kn: '条件つき（置き換え）',
            kuid: '02_02_03',
            hn: 'I / ２次関数 / ２次関数の最大最小',
            vid: '4x11FqtAapMR',
            record: {}
          },
          {
            uid: '2',
            fid: '1',
            kid: '4',
            kn: '文字場合分け（グラフが動く）',
            kuid: '02_02_04',
            hn: 'I / ２次関数 / ２次関数の最大最小',
            vid: 'PjWprvpMnxSP',
            record: {}
          },
          {
            uid: '2',
            fid: '1',
            kid: '5',
            kn: '文字場合分け（区間が動く）',
            kuid: '02_02_05',
            hn: 'I / ２次関数 / ２次関数の最大最小',
            vid: 'wcDds6KwcpK1',
            record: {}
          },
          {
            uid: '2',
            fid: '1',
            kid: '6',
            kn: '２変数無関係',
            kuid: '02_02_06',
            hn: 'I / ２次関数 / ２次関数の最大最小',
            vid: 'DwMkbJpKcLs0',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '２次関数の決定',
        hn: 'I / ２次関数',
        kougis: [
          {
            uid: '2',
            fid: '1',
            kid: '1',
            kn: '２次関数を作る',
            kuid: '02_03_01',
            hn: 'I / ２次関数 / ２次関数の決定',
            vid: 'MqvywzIc3vqY',
            record: {}
          },
          {
            uid: '2',
            fid: '1',
            kid: '2',
            kn: '符号決定',
            kuid: '02_03_02',
            hn: 'I / ２次関数 / ２次関数の決定',
            vid: 's3tq7P5SfVJi',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '２次方程式',
        hn: 'I / ２次関数',
        kougis: [
          {
            uid: '2',
            fid: '1',
            kid: '1',
            kn: '基本',
            kuid: '02_04_01',
            hn: 'I / ２次関数 / ２次方程式',
            vid: '7WiJyecKlpMb',
            record: {}
          },
          {
            uid: '2',
            fid: '1',
            kid: '2',
            kn: 'グラフとｘ軸との関係',
            kuid: '02_04_02',
            hn: 'I / ２次関数 / ２次方程式',
            vid: '7O6ezgiCQeCa',
            record: {}
          },
          {
            uid: '2',
            fid: '1',
            kid: '3',
            kn: '２次方程式の解の配置',
            kuid: '02_04_03',
            hn: 'I / ２次関数 / ２次方程式',
            vid: 'ZsR98ImEGOJU',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '２次不等式',
        hn: 'I / ２次関数',
        kougis: [
          {
            uid: '2',
            fid: '1',
            kid: '1',
            kn: '基本',
            kuid: '02_05_01',
            hn: 'I / ２次関数 / ２次不等式',
            vid: 'k3KcasS6PCgY',
            record: {}
          },
          {
            uid: '2',
            fid: '1',
            kid: '2',
            kn: '係数決定',
            kuid: '02_05_02',
            hn: 'I / ２次関数 / ２次不等式',
            vid: 'nRgu-c7b8FaF',
            record: {}
          },
          {
            uid: '2',
            fid: '1',
            kid: '3',
            kn: '整数値の個数',
            kuid: '02_05_03',
            hn: 'I / ２次関数 / ２次不等式',
            vid: 'TcpiwQutOgMS',
            record: {}
          },
          {
            uid: '2',
            fid: '1',
            kid: '4',
            kn: '絶対不等式',
            kuid: '02_05_04',
            hn: 'I / ２次関数 / ２次不等式',
            vid: 'tYtzR-T0t-8b',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '3',
    un: '図形と計量',
    fid: '1a',
    hn: 'I',
    prints: [
      {
        pid: '1',
        pn: '値',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: '２秒以内に',
            kuid: '03_01_01',
            hn: 'I / 図形と計量 / 値',
            vid: 'GuLSfZy2-qS6',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '方程式',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: '値の逆バージョン',
            kuid: '03_02_01',
            hn: 'I / 図形と計量 / 方程式',
            vid: 'fJ7b8PR14hL1',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '不等式',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: '単位円を書こう！',
            kuid: '03_03_01',
            hn: 'I / 図形と計量 / 不等式',
            vid: 'cTrseP9yJVkE',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '相互関係',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: '３つ',
            kuid: '03_04_01',
            hn: 'I / 図形と計量 / 相互関係',
            vid: 'kjJmN8xTQ-4I',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '４５°以下の三角比に直す',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: 'しっかりイメージ',
            kuid: '03_05_01',
            hn: 'I / 図形と計量 / ４５°以下の三角比に直す',
            vid: 'q5TQv1vOSReB',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '正弦定理',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: '基本事項',
            kuid: '03_06_01',
            hn: 'I / 図形と計量 / 正弦定理',
            vid: '46lVdruLzACr',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '余弦定理',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: '基本事項',
            kuid: '03_07_01',
            hn: 'I / 図形と計量 / 余弦定理',
            vid: 'dkXH0o_YHYn2',
            record: {}
          },
          {
            uid: '3',
            fid: '1',
            kid: '2',
            kn: '応用',
            kuid: '03_07_02',
            hn: 'I / 図形と計量 / 余弦定理',
            vid: 'saf3-R6uTwTG',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '三角形の面積',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: '３公式',
            kuid: '03_08_01',
            hn: 'I / 図形と計量 / 三角形の面積',
            vid: 'S9AuumZSOgPr',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '9',
        pn: '入試５頻出　その１',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: '三角形のすべてを求める問題',
            kuid: '03_09_01',
            hn: 'I / 図形と計量 / 入試５頻出　その１',
            vid: 'j5KstuJKjRjH',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '10',
        pn: '入試５頻出　その２',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: '三角形の形状決定問題',
            kuid: '03_10_01',
            hn: 'I / 図形と計量 / 入試５頻出　その２',
            vid: 'Ku5jyt-2H8nc',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '11',
        pn: '入試５頻出　その３',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: '三角形の角の二等分線問題',
            kuid: '03_11_01',
            hn: 'I / 図形と計量 / 入試５頻出　その３',
            vid: 'KY8vMa1spbaW',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '12',
        pn: '入試５頻出　その４',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: '円に内接する四角形問題',
            kuid: '03_12_01',
            hn: 'I / 図形と計量 / 入試５頻出　その４',
            vid: 'R57iG0Sziw66',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '13',
        pn: '入試５頻出　その５',
        hn: 'I / 図形と計量',
        kougis: [
          {
            uid: '3',
            fid: '1',
            kid: '1',
            kn: '立体図形の問題',
            kuid: '03_13_01',
            hn: 'I / 図形と計量 / 入試５頻出　その５',
            vid: '0KmypWFeHC43',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '4',
    un: 'データの分析',
    fid: '1a',
    hn: 'I',
    prints: [
      {
        pid: '1',
        pn: 'データの代表値',
        hn: 'I / データの分析',
        kougis: [
          {
            uid: '4',
            fid: '1',
            kid: '1',
            kn: 'データの整理',
            kuid: '04_01_01',
            hn: 'I / データの分析 / データの代表値',
            vid: 'XbfNd2ig6k5B',
            record: {}
          },
          {
            uid: '4',
            fid: '1',
            kid: '2',
            kn: '平均値',
            kuid: '04_01_02',
            hn: 'I / データの分析 / データの代表値',
            vid: 'x6Mms_z4yW1R',
            record: {}
          },
          {
            uid: '4',
            fid: '1',
            kid: '3',
            kn: '中央値・最頻値',
            kuid: '04_01_03',
            hn: 'I / データの分析 / データの代表値',
            vid: 'VuVjY68CAZIk',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: 'データの散らばり',
        hn: 'I / データの分析',
        kougis: [
          {
            uid: '4',
            fid: '1',
            kid: '1',
            kn: '四分位数',
            kuid: '04_02_01',
            hn: 'I / データの分析 / データの散らばり',
            vid: 'u3XKRbYiwyTc',
            record: {}
          },
          {
            uid: '4',
            fid: '1',
            kid: '2',
            kn: '箱ひげ図',
            kuid: '04_02_02',
            hn: 'I / データの分析 / データの散らばり',
            vid: 'pO9Vi5v6la2a',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '分散と標準偏差',
        hn: 'I / データの分析',
        kougis: [
          {
            uid: '4',
            fid: '1',
            kid: '1',
            kn: '基本',
            kuid: '04_03_01',
            hn: 'I / データの分析 / 分散と標準偏差',
            vid: 'E1xjEPXYLvYj',
            record: {}
          },
          {
            uid: '4',
            fid: '1',
            kid: '2',
            kn: '平均値と分散',
            kuid: '04_03_02',
            hn: 'I / データの分析 / 分散と標準偏差',
            vid: 'MCftLCzj4D3F',
            record: {}
          },
          {
            uid: '4',
            fid: '1',
            kid: '3',
            kn: '変量変換',
            kuid: '04_03_03',
            hn: 'I / データの分析 / 分散と標準偏差',
            vid: 'Rjx3vVmJq5TU',
            record: {}
          },
          {
            uid: '4',
            fid: '1',
            kid: '4',
            kn: '度数分布表と分散・標準偏差',
            kuid: '04_03_04',
            hn: 'I / データの分析 / 分散と標準偏差',
            vid: 'P0xsgPZ6vIsC',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: 'データの相関',
        hn: 'I / データの分析',
        kougis: [
          {
            uid: '4',
            fid: '1',
            kid: '1',
            kn: '散布図・相関表',
            kuid: '04_04_01',
            hn: 'I / データの分析 / データの相関',
            vid: 'qLqmsH-ZQpGp',
            record: {}
          },
          {
            uid: '4',
            fid: '1',
            kid: '2',
            kn: '相関係数',
            kuid: '04_04_02',
            hn: 'I / データの分析 / データの相関',
            vid: 'kwOyorbe4X7h',
            record: {}
          },
          {
            uid: '4',
            fid: '1',
            kid: '3',
            kn: 'ー１≦ｒ≦１となることの証明',
            kuid: '04_04_03',
            hn: 'I / データの分析 / データの相関',
            vid: '_48GVYPwbPCV',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '5',
    un: '場合の数と確率',
    fid: '1a',
    hn: 'A',
    prints: [
      {
        pid: '1',
        pn: '順列',
        hn: 'A / 場合の数と確率',
        kougis: [
          {
            uid: '5',
            fid: 'a',
            kid: '1',
            kn: '順列の基本',
            kuid: '05_01_01',
            hn: 'A / 場合の数と確率 / 順列',
            vid: 'FOB_8lEXYQAS',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '2',
            kn: '条件つきの順列',
            kuid: '05_01_02',
            hn: 'A / 場合の数と確率 / 順列',
            vid: 'MHagU9qB_r8J',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '3',
            kn: '同じものを含む順列',
            kuid: '05_01_03',
            hn: 'A / 場合の数と確率 / 順列',
            vid: 'HQyx0gATw3sv',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '4',
            kn: '円順列・数珠順列',
            kuid: '05_01_04',
            hn: 'A / 場合の数と確率 / 順列',
            vid: 'BOqycC2hus7K',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '5',
            kn: '重複順列',
            kuid: '05_01_05',
            hn: 'A / 場合の数と確率 / 順列',
            vid: 'PUO2EBa-0-2K',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '組み合わせ',
        hn: 'A / 場合の数と確率',
        kougis: [
          {
            uid: '5',
            fid: 'a',
            kid: '1',
            kn: '組み合わせの基本',
            kuid: '05_02_01',
            hn: 'A / 場合の数と確率 / 組み合わせ',
            vid: 'wSSBavLq7aU3',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '2',
            kn: '重複組み合わせ',
            kuid: '05_02_02',
            hn: 'A / 場合の数と確率 / 組み合わせ',
            vid: 'a4w9ZyDXSp48',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '3',
            kn: '（頻出）道路問題',
            kuid: '05_02_03',
            hn: 'A / 場合の数と確率 / 組み合わせ',
            vid: 'ivft2sGENPT6',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '4',
            kn: '（頻出）対角線問題',
            kuid: '05_02_04',
            hn: 'A / 場合の数と確率 / 組み合わせ',
            vid: 'v9GdWTBaKEYb',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '5',
            kn: '（頻出）組分け問題',
            kuid: '05_02_05',
            hn: 'A / 場合の数と確率 / 組み合わせ',
            vid: 'LyOgwBl6VbSo',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '確率',
        hn: 'A / 場合の数と確率',
        kougis: [
          {
            uid: '5',
            fid: 'a',
            kid: '1',
            kn: '確率の基本',
            kuid: '05_03_01',
            hn: 'A / 場合の数と確率 / 確率',
            vid: 'FCNXIM3Un-9d',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '2',
            kn: '集合との融合',
            kuid: '05_03_02',
            hn: 'A / 場合の数と確率 / 確率',
            vid: 'lGefS7xF5TlB',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '3',
            kn: '独立試行',
            kuid: '05_03_03',
            hn: 'A / 場合の数と確率 / 確率',
            vid: 'ZKrf3ZltLaWF',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '4',
            kn: '反復試行',
            kuid: '05_03_04',
            hn: 'A / 場合の数と確率 / 確率',
            vid: 'rKkV3uCLRAGK',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '5',
            kn: '条件つき確率',
            kuid: '05_03_05',
            hn: 'A / 場合の数と確率 / 確率',
            vid: 'XJP4Lrpyry8T',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '6',
            kn: '期待値',
            kuid: '05_03_06',
            hn: 'A / 場合の数と確率 / 確率',
            vid: '4hdaDNrZuVXq',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '21',
            kn: '最大確率（数列との融合）',
            kuid: '05_03_21',
            hn: 'A / 場合の数と確率 / 確率',
            vid: 'wQHb53f4vay5',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '22',
            kn: '確率と漸化式（数列との融合）',
            kuid: '05_03_22',
            hn: 'A / 場合の数と確率 / 確率',
            vid: '1lMHLOl58Ly0',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '11',
            kn: '（頻出）さいころ',
            kuid: '05_03_11',
            hn: 'A / 場合の数と確率 / 確率',
            vid: 'zIQvC-K5atqa',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '12',
            kn: '（頻出）じゃんけん',
            kuid: '05_03_12',
            hn: 'A / 場合の数と確率 / 確率',
            vid: 'Dk1WDsMLs244',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '13',
            kn: '（頻出）くじ',
            kuid: '05_03_13',
            hn: 'A / 場合の数と確率 / 確率',
            vid: 'ulMBkuZGrdIk',
            record: {}
          },
          {
            uid: '5',
            fid: 'a',
            kid: '14',
            kn: '（頻出）点の移動',
            kuid: '05_03_14',
            hn: 'A / 場合の数と確率 / 確率',
            vid: '0oq_xsDsqbAB',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '6',
    un: '図形の性質',
    fid: '1a',
    hn: 'A',
    prints: [
      {
        pid: '1',
        pn: '＜平面図形＞　三角形',
        hn: 'A / 図形の性質',
        kougis: [
          {
            uid: '6',
            fid: 'a',
            kid: '1',
            kn: '三角形の成立条件',
            kuid: '06_01_01',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: '__wKMMO9WHRJ',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '2',
            kn: '平行線と線分の比',
            kuid: '06_01_02',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: '7NyfG21-UyVO',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '3',
            kn: '角の二等分線と線分の比',
            kuid: '06_01_03',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: 'nOEbaX_FEOC6',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '4',
            kn: '面積比',
            kuid: '06_01_04',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: 'BiZsckOhvNX1',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '5',
            kn: '中線定理',
            kuid: '06_01_05',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: 'ODt46aAFkgEB',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '6',
            kn: 'メネラウスの定理',
            kuid: '06_01_06',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: 'cwmtQIW_nqrD',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '7',
            kn: 'チェバの定理',
            kuid: '06_01_07',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: 'NGXQk8WCdYGY',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '8',
            kn: 'メネラウスの定理の逆',
            kuid: '06_01_08',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: 'E_PgEczz5muC',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '9',
            kn: 'チェバの定理の逆',
            kuid: '06_01_09',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: '_V06Vjt8cuv0',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '10',
            kn: '重心',
            kuid: '06_01_10',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: 'ddTa_bswf2c5',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '11',
            kn: '外心',
            kuid: '06_01_11',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: 'ePJuunE-0JF4',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '12',
            kn: '垂心',
            kuid: '06_01_12',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: 'jyUAx6KvYa5x',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '13',
            kn: '内心',
            kuid: '06_01_13',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: '6C4A3UXgqLEZ',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '14',
            kn: '傍心',
            kuid: '06_01_14',
            hn: 'A / 図形の性質 / ＜平面図形＞　三角形',
            vid: 'frpMjbxK_qW2',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '＜平面図形＞　円',
        hn: 'A / 図形の性質',
        kougis: [
          {
            uid: '6',
            fid: 'a',
            kid: '1',
            kn: '円周角の定理',
            kuid: '06_02_01',
            hn: 'A / 図形の性質 / ＜平面図形＞　円',
            vid: 'eLT8sLc-1OGG',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '2',
            kn: '円に内接する四角形',
            kuid: '06_02_02',
            hn: 'A / 図形の性質 / ＜平面図形＞　円',
            vid: 'HUOWutakbblX',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '3',
            kn: '接弦定理',
            kuid: '06_02_03',
            hn: 'A / 図形の性質 / ＜平面図形＞　円',
            vid: 'TE65uZfq3EMM',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '4',
            kn: '方べきの定理',
            kuid: '06_02_04',
            hn: 'A / 図形の性質 / ＜平面図形＞　円',
            vid: '2JgThOtNwDAA',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '5',
            kn: '２円の関係',
            kuid: '06_02_05',
            hn: 'A / 図形の性質 / ＜平面図形＞　円',
            vid: 'Pr2ilncAQvow',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '＜空間図形＞　直線と平面',
        hn: 'A / 図形の性質',
        kougis: [
          {
            uid: '6',
            fid: 'a',
            kid: '1',
            kn: '位置関係',
            kuid: '06_03_01',
            hn: 'A / 図形の性質 / ＜空間図形＞　直線と平面',
            vid: 'ZDoS5Fk85jlk',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '2',
            kn: '三垂線の定理',
            kuid: '06_03_02',
            hn: 'A / 図形の性質 / ＜空間図形＞　直線と平面',
            vid: 'Dhz6yjLseDDt',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '＜空間図形＞　多面体',
        hn: 'A / 図形の性質',
        kougis: [
          {
            uid: '6',
            fid: 'a',
            kid: '1',
            kn: '正多面体',
            kuid: '06_04_01',
            hn: 'A / 図形の性質 / ＜空間図形＞　多面体',
            vid: 'MVITrh8oNwKy',
            record: {}
          },
          {
            uid: '6',
            fid: 'a',
            kid: '2',
            kn: 'オイラーの多面体定理',
            kuid: '06_04_02',
            hn: 'A / 図形の性質 / ＜空間図形＞　多面体',
            vid: 'TxFaEh97otHH',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '7',
    un: '数学と人間の活動',
    fid: '1a',
    hn: 'A',
    prints: [
      {
        pid: '1',
        pn: '基本事項',
        hn: 'A / 数学と人間の活動',
        kougis: [
          {
            uid: '7',
            fid: 'a',
            kid: '1',
            kn: '約数・倍数・素因数',
            kuid: '07_01_01',
            hn: 'A / 数学と人間の活動 / 基本事項',
            vid: 'hblwE5SLZau5',
            record: {}
          },
          {
            uid: '7',
            fid: 'a',
            kid: '2',
            kn: '最大公約数と最小公倍数',
            kuid: '07_01_02',
            hn: 'A / 数学と人間の活動 / 基本事項',
            vid: 'rSv6q2GXQzW8',
            record: {}
          },
          {
            uid: '7',
            fid: 'a',
            kid: '3',
            kn: 'ユークリッドの互除法（使い方）',
            kuid: '07_01_03',
            hn: 'A / 数学と人間の活動 / 基本事項',
            vid: 'G_gIW5NDM5rg',
            record: {}
          },
          {
            uid: '7',
            fid: 'a',
            kid: '4',
            kn: 'ユークリッドの互除法（証明）',
            kuid: '07_01_04',
            hn: 'A / 数学と人間の活動 / 基本事項',
            vid: 'HkR8fWwTq25u',
            record: {}
          },
          {
            uid: '7',
            fid: 'a',
            kid: '5',
            kn: 'ｎ進法',
            kuid: '07_01_05',
            hn: 'A / 数学と人間の活動 / 基本事項',
            vid: 'cgNeKUtyaMuZ',
            record: {}
          },
          {
            uid: '7',
            fid: 'a',
            kid: '6',
            kn: '合同式',
            kuid: '07_01_06',
            hn: 'A / 数学と人間の活動 / 基本事項',
            vid: '8YFrBpKGe5Ri',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '整数解方程式',
        hn: 'A / 数学と人間の活動',
        kougis: [
          {
            uid: '7',
            fid: 'a',
            kid: '1',
            kn: '基本',
            kuid: '07_02_01',
            hn: 'A / 数学と人間の活動 / 整数解方程式',
            vid: 'qn2bzhCjansT',
            record: {}
          },
          {
            uid: '7',
            fid: 'a',
            kid: '2',
            kn: '２元１次不定方程式',
            kuid: '07_02_02',
            hn: 'A / 数学と人間の活動 / 整数解方程式',
            vid: 'h0RzdwfOpxei',
            record: {}
          },
          {
            uid: '7',
            fid: 'a',
            kid: '3',
            kn: '２元２次不定方程式',
            kuid: '07_02_03',
            hn: 'A / 数学と人間の活動 / 整数解方程式',
            vid: 'HiW8P81VHbZb',
            record: {}
          },
          {
            uid: '7',
            fid: 'a',
            kid: '4',
            kn: '絞り込み（実数条件）',
            kuid: '07_02_04',
            hn: 'A / 数学と人間の活動 / 整数解方程式',
            vid: 'ihQzpLnAFG5B',
            record: {}
          },
          {
            uid: '7',
            fid: 'a',
            kid: '5',
            kn: '絞り込み（大小関係）',
            kuid: '07_02_05',
            hn: 'A / 数学と人間の活動 / 整数解方程式',
            vid: 'Frc5SbBADrJk',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '倍数証明',
        hn: 'A / 数学と人間の活動',
        kougis: [
          {
            uid: '7',
            fid: 'a',
            kid: '1',
            kn: '剰余類',
            kuid: '07_03_01',
            hn: 'A / 数学と人間の活動 / 倍数証明',
            vid: 'KSIS_dUR1PeC',
            record: {}
          },
          {
            uid: '7',
            fid: 'a',
            kid: '2',
            kn: '２大証明法（背理法）',
            kuid: '07_03_02',
            hn: 'A / 数学と人間の活動 / 倍数証明',
            vid: '6lZODWeRpfLP',
            record: {}
          },
          {
            uid: '7',
            fid: 'a',
            kid: '3',
            kn: '２大証明法（数学的帰納法）',
            kuid: '07_03_03',
            hn: 'A / 数学と人間の活動 / 倍数証明',
            vid: '1e7l9V9FLPcB',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '8',
    un: '式と証明',
    fid: '2bc',
    hn: 'Ⅱ',
    prints: [
      {
        pid: '0',
        pn: '式の扱い',
        hn: 'Ⅱ / 式と証明',
        kougis: [
          {
            uid: '8',
            fid: '2',
            kid: '1',
            kn: '整式・分数式',
            kuid: '08_00_01',
            hn: 'Ⅱ / 式と証明 / 式の扱い',
            vid: 'c0gA2Tgka1i3',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '1',
        pn: '二項定理',
        hn: 'Ⅱ / 式と証明',
        kougis: [
          {
            uid: '8',
            fid: '2',
            kid: '1',
            kn: '二項定理・多項定理',
            kuid: '08_01_01',
            hn: 'Ⅱ / 式と証明 / 二項定理',
            vid: '1Wl7jbGxWC14',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '恒等式',
        hn: 'Ⅱ / 式と証明',
        kougis: [
          {
            uid: '8',
            fid: '2',
            kid: '1',
            kn: '恒等式',
            kuid: '08_02_01',
            hn: 'Ⅱ / 式と証明 / 恒等式',
            vid: 'ELnpuYv5wS3T',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '等式の証明',
        hn: 'Ⅱ / 式と証明',
        kougis: [
          {
            uid: '8',
            fid: '2',
            kid: '1',
            kn: '方法１〜３、条件の使い方',
            kuid: '08_03_01',
            hn: 'Ⅱ / 式と証明 / 等式の証明',
            vid: 'QTLIsn8wd439',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '不等式の証明',
        hn: 'Ⅱ / 式と証明',
        kougis: [
          {
            uid: '8',
            fid: '2',
            kid: '1',
            kn: '方法１と方法２',
            kuid: '08_04_01',
            hn: 'Ⅱ / 式と証明 / 不等式の証明',
            vid: 'eT3YZPjQAVuC',
            record: {}
          },
          {
            uid: '8',
            fid: '2',
            kid: '2',
            kn: '方法３',
            kuid: '08_04_02',
            hn: 'Ⅱ / 式と証明 / 不等式の証明',
            vid: 'oqdz6vZc5a3z',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '有名不等式の利用',
        hn: 'Ⅱ / 式と証明',
        kougis: [
          {
            uid: '8',
            fid: '2',
            kid: '1',
            kn: '相加相乗平均の関係',
            kuid: '08_05_01',
            hn: 'Ⅱ / 式と証明 / 有名不等式の利用',
            vid: 'W-OG9zI8-aPD',
            record: {}
          },
          {
            uid: '8',
            fid: '2',
            kid: '2',
            kn: 'コーシー・シュワルツの不等式',
            kuid: '08_05_02',
            hn: 'Ⅱ / 式と証明 / 有名不等式の利用',
            vid: 'BVtgrZvC4NqA',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '9',
    un: '複素数と方程式',
    fid: '2bc',
    hn: 'Ⅱ',
    prints: [
      {
        pid: '1',
        pn: '複素数',
        hn: 'Ⅱ / 複素数と方程式',
        kougis: [
          {
            uid: '9',
            fid: '2',
            kid: '1',
            kn: '基本事項の確認',
            kuid: '09_01_01',
            hn: 'Ⅱ / 複素数と方程式 / 複素数',
            vid: 'WDVORmBwOBgi',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '２次方程式',
        hn: 'Ⅱ / 複素数と方程式',
        kougis: [
          {
            uid: '9',
            fid: '2',
            kid: '1',
            kn: '解の判別（基本）',
            kuid: '09_02_01',
            hn: 'Ⅱ / 複素数と方程式 / ２次方程式',
            vid: 'FKFpCHYDeVn4',
            record: {}
          },
          {
            uid: '9',
            fid: '2',
            kid: '2',
            kn: '解の判別（応用）対称式置き換え',
            kuid: '09_02_02',
            hn: 'Ⅱ / 複素数と方程式 / ２次方程式',
            vid: 'oBMm9k62oRks',
            record: {}
          },
          {
            uid: '9',
            fid: '2',
            kid: '3',
            kn: '解と係数の関係（基本）',
            kuid: '09_02_03',
            hn: 'Ⅱ / 複素数と方程式 / ２次方程式',
            vid: 'sv65S4qVQuGR',
            record: {}
          },
          {
            uid: '9',
            fid: '2',
            kid: '4',
            kn: '解と係数の関係（２次方程式を作る）',
            kuid: '09_02_04',
            hn: 'Ⅱ / 複素数と方程式 / ２次方程式',
            vid: 'S5Wq8xGjKNhy',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '高次方程式',
        hn: 'Ⅱ / 複素数と方程式',
        kougis: [
          {
            uid: '9',
            fid: '2',
            kid: '1',
            kn: '剰余の定理',
            kuid: '09_03_01',
            hn: 'Ⅱ / 複素数と方程式 / 高次方程式',
            vid: '_S9pTkw9P2q5',
            record: {}
          },
          {
            uid: '9',
            fid: '2',
            kid: '2',
            kn: '因数定理',
            kuid: '09_03_02',
            hn: 'Ⅱ / 複素数と方程式 / 高次方程式',
            vid: '_eB5c4jpQglI',
            record: {}
          },
          {
            uid: '9',
            fid: '2',
            kid: '3',
            kn: '解と係数の関係',
            kuid: '09_03_03',
            hn: 'Ⅱ / 複素数と方程式 / 高次方程式',
            vid: '4L04x3ZuMKQD',
            record: {}
          },
          {
            uid: '9',
            fid: '2',
            kid: '4',
            kn: '（頻１）余りの決定',
            kuid: '09_03_04',
            hn: 'Ⅱ / 複素数と方程式 / 高次方程式',
            vid: 'YFmfut5TdKSi',
            record: {}
          },
          {
            uid: '9',
            fid: '2',
            kid: '5',
            kn: '（頻２）解から係数決定',
            kuid: '09_03_05',
            hn: 'Ⅱ / 複素数と方程式 / 高次方程式',
            vid: 'DoZncA9KIX6C',
            record: {}
          },
          {
            uid: '9',
            fid: '2',
            kid: '6',
            kn: '（頻３）ωの問題',
            kuid: '09_03_06',
            hn: 'Ⅱ / 複素数と方程式 / 高次方程式',
            vid: 'xqqpiIWYy-3W',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '10',
    un: '図形と方程式',
    fid: '2bc',
    hn: 'Ⅱ',
    prints: [
      {
        pid: '1',
        pn: '点と直線',
        hn: 'Ⅱ / 図形と方程式',
        kougis: [
          {
            uid: '10',
            fid: '2',
            kid: '1',
            kn: '２点間の距離',
            kuid: '10_01_01',
            hn: 'Ⅱ / 図形と方程式 / 点と直線',
            vid: 'SA5ZQle2y4VE',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '2',
            kn: '内分・外分',
            kuid: '10_01_02',
            hn: 'Ⅱ / 図形と方程式 / 点と直線',
            vid: '8B_-jMbj5PJC',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '3',
            kn: '直線の方程式',
            kuid: '10_01_03',
            hn: 'Ⅱ / 図形と方程式 / 点と直線',
            vid: 'FBvf2dqUe8Vi',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '4',
            kn: '点と直線の距離',
            kuid: '10_01_04',
            hn: 'Ⅱ / 図形と方程式 / 点と直線',
            vid: 'N4MVNMctx6K_',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '5',
            kn: '平行＆垂直条件',
            kuid: '10_01_05',
            hn: 'Ⅱ / 図形と方程式 / 点と直線',
            vid: 'BHcFtMVVFmUH',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '6',
            kn: '「束」という考え方',
            kuid: '10_01_06',
            hn: 'Ⅱ / 図形と方程式 / 点と直線',
            vid: 'B4IdF7TcBWeC',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '7',
            kn: '＜応用編＞三角形の面積',
            kuid: '10_01_07',
            hn: 'Ⅱ / 図形と方程式 / 点と直線',
            vid: 'Qz0oNKtxDxKi',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '8',
            kn: '＜応用編＞対称点',
            kuid: '10_01_08',
            hn: 'Ⅱ / 図形と方程式 / 点と直線',
            vid: 'z8_DhkAzc-72',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '9',
            kn: '＜応用編＞３直線',
            kuid: '10_01_09',
            hn: 'Ⅱ / 図形と方程式 / 点と直線',
            vid: 'IoPjqpnRSpOq',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '10',
            kn: '＜応用編＞２直線を表す方程式',
            kuid: '10_01_10',
            hn: 'Ⅱ / 図形と方程式 / 点と直線',
            vid: 'G5ixycp5YzBx',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '円',
        hn: 'Ⅱ / 図形と方程式',
        kougis: [
          {
            uid: '10',
            fid: '2',
            kid: '1',
            kn: '（１）円の方程式',
            kuid: '10_02_01',
            hn: 'Ⅱ / 図形と方程式 / 円',
            vid: 'm6RBo12nMxe6',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '2',
            kn: '（２）束',
            kuid: '10_02_02',
            hn: 'Ⅱ / 図形と方程式 / 円',
            vid: 'amp0w7x6uakJ',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '3',
            kn: '（３）(i)円と直線「位置関係・弦」',
            kuid: '10_02_03',
            hn: 'Ⅱ / 図形と方程式 / 円',
            vid: 'S-XMmGYjcPaz',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '4',
            kn: '（３）(ii)円と直線「接線」',
            kuid: '10_02_04',
            hn: 'Ⅱ / 図形と方程式 / 円',
            vid: 'FsmsrW2MjYhO',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '5',
            kn: '（４）2円の関係',
            kuid: '10_02_05',
            hn: 'Ⅱ / 図形と方程式 / 円',
            vid: 'hzGoW1YsWbFd',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '6',
            kn: '＜応用編＞極線',
            kuid: '10_02_06',
            hn: 'Ⅱ / 図形と方程式 / 円',
            vid: 'Yhn6WSKJ-gTa',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '7',
            kn: '＜応用編＞２円の共通接線',
            kuid: '10_02_07',
            hn: 'Ⅱ / 図形と方程式 / 円',
            vid: 'xjEngEox3xin',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '軌跡',
        hn: 'Ⅱ / 図形と方程式',
        kougis: [
          {
            uid: '10',
            fid: '2',
            kid: '1',
            kn: '概要',
            kuid: '10_03_01',
            hn: 'Ⅱ / 図形と方程式 / 軌跡',
            vid: '2WrBOVm3sMYp',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '2',
            kn: '手順通り　タイプ１',
            kuid: '10_03_02',
            hn: 'Ⅱ / 図形と方程式 / 軌跡',
            vid: '3dldt_PNlGDs',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '3',
            kn: '手順通り　タイプ２',
            kuid: '10_03_03',
            hn: 'Ⅱ / 図形と方程式 / 軌跡',
            vid: 'i7akkS8y7olc',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '4',
            kn: '手順通り　タイプ２の応用',
            kuid: '10_03_04',
            hn: 'Ⅱ / 図形と方程式 / 軌跡',
            vid: 'LAMjHkT27_m8',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '5',
            kn: '手順通り　タイプ３',
            kuid: '10_03_05',
            hn: 'Ⅱ / 図形と方程式 / 軌跡',
            vid: 'PfZUSvnQ0u5o',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '6',
            kn: '図形的性質の利用',
            kuid: '10_03_06',
            hn: 'Ⅱ / 図形と方程式 / 軌跡',
            vid: '2O9xtQHASOOR',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '領域',
        hn: 'Ⅱ / 図形と方程式',
        kougis: [
          {
            uid: '10',
            fid: '2',
            kid: '1',
            kn: '（１）図示',
            kuid: '10_04_01',
            hn: 'Ⅱ / 図形と方程式 / 領域',
            vid: 'xqYwypNVtRCE',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '2',
            kn: '（２）最大・最小',
            kuid: '10_04_02',
            hn: 'Ⅱ / 図形と方程式 / 領域',
            vid: 'UQJJm_CMsTS7',
            record: {}
          },
          {
            uid: '10',
            fid: '2',
            kid: '3',
            kn: '（３）通過領域',
            kuid: '10_04_03',
            hn: 'Ⅱ / 図形と方程式 / 領域',
            vid: 'AyOLZJqXG1DF',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '11',
    un: '三角関数',
    fid: '2bc',
    hn: 'Ⅱ',
    prints: [
      {
        pid: '1',
        pn: '＜基本編＞　弧度法・一般角',
        hn: 'Ⅱ / 三角関数',
        kougis: [
          {
            uid: '11',
            fid: '2',
            kid: '1',
            kn: '弧度法',
            kuid: '11_01_01',
            hn: 'Ⅱ / 三角関数 / ＜基本編＞　弧度法・一般角',
            vid: 'tEgjsgel70aE',
            record: {}
          },
          {
            uid: '11',
            fid: '2',
            kid: '2',
            kn: '一般角',
            kuid: '11_01_02',
            hn: 'Ⅱ / 三角関数 / ＜基本編＞　弧度法・一般角',
            vid: 'T7MYa0k6D20S',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '＜基本編＞　相互関係',
        hn: 'Ⅱ / 三角関数',
        kougis: [
          {
            uid: '11',
            fid: '2',
            kid: '1',
            kn: 'tanの値など',
            kuid: '11_02_01',
            hn: 'Ⅱ / 三角関数 / ＜基本編＞　相互関係',
            vid: 'sFCaaz6T-lEZ',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '＜基本編＞　グラフ',
        hn: 'Ⅱ / 三角関数',
        kougis: [
          {
            uid: '11',
            fid: '2',
            kid: '1',
            kn: '基本',
            kuid: '11_03_01',
            hn: 'Ⅱ / 三角関数 / ＜基本編＞　グラフ',
            vid: 'M1fPHXHmBfoT',
            record: {}
          },
          {
            uid: '11',
            fid: '2',
            kid: '2',
            kn: '移動や周期・振幅',
            kuid: '11_03_02',
            hn: 'Ⅱ / 三角関数 / ＜基本編＞　グラフ',
            vid: 'FbX9_LJ9pxro',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '＜基本編＞　２５公式',
        hn: 'Ⅱ / 三角関数',
        kougis: [
          {
            uid: '11',
            fid: '2',
            kid: '1',
            kn: '加法定理',
            kuid: '11_04_01',
            hn: 'Ⅱ / 三角関数 / ＜基本編＞　２５公式',
            vid: 'q4NlFWePq3A5',
            record: {}
          },
          {
            uid: '11',
            fid: '2',
            kid: '2',
            kn: '２倍角・半角・３倍角',
            kuid: '11_04_02',
            hn: 'Ⅱ / 三角関数 / ＜基本編＞　２５公式',
            vid: 'QpA0oi-GkpIT',
            record: {}
          },
          {
            uid: '11',
            fid: '2',
            kid: '3',
            kn: '合成',
            kuid: '11_04_03',
            hn: 'Ⅱ / 三角関数 / ＜基本編＞　２５公式',
            vid: 'ORvl3RXXSbmw',
            record: {}
          },
          {
            uid: '11',
            fid: '2',
            kid: '4',
            kn: '積和・和積',
            kuid: '11_04_04',
            hn: 'Ⅱ / 三角関数 / ＜基本編＞　２５公式',
            vid: 'HHhuhA8uX0Uo',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '10',
        pn: '＜応用編＞　パターン０',
        hn: 'Ⅱ / 三角関数',
        kougis: [
          {
            uid: '11',
            fid: '2',
            kid: '1',
            kn: '基本的な方程式＆不等式',
            kuid: '11_10_01',
            hn: 'Ⅱ / 三角関数 / ＜応用編＞　パターン０',
            vid: 'kvgBQB19UggW',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '11',
        pn: '＜応用編＞　パターン１',
        hn: 'Ⅱ / 三角関数',
        kougis: [
          {
            uid: '11',
            fid: '2',
            kid: '1',
            kn: '公式のみで解けるもの',
            kuid: '11_11_01',
            hn: 'Ⅱ / 三角関数 / ＜応用編＞　パターン１',
            vid: 'OMQLJx4F0eAn',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '12',
        pn: '＜応用編＞　パターン２',
        hn: 'Ⅱ / 三角関数',
        kougis: [
          {
            uid: '11',
            fid: '2',
            kid: '1',
            kn: '２次関数に帰着（方程式・不等式）',
            kuid: '11_12_01',
            hn: 'Ⅱ / 三角関数 / ＜応用編＞　パターン２',
            vid: 'ALgBa8Qz8Rjy',
            record: {}
          },
          {
            uid: '11',
            fid: '2',
            kid: '2',
            kn: '２次関数に帰着（最大最小系）',
            kuid: '11_12_02',
            hn: 'Ⅱ / 三角関数 / ＜応用編＞　パターン２',
            vid: 'uO40hqUliMZe',
            record: {}
          },
          {
            uid: '11',
            fid: '2',
            kid: '3',
            kn: '２次関数に帰着（解の個数）',
            kuid: '11_12_03',
            hn: 'Ⅱ / 三角関数 / ＜応用編＞　パターン２',
            vid: 'O6WfV53mruD4',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '13',
        pn: '＜応用編＞　パターン３',
        hn: 'Ⅱ / 三角関数',
        kougis: [
          {
            uid: '11',
            fid: '2',
            kid: '1',
            kn: 'SINとCOSの一次式の和・差（方＆不）',
            kuid: '11_13_01',
            hn: 'Ⅱ / 三角関数 / ＜応用編＞　パターン３',
            vid: 'ymCeSsGZCpVg',
            record: {}
          },
          {
            uid: '11',
            fid: '2',
            kid: '2',
            kn: 'SINとCOSの一次式の和・差（最大最小）',
            kuid: '11_13_02',
            hn: 'Ⅱ / 三角関数 / ＜応用編＞　パターン３',
            vid: 'vSOC6BGQqH5c',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '14',
        pn: '＜応用編＞　パターン４',
        hn: 'Ⅱ / 三角関数',
        kougis: [
          {
            uid: '11',
            fid: '2',
            kid: '1',
            kn: '積以外が和（差）のみ（最大最小）',
            kuid: '11_14_01',
            hn: 'Ⅱ / 三角関数 / ＜応用編＞　パターン４',
            vid: 'M50Q5D7Hgk2O',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '15',
        pn: '＜応用編＞　パターン５',
        hn: 'Ⅱ / 三角関数',
        kougis: [
          {
            uid: '11',
            fid: '2',
            kid: '1',
            kn: '積以外が２乗or２倍角のみ（最大最小）',
            kuid: '11_15_01',
            hn: 'Ⅱ / 三角関数 / ＜応用編＞　パターン５',
            vid: 'AeMcTCsBMsTH',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '16',
        pn: '＜応用編＞　パターン６',
        hn: 'Ⅱ / 三角関数',
        kougis: [
          {
            uid: '11',
            fid: '2',
            kid: '1',
            kn: '積和・和積の左辺の形（方程式）',
            kuid: '11_16_01',
            hn: 'Ⅱ / 三角関数 / ＜応用編＞　パターン６',
            vid: 'rTrTFoeWyZZQ',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '17',
        pn: '＜応用編＞　パターン７',
        hn: 'Ⅱ / 三角関数',
        kougis: [
          {
            uid: '11',
            fid: '2',
            kid: '1',
            kn: '１〜６でダメなら（不等式）',
            kuid: '11_17_01',
            hn: 'Ⅱ / 三角関数 / ＜応用編＞　パターン７',
            vid: 'Ufmz6DlqId4z',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '12',
    un: '指数・対数関数',
    fid: '2bc',
    hn: 'Ⅱ',
    prints: [
      {
        pid: '0',
        pn: '指数対数の準備',
        hn: 'Ⅱ / 指数・対数関数',
        kougis: [
          {
            uid: '12',
            fid: '2',
            kid: '1',
            kn: 'ｌｏｇの定義など',
            kuid: '12_00_01',
            hn: 'Ⅱ / 指数・対数関数 / 指数対数の準備',
            vid: '9NFXjZF8D1Xq',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '1',
        pn: '指数・対数の「法則」と「性質」',
        hn: 'Ⅱ / 指数・対数関数',
        kougis: [
          {
            uid: '12',
            fid: '2',
            kid: '1',
            kn: '指数法則（基本事項）',
            kuid: '12_01_01',
            hn: 'Ⅱ / 指数・対数関数 / 指数・対数の「法則」と「性質」',
            vid: '5_KhbHPWka4u',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '2',
            kn: '指数法則（例題演習）',
            kuid: '12_01_02',
            hn: 'Ⅱ / 指数・対数関数 / 指数・対数の「法則」と「性質」',
            vid: 'zn3lxAC07zHH',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '3',
            kn: '指数の性質',
            kuid: '12_01_03',
            hn: 'Ⅱ / 指数・対数関数 / 指数・対数の「法則」と「性質」',
            vid: 'U-g1IXxgrZMi',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '4',
            kn: '対数の法則（基本事項）',
            kuid: '12_01_04',
            hn: 'Ⅱ / 指数・対数関数 / 指数・対数の「法則」と「性質」',
            vid: 'lq8Vxriea1yc',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '5',
            kn: '対数の法則（例題演習）',
            kuid: '12_01_05',
            hn: 'Ⅱ / 指数・対数関数 / 指数・対数の「法則」と「性質」',
            vid: '0ptUGs7xkokF',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '6',
            kn: '対数の性質',
            kuid: '12_01_06',
            hn: 'Ⅱ / 指数・対数関数 / 指数・対数の「法則」と「性質」',
            vid: 'r4UJtKd7azb6',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '指数・対数関数のグラフ',
        hn: 'Ⅱ / 指数・対数関数',
        kougis: [
          {
            uid: '12',
            fid: '2',
            kid: '1',
            kn: 'グラフの基本',
            kuid: '12_02_01',
            hn: 'Ⅱ / 指数・対数関数 / 指数・対数関数のグラフ',
            vid: 'g5nyPilEYxho',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '2',
            kn: '指数方程式',
            kuid: '12_02_02',
            hn: 'Ⅱ / 指数・対数関数 / 指数・対数関数のグラフ',
            vid: 'Sm-G8C6vxG4f',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '3',
            kn: '指数不等式',
            kuid: '12_02_03',
            hn: 'Ⅱ / 指数・対数関数 / 指数・対数関数のグラフ',
            vid: 'N1lz0fffwkGI',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '4',
            kn: '対数方程式',
            kuid: '12_02_04',
            hn: 'Ⅱ / 指数・対数関数 / 指数・対数関数のグラフ',
            vid: 'JqOOYfZ-UdL6',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '5',
            kn: '対数不等式',
            kuid: '12_02_05',
            hn: 'Ⅱ / 指数・対数関数 / 指数・対数関数のグラフ',
            vid: 'ZYQyJcLO50vR',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '最大最小（基本）',
        hn: 'Ⅱ / 指数・対数関数',
        kougis: [
          {
            uid: '12',
            fid: '2',
            kid: '1',
            kn: '＜指数編＞置き換え',
            kuid: '12_03_01',
            hn: 'Ⅱ / 指数・対数関数 / 最大最小（基本）',
            vid: 'I6SGOp8R55Nk',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '2',
            kn: '＜指数編＞文字消去',
            kuid: '12_03_02',
            hn: 'Ⅱ / 指数・対数関数 / 最大最小（基本）',
            vid: 'pcod-zPvL9wc',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '3',
            kn: '＜対数編＞置き換え',
            kuid: '12_03_03',
            hn: 'Ⅱ / 指数・対数関数 / 最大最小（基本）',
            vid: '6L335l_NOgVr',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '4',
            kn: '＜対数編＞真数の最大最小',
            kuid: '12_03_04',
            hn: 'Ⅱ / 指数・対数関数 / 最大最小（基本）',
            vid: 'WTKkc6GA3opE',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '5',
            kn: '＜対数編＞そのまま○○○○',
            kuid: '12_03_05',
            hn: 'Ⅱ / 指数・対数関数 / 最大最小（基本）',
            vid: 'tJAQuemJv5RW',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '種々の応用題',
        hn: 'Ⅱ / 指数・対数関数',
        kougis: [
          {
            uid: '12',
            fid: '2',
            kid: '1',
            kn: '対称式を利用した最大最小',
            kuid: '12_04_01',
            hn: 'Ⅱ / 指数・対数関数 / 種々の応用題',
            vid: 'ORz4fnDR8WFE',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '2',
            kn: '領域図示（対数不等式）',
            kuid: '12_04_02',
            hn: 'Ⅱ / 指数・対数関数 / 種々の応用題',
            vid: 'KRVdNLmY88IU',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '3',
            kn: '大小比較',
            kuid: '12_04_03',
            hn: 'Ⅱ / 指数・対数関数 / 種々の応用題',
            vid: 'ByDbwaAE4Iq8',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '桁数問題',
        hn: 'Ⅱ / 指数・対数関数',
        kougis: [
          {
            uid: '12',
            fid: '2',
            kid: '1',
            kn: '準備',
            kuid: '12_05_01',
            hn: 'Ⅱ / 指数・対数関数 / 桁数問題',
            vid: 'RcNNPTXBxtoR',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '2',
            kn: '桁数＆小数首位の桁',
            kuid: '12_05_02',
            hn: 'Ⅱ / 指数・対数関数 / 桁数問題',
            vid: 'ldvpnfsJQTGw',
            record: {}
          },
          {
            uid: '12',
            fid: '2',
            kid: '3',
            kn: '最高位の数',
            kuid: '12_05_03',
            hn: 'Ⅱ / 指数・対数関数 / 桁数問題',
            vid: 'Y60loXz5atNN',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '13',
    un: '微分積分',
    fid: '2bc',
    hn: 'Ⅱ',
    prints: [
      {
        pid: '1',
        pn: '微分',
        hn: 'Ⅱ / 微分積分',
        kougis: [
          {
            uid: '13',
            fid: '2',
            kid: '1',
            kn: '計算（極限）',
            kuid: '13_01_01',
            hn: 'Ⅱ / 微分積分 / 微分',
            vid: 'BDcJjD312yAZ',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '2',
            kn: '計算（微分係数）',
            kuid: '13_01_02',
            hn: 'Ⅱ / 微分積分 / 微分',
            vid: 'JN_xj_WfRXMu',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '3',
            kn: '計算（導関数の基本）',
            kuid: '13_01_03',
            hn: 'Ⅱ / 微分積分 / 微分',
            vid: '4bP4G_Cr0hg5',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '4',
            kn: '計算（導関数の応用）',
            kuid: '13_01_04',
            hn: 'Ⅱ / 微分積分 / 微分',
            vid: 'Uvza5hYsOwRT',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '5',
            kn: '極値・グラフ（基本）',
            kuid: '13_01_05',
            hn: 'Ⅱ / 微分積分 / 微分',
            vid: 'oNrgo_rgyx7M',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '6',
            kn: '極値・グラフ（応用）',
            kuid: '13_01_06',
            hn: 'Ⅱ / 微分積分 / 微分',
            vid: '2Nf9v6XS75a1',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '7',
            kn: '最大最小',
            kuid: '13_01_07',
            hn: 'Ⅱ / 微分積分 / 微分',
            vid: 'Ot8xL2_Fr8Uo',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '8',
            kn: '方程式への応用',
            kuid: '13_01_08',
            hn: 'Ⅱ / 微分積分 / 微分',
            vid: 'kWsKTXdEypvp',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '9',
            kn: '不等式への応用',
            kuid: '13_01_09',
            hn: 'Ⅱ / 微分積分 / 微分',
            vid: '8a9QexUszF-t',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '10',
            kn: '接線（基本）',
            kuid: '13_01_10',
            hn: 'Ⅱ / 微分積分 / 微分',
            vid: 'Rg1Wpr7SgBcl',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '11',
            kn: '接線（応用）',
            kuid: '13_01_11',
            hn: 'Ⅱ / 微分積分 / 微分',
            vid: 'qf-FXy2XMHpc',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '積分',
        hn: 'Ⅱ / 微分積分',
        kougis: [
          {
            uid: '13',
            fid: '2',
            kid: '1',
            kn: '不定積分',
            kuid: '13_02_01',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'kVrZ46r1769K',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '2',
            kn: '定積分の基本',
            kuid: '13_02_02',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'Jb6H2mja9NQP',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '3',
            kn: '定積分の応用（面積を表す）',
            kuid: '13_02_03',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'psgSJsuCAvEj',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '4',
            kn: '定積分の応用（絶対値つき）',
            kuid: '13_02_04',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'vO9_Gc1W8jtD',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '5',
            kn: '定積分の応用（偶関数と奇関数）',
            kuid: '13_02_05',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'FP-SubCzIReZ',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '6',
            kn: '定積分の応用（面積公式）',
            kuid: '13_02_06',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'lW42hph6K8gu',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '7',
            kn: '定積分で表された関数',
            kuid: '13_02_07',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'OL8cRlzWwAi_',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '8',
            kn: '面積（基本）',
            kuid: '13_02_08',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'w6-iutOiwZkg',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '9',
            kn: '面積（頻１）面積の最大最小',
            kuid: '13_02_09',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'lZryX_I58cMq',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '10',
            kn: '面積（頻２）外の点から２接線',
            kuid: '13_02_10',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'Hm6rHc7zSTNP',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '11',
            kn: '面積（頻３）２次関数共通接線',
            kuid: '13_02_11',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'aT-cWcdJhq9o',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '12',
            kn: '面積（頻４）３次関数に接する',
            kuid: '13_02_12',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'Urj38BY8w-RZ',
            record: {}
          },
          {
            uid: '13',
            fid: '2',
            kid: '13',
            kn: '面積（頻５）面積の等分',
            kuid: '13_02_13',
            hn: 'Ⅱ / 微分積分 / 積分',
            vid: 'UcXWRz7tKM0k',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '14',
    un: '数列',
    fid: '2bc',
    hn: 'B',
    prints: [
      {
        pid: '0',
        pn: 'Σの基本',
        hn: 'B / 数列',
        kougis: [
          {
            uid: '14',
            fid: 'b',
            kid: '1',
            kn: 'Σの基本',
            kuid: '14_00_01',
            hn: 'B / 数列 / Σの基本',
            vid: '54VShFJrGAtt',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '1',
        pn: '等差数列',
        hn: 'B / 数列',
        kougis: [
          {
            uid: '14',
            fid: 'b',
            kid: '1',
            kn: '公式作り',
            kuid: '14_01_01',
            hn: 'B / 数列 / 等差数列',
            vid: '9jz5l0j0nk8S',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '2',
            kn: '練習編',
            kuid: '14_01_02',
            hn: 'B / 数列 / 等差数列',
            vid: 'DPUVeW4obwCl',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '等比数列',
        hn: 'B / 数列',
        kougis: [
          {
            uid: '14',
            fid: 'b',
            kid: '1',
            kn: '公式作り・練習',
            kuid: '14_02_01',
            hn: 'B / 数列 / 等比数列',
            vid: 'awdLzYKdLvXb',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '2',
            kn: '等差中項・等比中項',
            kuid: '14_02_02',
            hn: 'B / 数列 / 等比数列',
            vid: 'DXA3oSMNLxsd',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: 'Σ',
        hn: 'B / 数列',
        kougis: [
          {
            uid: '14',
            fid: 'b',
            kid: '1',
            kn: '概要',
            kuid: '14_03_01',
            hn: 'B / 数列 / Σ',
            vid: 'W0TV69nkyVP9',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '2',
            kn: 'Σの公式を利用',
            kuid: '14_03_02',
            hn: 'B / 数列 / Σ',
            vid: 'TNghNPuk-Tmr',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '3',
            kn: '等比の和の公式を利用',
            kuid: '14_03_03',
            hn: 'B / 数列 / Σ',
            vid: 'TogaM_crtXFz',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '4',
            kn: 'バラバラ（部分分数）',
            kuid: '14_03_04',
            hn: 'B / 数列 / Σ',
            vid: 'r81np8S3Cb04',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '5',
            kn: 'バラバラ（有理化）',
            kuid: '14_03_05',
            hn: 'B / 数列 / Σ',
            vid: 'oMfW9hqcvWRT',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '6',
            kn: 'バラバラ（等差等比積）',
            kuid: '14_03_06',
            hn: 'B / 数列 / Σ',
            vid: 'AYkJqjkx8pdH',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '7',
            kn: 'おまけ（二項定理利用）',
            kuid: '14_03_07',
            hn: 'B / 数列 / Σ',
            vid: 'oda-JI793TOG',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '群数列',
        hn: 'B / 数列',
        kougis: [
          {
            uid: '14',
            fid: 'b',
            kid: '1',
            kn: '最初に調べる３つのこと',
            kuid: '14_04_01',
            hn: 'B / 数列 / 群数列',
            vid: 'jyQRkmB_tvGG',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '階差数列',
        hn: 'B / 数列',
        kougis: [
          {
            uid: '14',
            fid: 'b',
            kid: '1',
            kn: '基本',
            kuid: '14_05_01',
            hn: 'B / 数列 / 階差数列',
            vid: '-Q-dUKACcRg8',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '2',
            kn: '応用（第２階差）',
            kuid: '14_05_02',
            hn: 'B / 数列 / 階差数列',
            vid: 'dRexBmjthz8c',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: 'ＡｎとＳｎの関係',
        hn: 'B / 数列',
        kougis: [
          {
            uid: '14',
            fid: 'b',
            kid: '1',
            kn: '基本',
            kuid: '14_06_01',
            hn: 'B / 数列 / ＡｎとＳｎの関係',
            vid: 'lJ-z0RZ_x0wO',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '2',
            kn: '応用',
            kuid: '14_06_02',
            hn: 'B / 数列 / ＡｎとＳｎの関係',
            vid: 'IAizD0cH-0nJ',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '漸化式',
        hn: 'B / 数列',
        kougis: [
          {
            uid: '14',
            fid: 'b',
            kid: '1',
            kn: '基本説明',
            kuid: '14_07_01',
            hn: 'B / 数列 / 漸化式',
            vid: 'qIVGbM9Ujn12',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '2',
            kn: 'パターン１',
            kuid: '14_07_02',
            hn: 'B / 数列 / 漸化式',
            vid: '73Rdb3zHFvcZ',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '3',
            kn: 'パターン２',
            kuid: '14_07_03',
            hn: 'B / 数列 / 漸化式',
            vid: '9mfrjgMMk4vP',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '4',
            kn: 'パターン３',
            kuid: '14_07_04',
            hn: 'B / 数列 / 漸化式',
            vid: 'KIkw03z7l7bv',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '5',
            kn: 'パターン４',
            kuid: '14_07_05',
            hn: 'B / 数列 / 漸化式',
            vid: '9jlvxu3nU2Aa',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '6',
            kn: 'パターン５',
            kuid: '14_07_06',
            hn: 'B / 数列 / 漸化式',
            vid: 'sviybUEru4T5',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '7',
            kn: 'パターン６',
            kuid: '14_07_07',
            hn: 'B / 数列 / 漸化式',
            vid: 'Tak587lzKS11',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '8',
            kn: 'パターン７',
            kuid: '14_07_08',
            hn: 'B / 数列 / 漸化式',
            vid: 'QjadwBONl7cu',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '8',
        pn: '数学的帰納法',
        hn: 'B / 数列',
        kougis: [
          {
            uid: '14',
            fid: 'b',
            kid: '1',
            kn: '＜基本編＞概要・等式',
            kuid: '14_08_01',
            hn: 'B / 数列 / 数学的帰納法',
            vid: '26a5E4frbWCe',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '2',
            kn: '＜基本編＞不等式',
            kuid: '14_08_02',
            hn: 'B / 数列 / 数学的帰納法',
            vid: 'NPS34CC49GwM',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '3',
            kn: '＜基本編＞倍数証明',
            kuid: '14_08_03',
            hn: 'B / 数列 / 数学的帰納法',
            vid: 'HNBJ26-Fo17N',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '4',
            kn: '＜基本編＞推定→帰納法',
            kuid: '14_08_04',
            hn: 'B / 数列 / 数学的帰納法',
            vid: '4vHB05bsxPoI',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '5',
            kn: '＜応用編＞3項間',
            kuid: '14_08_05',
            hn: 'B / 数列 / 数学的帰納法',
            vid: 'fF8PbrbWsz4Q',
            record: {}
          },
          {
            uid: '14',
            fid: 'b',
            kid: '6',
            kn: '＜応用編＞和の利用',
            kuid: '14_08_06',
            hn: 'B / 数列 / 数学的帰納法',
            vid: 'nj5thKOMewrA',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '15',
    un: '確率分布と統計的な推測',
    fid: '2bc',
    hn: 'B',
    prints: [
      {
        pid: '1',
        pn: '確率分布',
        hn: 'B / 確率分布と統計的な推測',
        kougis: [
          {
            uid: '15',
            fid: 'b',
            kid: '1',
            kn: '確率変数と確率分布',
            kuid: '15_01_01',
            hn: 'B / 確率分布と統計的な推測 / 確率分布',
            vid: 'QD-uxGXKxMvq',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '2',
            kn: '確率変数の期待値と分散',
            kuid: '15_01_02',
            hn: 'B / 確率分布と統計的な推測 / 確率分布',
            vid: 'GRZpxkrEsxpU',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '3',
            kn: '確率変数の変換',
            kuid: '15_01_03',
            hn: 'B / 確率分布と統計的な推測 / 確率分布',
            vid: 'xCbxvs4EBPGX',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '4',
            kn: '確率変数の和と期待値',
            kuid: '15_01_04',
            hn: 'B / 確率分布と統計的な推測 / 確率分布',
            vid: 'xuukHCXpcn79',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '5',
            kn: '独立な確率変数（事象の独立と確率変数の独立）',
            kuid: '15_01_05',
            hn: 'B / 確率分布と統計的な推測 / 確率分布',
            vid: 'Zdz5K89GytN6',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '6',
            kn: '独立な確率変数（独立な確率変数の積の期待値・和の分散）',
            kuid: '15_01_06',
            hn: 'B / 確率分布と統計的な推測 / 確率分布',
            vid: '2rNRcoqQ5Dzj',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '7',
            kn: '二項分布',
            kuid: '15_01_07',
            hn: 'B / 確率分布と統計的な推測 / 確率分布',
            vid: 'FVK9v-d2pyJo',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '8',
            kn: '正規分布(i)連続型確率変数と確率密度関数',
            kuid: '15_01_08',
            hn: 'B / 確率分布と統計的な推測 / 確率分布',
            vid: 'UcDdxfC8aHst',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '9_F',
            kn: '正規分布(ii)正規分布と標準正規分布（前半）',
            kuid: '15_01_09_F',
            hn: 'B / 確率分布と統計的な推測 / 確率分布',
            vid: 'fDQ34jCHhJdt',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '9_S',
            kn: '正規分布(ii)正規分布と標準正規分布（後半）',
            kuid: '15_01_09_S',
            hn: 'B / 確率分布と統計的な推測 / 確率分布',
            vid: '3M7X3hubQ6s_',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '10',
            kn: '正規分布(iii)二項分布の正規分布による近似',
            kuid: '15_01_10',
            hn: 'B / 確率分布と統計的な推測 / 確率分布',
            vid: 'cvpnqucIWEks',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '統計的な推測',
        hn: 'B / 確率分布と統計的な推測',
        kougis: [
          {
            uid: '15',
            fid: 'b',
            kid: '1',
            kn: '母集団と標本',
            kuid: '15_02_01',
            hn: 'B / 確率分布と統計的な推測 / 統計的な推測',
            vid: 'LmnPDdmv948o',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '2_F',
            kn: '標本平均とその分布  (i)  標本平均・標本比率と正規分布（前半）',
            kuid: '15_02_02_F',
            hn: 'B / 確率分布と統計的な推測 / 統計的な推測',
            vid: 'Obrnjcr2sKXX',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '2_S',
            kn: '標本平均とその分布  (i)  標本平均・標本比率と正規分布（後半）',
            kuid: '15_02_02_S',
            hn: 'B / 確率分布と統計的な推測 / 統計的な推測',
            vid: 'B4Q29UJ0A753',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '3',
            kn: '標本平均とその分布  (ii) 大数の法則',
            kuid: '15_02_03',
            hn: 'B / 確率分布と統計的な推測 / 統計的な推測',
            vid: '3TqiR5CD93Y0',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '4',
            kn: '推定',
            kuid: '15_02_04',
            hn: 'B / 確率分布と統計的な推測 / 統計的な推測',
            vid: 'gqh74Ncrsoe9',
            record: {}
          },
          {
            uid: '15',
            fid: 'b',
            kid: '5',
            kn: '仮説検定',
            kuid: '15_02_05',
            hn: 'B / 確率分布と統計的な推測 / 統計的な推測',
            vid: 'WyTLhTMnJd75',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '16',
    un: 'ベクトル',
    fid: '2bc',
    hn: 'C',
    prints: [
      {
        pid: '0',
        pn: '＜平面＞ベクトルの基本くん',
        hn: 'C / ベクトル',
        kougis: [
          {
            uid: '16',
            fid: 'c',
            kid: '1',
            kn: '導入→和・差・実数倍',
            kuid: '16_00_01',
            hn: 'C / ベクトル / ＜平面＞ベクトルの基本くん',
            vid: '4DAOZDNbN-PR',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '2',
            kn: '成分表示',
            kuid: '16_00_02',
            hn: 'C / ベクトル / ＜平面＞ベクトルの基本くん',
            vid: 'PBX59aIbf3C4',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '3',
            kn: '一次独立とは',
            kuid: '16_00_03',
            hn: 'C / ベクトル / ＜平面＞ベクトルの基本くん',
            vid: 'UCriKJAOYSqT',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '1',
        pn: '＜平面＞内積系',
        hn: 'C / ベクトル',
        kougis: [
          {
            uid: '16',
            fid: 'c',
            kid: '1',
            kn: '内積の基礎（内積に慣れる）',
            kuid: '16_01_01',
            hn: 'C / ベクトル / ＜平面＞内積系',
            vid: 'WOEEcEJtaQUF',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '2',
            kn: '内積の基礎（内積となす角）',
            kuid: '16_01_02',
            hn: 'C / ベクトル / ＜平面＞内積系',
            vid: '8WS8c2NCsnmh',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '3',
            kn: '内積の基礎｜ｓa＋ｔｂ｜形は２乗',
            kuid: '16_01_03',
            hn: 'C / ベクトル / ＜平面＞内積系',
            vid: 'rmwPkijn2Pjw',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '4',
            kn: '内積の応用（平行条件・垂直条件）',
            kuid: '16_01_04',
            hn: 'C / ベクトル / ＜平面＞内積系',
            vid: '-o4TRRdDOQj2',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '5',
            kn: '内積の応用（三角形の面積）',
            kuid: '16_01_05',
            hn: 'C / ベクトル / ＜平面＞内積系',
            vid: 'JuVvq6pLUgLO',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '6',
            kn: '内積の応用（内積と最大最小）',
            kuid: '16_01_06',
            hn: 'C / ベクトル / ＜平面＞内積系',
            vid: 'rG6JafIrtVKo',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '＜平面＞位置ベクトル系',
        hn: 'C / ベクトル',
        kougis: [
          {
            uid: '16',
            fid: 'c',
            kid: '1',
            kn: '一次独立（内分外分を準備）',
            kuid: '16_02_01',
            hn: 'C / ベクトル / ＜平面＞位置ベクトル系',
            vid: 'GiktRDPHVJ54',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '2',
            kn: '一次独立（点の位置）',
            kuid: '16_02_02',
            hn: 'C / ベクトル / ＜平面＞位置ベクトル系',
            vid: 'ek2AfK0arlwC',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '3',
            kn: '一次独立（面積比）',
            kuid: '16_02_03',
            hn: 'C / ベクトル / ＜平面＞位置ベクトル系',
            vid: 'mQzlwMEGDrnu',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '4',
            kn: '領域',
            kuid: '16_02_04',
            hn: 'C / ベクトル / ＜平面＞位置ベクトル系',
            vid: 'lovmJZrfLfsi',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '5',
            kn: 'ベクトル方程式（直線）',
            kuid: '16_02_05',
            hn: 'C / ベクトル / ＜平面＞位置ベクトル系',
            vid: 'yWVZYu-gPf9O',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '6',
            kn: 'ベクトル方程式（円）',
            kuid: '16_02_06',
            hn: 'C / ベクトル / ＜平面＞位置ベクトル系',
            vid: 'vb7Tba3vo1lC',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '＜平面＞内積系と位置ベクトル系の融合',
        hn: 'C / ベクトル',
        kougis: [
          {
            uid: '16',
            fid: 'c',
            kid: '1',
            kn: '典型題練習',
            kuid: '16_03_01',
            hn: 'C / ベクトル / ＜平面＞内積系と位置ベクトル系の融合',
            vid: 'u7D4fBJ5ULxf',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '＜空間＞内積系',
        hn: 'C / ベクトル',
        kougis: [
          {
            uid: '16',
            fid: 'c',
            kid: '1',
            kn: '内積の基本（ベクトルの大きさ）',
            kuid: '16_04_01',
            hn: 'C / ベクトル / ＜空間＞内積系',
            vid: 'SEK65KVnsJq9',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '2',
            kn: '内積の基本（内積となす角）',
            kuid: '16_04_02',
            hn: 'C / ベクトル / ＜空間＞内積系',
            vid: 'piTocGto6sVE',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '3',
            kn: '内積の応用（２つのベクトルに垂直なベクトル）',
            kuid: '16_04_03',
            hn: 'C / ベクトル / ＜空間＞内積系',
            vid: 'UIeOYI9gQphE',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '4',
            kn: '内積の応用（正射影ベクトル）',
            kuid: '16_04_04',
            hn: 'C / ベクトル / ＜空間＞内積系',
            vid: 'JDCPMvDlyxdO',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '5',
            kn: 'その他（平面ベクトルからの継承事項）',
            kuid: '16_04_05',
            hn: 'C / ベクトル / ＜空間＞内積系',
            vid: 'eiXhb-4KXpwK',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '＜空間＞位置ベクトル系',
        hn: 'C / ベクトル',
        kougis: [
          {
            uid: '16',
            fid: 'c',
            kid: '1',
            kn: '一次独立（「同一平面上」を準備）',
            kuid: '16_05_01',
            hn: 'C / ベクトル / ＜空間＞位置ベクトル系',
            vid: 'Kq2doYN7vXF1',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '2',
            kn: '一次独立（基本）',
            kuid: '16_05_02',
            hn: 'C / ベクトル / ＜空間＞位置ベクトル系',
            vid: 'sdLaC4h0XP52',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '3',
            kn: '一次独立（点の位置）',
            kuid: '16_05_03',
            hn: 'C / ベクトル / ＜空間＞位置ベクトル系',
            vid: 'j-PjSUmO1cZB',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '4',
            kn: 'ベクトル方程式（直線の方程式）',
            kuid: '16_05_04',
            hn: 'C / ベクトル / ＜空間＞位置ベクトル系',
            vid: 'fwVNOx_W83_P',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '5',
            kn: 'ベクトル方程式（球面の方程式）',
            kuid: '16_05_05',
            hn: 'C / ベクトル / ＜空間＞位置ベクトル系',
            vid: 'llnQPE2y7ukW',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '6',
            kn: 'ベクトル方程式（平面の方程式）',
            kuid: '16_05_06',
            hn: 'C / ベクトル / ＜空間＞位置ベクトル系',
            vid: 'SwaX3PzdI2tR',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '7',
            kn: 'ベクトル方程式（２直線）',
            kuid: '16_05_07',
            hn: 'C / ベクトル / ＜空間＞位置ベクトル系',
            vid: 'MyhPSj-PBglM',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '8',
            kn: 'ベクトル方程式（直線と球面）',
            kuid: '16_05_08',
            hn: 'C / ベクトル / ＜空間＞位置ベクトル系',
            vid: 'ktUgntqiZhO8',
            record: {}
          },
          {
            uid: '16',
            fid: 'c',
            kid: '9',
            kn: 'ベクトル方程式（直線と平面）',
            kuid: '16_05_09',
            hn: 'C / ベクトル / ＜空間＞位置ベクトル系',
            vid: 'g7cm3RaDGdtS',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '＜空間＞内積系と位置ベクトル系の融合',
        hn: 'C / ベクトル',
        kougis: [
          {
            uid: '16',
            fid: 'c',
            kid: '1',
            kn: '典型題練習',
            kuid: '16_06_01',
            hn: 'C / ベクトル / ＜空間＞内積系と位置ベクトル系の融合',
            vid: 'iD9tC6CDDh_t',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '17',
    un: '平面上の曲線と複素数平面',
    fid: '2bc',
    hn: 'C',
    prints: [
      {
        pid: '1',
        pn: '＜平面上の曲線＞２次曲線',
        hn: 'C / 平面上の曲線と複素数平面',
        kougis: [
          {
            uid: '17',
            fid: 'c',
            kid: '1',
            kn: '放物線',
            kuid: '17_01_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞２次曲線',
            vid: 'jD6ue6cba3_f',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '2',
            kn: '楕円',
            kuid: '17_01_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞２次曲線',
            vid: 'XafJ34T38mSA',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '3',
            kn: '双曲線',
            kuid: '17_01_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞２次曲線',
            vid: 'dCP_vmU0BBKh',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '4',
            kn: '２次曲線の応用（接線）',
            kuid: '17_01_04',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞２次曲線',
            vid: 'tG4PZCYDWRdG',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '5',
            kn: '２次曲線の応用（離心率）',
            kuid: '17_01_05',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞２次曲線',
            vid: '1bf4WEhu2btF',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '＜平面上の曲線＞媒介変数表示と極座標',
        hn: 'C / 平面上の曲線と複素数平面',
        kougis: [
          {
            uid: '17',
            fid: 'c',
            kid: '1',
            kn: '媒介変数表示（基本）',
            kuid: '17_02_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞媒介変数表示と極座標',
            vid: 'nBidWjypitrq',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '2',
            kn: '媒介変数表示（自分でつくる）',
            kuid: '17_02_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞媒介変数表示と極座標',
            vid: 'Unx4mPpr1u5L',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '3',
            kn: '極座標の基本',
            kuid: '17_02_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞媒介変数表示と極座標',
            vid: 'gxz3S6EmKesl',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '4',
            kn: '極方程式（直線）',
            kuid: '17_02_04',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞媒介変数表示と極座標',
            vid: 'Dy-BdX1oPquc',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '5',
            kn: '極方程式（円）',
            kuid: '17_02_05',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞媒介変数表示と極座標',
            vid: '3NYv2y3QJh9e',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '6',
            kn: '極方程式（２次曲線）',
            kuid: '17_02_06',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞媒介変数表示と極座標',
            vid: 'WMrsS0WKkRfX',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '7',
            kn: '面積・弧長',
            kuid: '17_02_07',
            hn: 'C / 平面上の曲線と複素数平面 / ＜平面上の曲線＞媒介変数表示と極座標',
            vid: 'ziDe4OL18SXD',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '＜複素数平面＞複素数の復習',
        hn: 'C / 平面上の曲線と複素数平面',
        kougis: [
          {
            uid: '17',
            fid: 'c',
            kid: '1',
            kn: '「共役複素数」とその性質',
            kuid: '17_03_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞複素数の復習',
            vid: 'Bzc3pWg4a-NY',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '＜複素数平面＞極形式',
        hn: 'C / 平面上の曲線と複素数平面',
        kougis: [
          {
            uid: '17',
            fid: 'c',
            kid: '1',
            kn: 'ωの謎を解く',
            kuid: '17_04_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞極形式',
            vid: 'ITmcpRrqs9W1',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '2',
            kn: '定着させよう（４項目）',
            kuid: '17_04_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞極形式',
            vid: 'JBbR4wPX-Sop',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '3',
            kn: '５次にもいこう',
            kuid: '17_04_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞極形式',
            vid: 'PC9nqodmdsit',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '4',
            kn: '７次に挑戦',
            kuid: '17_04_04',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞極形式',
            vid: 'QwvR0Em7B3F3',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '＜複素数平面＞三角形',
        hn: 'C / 平面上の曲線と複素数平面',
        kougis: [
          {
            uid: '17',
            fid: 'c',
            kid: '1',
            kn: '原点を含む（△OAB）',
            kuid: '17_05_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞三角形',
            vid: 'mtwWC6r3UEMd',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '2',
            kn: '原点を含まない（△ABC）',
            kuid: '17_05_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞三角形',
            vid: 'HF3UOtLClSbg',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '＜複素数平面＞図形と方程式',
        hn: 'C / 平面上の曲線と複素数平面',
        kougis: [
          {
            uid: '17',
            fid: 'c',
            kid: '1',
            kn: '直線の方程式',
            kuid: '17_06_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞図形と方程式',
            vid: '1rUwXGrVOE8k',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '2',
            kn: '円の方程式',
            kuid: '17_06_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞図形と方程式',
            vid: 'oyifBeM5yyAP',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '7',
        pn: '＜複素数平面＞図形の応用',
        hn: 'C / 平面上の曲線と複素数平面',
        kougis: [
          {
            uid: '17',
            fid: 'c',
            kid: '1',
            kn: '基本編（回転・相似など）',
            kuid: '17_07_01',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞図形の応用',
            vid: '11UDC3F2m8mI',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '2',
            kn: '応用編（折り返し・反転など）',
            kuid: '17_07_02',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞図形の応用',
            vid: 'ZEB-LgsHQhWY',
            record: {}
          },
          {
            uid: '17',
            fid: 'c',
            kid: '3',
            kn: 'ベクトルとともに',
            kuid: '17_07_03',
            hn: 'C / 平面上の曲線と複素数平面 / ＜複素数平面＞図形の応用',
            vid: '6jU73GoFkq_w',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '18',
    un: '極限',
    fid: '3',
    hn: 'Ⅲ',
    prints: [
      {
        pid: '1',
        pn: '数列の極限',
        hn: 'Ⅲ / 極限',
        kougis: [
          {
            uid: '18',
            fid: '3',
            kid: '1',
            kn: '一般項の極限（導入編）',
            kuid: '18_01_01',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: 'wkfmMP_m1y1u',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '2',
            kn: '一般項の極限（基本その１）',
            kuid: '18_01_02',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: 'Ixrs9FekAWhF',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '3',
            kn: '一般項の極限（基本その２）',
            kuid: '18_01_03',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: 'quoMjSIv_qzO',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '4',
            kn: '一般項の極限（基本その３）',
            kuid: '18_01_04',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: 'Qrm-PN3mjNzH',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '5',
            kn: '一般項の極限（基本その３）（場合分け）',
            kuid: '18_01_05',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: 'G6QshHewuT5Q',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '6',
            kn: '一般項の極限（基本その４）',
            kuid: '18_01_06',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: '9oRCwjCmXuRH',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '7',
            kn: '一般項の極限（基本その５）',
            kuid: '18_01_07',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: 'Tcnn0I2R8gfw',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '8',
            kn: '一般項の極限（はさみうちの原理）',
            kuid: '18_01_08',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: 'nyZPR0beNkZr',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '9',
            kn: '無限級数（部分和→ｌｉｍ）',
            kuid: '18_01_09',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: 'ukyQ7oBTYcPQ',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '10',
            kn: '無限級数（無限等比級数）',
            kuid: '18_01_10',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: 'M-NUVAcDW2Pt',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '11',
            kn: '無限級数（区分求積法）',
            kuid: '18_01_11',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: 'rnN6-8qy8i0w',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '12',
            kn: '無限級数（はさみうちの原理）',
            kuid: '18_01_12',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: 'pXkfkMfQ_tNT',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '13',
            kn: '無限級数＜３つの重要事項＞',
            kuid: '18_01_13',
            hn: 'Ⅲ / 極限 / 数列の極限',
            vid: '7-txzI4jHAOd',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '関数の極限',
        hn: 'Ⅲ / 極限',
        kougis: [
          {
            uid: '18',
            fid: '3',
            kid: '1',
            kn: '準備（分数関数）',
            kuid: '18_02_01',
            hn: 'Ⅲ / 極限 / 関数の極限',
            vid: '2Quk440RS52u',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '2',
            kn: '準備（無理関数）',
            kuid: '18_02_02',
            hn: 'Ⅲ / 極限 / 関数の極限',
            vid: 'ZfJzWlAy-iHx',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '3',
            kn: '準備（逆関数）',
            kuid: '18_02_03',
            hn: 'Ⅲ / 極限 / 関数の極限',
            vid: 'DCwn7F5DnL5A',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '4',
            kn: '準備（合成関数）',
            kuid: '18_02_04',
            hn: 'Ⅲ / 極限 / 関数の極限',
            vid: '5O5BJ72oo2nE',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '5',
            kn: '分数＆無理関数の極限',
            kuid: '18_02_05',
            hn: 'Ⅲ / 極限 / 関数の極限',
            vid: '---4P_kk7l4q',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '6',
            kn: '分数＆無理関数の極限（つづき）',
            kuid: '18_02_06',
            hn: 'Ⅲ / 極限 / 関数の極限',
            vid: '3joc57SUyQyY',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '7',
            kn: '三角＆指数対数関数（概要）',
            kuid: '18_02_07',
            hn: 'Ⅲ / 極限 / 関数の極限',
            vid: '8JdAz6skEI99',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '8',
            kn: '三角関数の極限',
            kuid: '18_02_08',
            hn: 'Ⅲ / 極限 / 関数の極限',
            vid: 'r9mY-O2oiXWB',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '9',
            kn: '三角関数の極限（つづき）',
            kuid: '18_02_09',
            hn: 'Ⅲ / 極限 / 関数の極限',
            vid: 'xPPuAInypnWF',
            record: {}
          },
          {
            uid: '18',
            fid: '3',
            kid: '10',
            kn: '指数対数関数の極限',
            kuid: '18_02_10',
            hn: 'Ⅲ / 極限 / 関数の極限',
            vid: 'zRr7JzuU0Ikd',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '19',
    un: '微分',
    fid: '3',
    hn: 'Ⅲ',
    prints: [
      {
        pid: '1',
        pn: '計算',
        hn: 'Ⅲ / 微分',
        kougis: [
          {
            uid: '19',
            fid: '3',
            kid: '1',
            kn: '公式作り〜計算全体像',
            kuid: '19_01_01',
            hn: 'Ⅲ / 微分 / 計算',
            vid: 'yUYOfNR2ALGw',
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            kid: '2',
            kn: '計算全体像（前半戦）',
            kuid: '19_01_02',
            hn: 'Ⅲ / 微分 / 計算',
            vid: 'sS3F6CX6Kzvu',
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            kid: '3',
            kn: '計算全体像（後半戦）',
            kuid: '19_01_03',
            hn: 'Ⅲ / 微分 / 計算',
            vid: 'k4L78MAfAUat',
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            kid: '4',
            kn: '高次導関数',
            kuid: '19_01_04',
            hn: 'Ⅲ / 微分 / 計算',
            vid: '8us-lbIFeIGF',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '接線',
        hn: 'Ⅲ / 微分',
        kougis: [
          {
            uid: '19',
            fid: '3',
            kid: '1',
            kn: '基本編',
            kuid: '19_02_01',
            hn: 'Ⅲ / 微分 / 接線',
            vid: 'DsxtD0seBy-P',
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            kid: '2',
            kn: '応用編',
            kuid: '19_02_02',
            hn: 'Ⅲ / 微分 / 接線',
            vid: 'VURPKSnKuG3x',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: 'グラフ',
        hn: 'Ⅲ / 微分',
        kougis: [
          {
            uid: '19',
            fid: '3',
            kid: '1',
            kn: 'まずは基本から',
            kuid: '19_03_01',
            hn: 'Ⅲ / 微分 / グラフ',
            vid: 'BBrsfG9713C2',
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            kid: '2',
            kn: '練習（４項目まで）',
            kuid: '19_03_02',
            hn: 'Ⅲ / 微分 / グラフ',
            vid: 'y_nQPG-WfsL_',
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            kid: '3',
            kn: '凹凸',
            kuid: '19_03_03',
            hn: 'Ⅲ / 微分 / グラフ',
            vid: 'h-643NbQfsAn',
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            kid: '4',
            kn: '漸近線',
            kuid: '19_03_04',
            hn: 'Ⅲ / 微分 / グラフ',
            vid: 'I8AegTm8GI7f',
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            kid: '5',
            kn: '練習（６項目まで）',
            kuid: '19_03_05',
            hn: 'Ⅲ / 微分 / グラフ',
            vid: '5TmLAH3XJUMb',
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            kid: '6',
            kn: '練習（媒介変数表示）',
            kuid: '19_03_06',
            hn: 'Ⅲ / 微分 / グラフ',
            vid: 'ePTQIDacqdXO',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '最大最小',
        hn: 'Ⅲ / 微分',
        kougis: [
          {
            uid: '19',
            fid: '3',
            kid: '1',
            kn: 'グラフを利用',
            kuid: '19_04_01',
            hn: 'Ⅲ / 微分 / 最大最小',
            vid: 'Jqc99y4oJjEs',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '方程式＆不等式への応用',
        hn: 'Ⅲ / 微分',
        kougis: [
          {
            uid: '19',
            fid: '3',
            kid: '1',
            kn: '方程式への応用',
            kuid: '19_05_01',
            hn: 'Ⅲ / 微分 / 方程式＆不等式への応用',
            vid: 'uVMYXmfks2RH',
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            kid: '2',
            kn: '不等式への応用',
            kuid: '19_05_02',
            hn: 'Ⅲ / 微分 / 方程式＆不等式への応用',
            vid: 'cr_iTmlPA8Mp',
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            kid: '3',
            kn: '不等式への応用（最終極限公式）',
            kuid: '19_05_03',
            hn: 'Ⅲ / 微分 / 方程式＆不等式への応用',
            vid: 'n2K_smmrd5hH',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: 'その他',
        hn: 'Ⅲ / 微分',
        kougis: [
          {
            uid: '19',
            fid: '3',
            kid: '1',
            kn: '微分可能と連続',
            kuid: '19_06_01',
            hn: 'Ⅲ / 微分 / その他',
            vid: '7IlkzzRe4Kdw',
            record: {}
          },
          {
            uid: '19',
            fid: '3',
            kid: '2',
            kn: '平均値の定理',
            kuid: '19_06_02',
            hn: 'Ⅲ / 微分 / その他',
            vid: 'zRdQATnwiEaX',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  },
  {
    uid: '20',
    un: '積分',
    fid: '3',
    hn: 'Ⅲ',
    prints: [
      {
        pid: '1',
        pn: '計算',
        hn: 'Ⅲ / 積分',
        kougis: [
          {
            uid: '20',
            fid: '3',
            kid: '1',
            kn: '概要',
            kuid: '20_01_01',
            hn: 'Ⅲ / 積分 / 計算',
            vid: 'lTYJIiMU_Sea',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '2',
            kn: '不定積分（基本）その１',
            kuid: '20_01_02',
            hn: 'Ⅲ / 積分 / 計算',
            vid: 'UirXZAnpkekb',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '3',
            kn: '不定積分（基本）その２',
            kuid: '20_01_03',
            hn: 'Ⅲ / 積分 / 計算',
            vid: '0T9tiKeBYdK1',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '4',
            kn: '不定積分（部分積分）',
            kuid: '20_01_04',
            hn: 'Ⅲ / 積分 / 計算',
            vid: 'rVn6tG1Pplnf',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '5',
            kn: '不定積分（置換部分）',
            kuid: '20_01_05',
            hn: 'Ⅲ / 積分 / 計算',
            vid: '48cKJ6cjZ-0m',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '6',
            kn: '定積分（基本）',
            kuid: '20_01_06',
            hn: 'Ⅲ / 積分 / 計算',
            vid: 'MfRVicw62mCM',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '7',
            kn: '定積分（部分積分）',
            kuid: '20_01_07',
            hn: 'Ⅲ / 積分 / 計算',
            vid: 'dakk8XMCyp5M',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '8',
            kn: '定積分（置換積分）',
            kuid: '20_01_08',
            hn: 'Ⅲ / 積分 / 計算',
            vid: '4brCzFoesQ3Z',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '9',
            kn: '定積分の応用（置換短縮編）',
            kuid: '20_01_09',
            hn: 'Ⅲ / 積分 / 計算',
            vid: 'UGeNBQdacHHD',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '10',
            kn: '定積分の応用（あの置換）',
            kuid: '20_01_10',
            hn: 'Ⅲ / 積分 / 計算',
            vid: 'p6QuwYCArzyu',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '11',
            kn: '定積分の応用（面積を表す）',
            kuid: '20_01_11',
            hn: 'Ⅲ / 積分 / 計算',
            vid: 'N7WNrA0rEmfw',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '12',
            kn: '定積分の応用（絶対値つき）',
            kuid: '20_01_12',
            hn: 'Ⅲ / 積分 / 計算',
            vid: '7PccWTe2_BDt',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '13',
            kn: '定積分の応用（対称性）',
            kuid: '20_01_13',
            hn: 'Ⅲ / 積分 / 計算',
            vid: 'P7gOkxIQIVL9',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '14',
            kn: '定積分の応用（周期性）',
            kuid: '20_01_14',
            hn: 'Ⅲ / 積分 / 計算',
            vid: 'Ac_lwQzFV8gP',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '15',
            kn: '定積分の応用（定積分と漸化式）',
            kuid: '20_01_15',
            hn: 'Ⅲ / 積分 / 計算',
            vid: 'xFGIPBEGwZOc',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '2',
        pn: '定積分で表された関数',
        hn: 'Ⅲ / 積分',
        kougis: [
          {
            uid: '20',
            fid: '3',
            kid: '1',
            kn: '関数決定（概要編）',
            kuid: '20_02_01',
            hn: 'Ⅲ / 積分 / 定積分で表された関数',
            vid: 'sJ4fGD5puSM2',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '2',
            kn: '関数決定（区間が定数のみ）',
            kuid: '20_02_02',
            hn: 'Ⅲ / 積分 / 定積分で表された関数',
            vid: '8J5GkqbT-Nfk',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '3',
            kn: '関数決定（区間に変数含む）',
            kuid: '20_02_03',
            hn: 'Ⅲ / 積分 / 定積分で表された関数',
            vid: 'uJpRSs4RSa5n',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '3',
        pn: '面積',
        hn: 'Ⅲ / 積分',
        kougis: [
          {
            uid: '20',
            fid: '3',
            kid: '1',
            kn: '基本',
            kuid: '20_03_01',
            hn: 'Ⅲ / 積分 / 面積',
            vid: 'NjFpyYvO8rRF',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '2',
            kn: '区分求積法',
            kuid: '20_03_02',
            hn: 'Ⅲ / 積分 / 面積',
            vid: 'MJBEMJqAveoz',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '3',
            kn: '不等式への応用',
            kuid: '20_03_03',
            hn: 'Ⅲ / 積分 / 面積',
            vid: 'b2hvljlVqyRi',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '4',
        pn: '体積',
        hn: 'Ⅲ / 積分',
        kougis: [
          {
            uid: '20',
            fid: '3',
            kid: '1',
            kn: '非回転体',
            kuid: '20_04_01',
            hn: 'Ⅲ / 積分 / 体積',
            vid: 'Y8wXRySoDcyG',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '2',
            kn: '回転体',
            kuid: '20_04_02',
            hn: 'Ⅲ / 積分 / 体積',
            vid: 'GwfZKxeyNSLF',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '5',
        pn: '曲線の長さ',
        hn: 'Ⅲ / 積分',
        kougis: [
          {
            uid: '20',
            fid: '3',
            kid: '1',
            kn: '曲線の長さ',
            kuid: '20_05_01',
            hn: 'Ⅲ / 積分 / 曲線の長さ',
            vid: 'eKxVHVEu_zFK',
            record: {}
          }
        ],
        record: {}
      },
      {
        pid: '6',
        pn: '物理量',
        hn: 'Ⅲ / 積分',
        kougis: [
          {
            uid: '20',
            fid: '3',
            kid: '1',
            kn: '１次元',
            kuid: '20_06_01',
            hn: 'Ⅲ / 積分 / 物理量',
            vid: '0pOHjBkywLrE',
            record: {}
          },
          {
            uid: '20',
            fid: '3',
            kid: '2',
            kn: '２次元と３次元',
            kuid: '20_06_02',
            hn: 'Ⅲ / 積分 / 物理量',
            vid: 'zdT3cDLHJqGl',
            record: {}
          }
        ],
        record: {}
      }
    ],
    record: {}
  }
];